import React, { useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';

import { useTheme, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import WriteBlogLeftSection from './write-blog-left-sec/write-blog-left-sec';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import WriteBlogRightSection from './write-blog-right-sec/write-blog-right-sec';
import { useNavigate } from 'react-router';
import { createBlogValidationSchema } from 'app/utils/validation-schema/blogs-schema';
import PostSuccessModal from 'app/components/common/post-success-modal/post-success-modal';
import { useAppDispatch } from 'app/config/store';
import { createNgo } from 'app/shared/reducers/ngo';

const initialValues = {
  blogTitle: '',
  blogBody: '',
  category: '',
  imageId: null,
  postAsNgo: false,
  ngoUserId: 0,
  hasActivity: false,
  name: '',
  address: '',
  contactPerson: '',
  emailId: '',
  phoneNumber: '',
  latitude: '',
  longitude: '',
  place: '',
};

const Blog = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [responseUrl, setResponseUrl] = useState<string>('');
  const [open, setOpen] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();

  const handleBlogSubmit = async values => {
    let responseNgoId;

    if (values.postAsNgo) {
      const createNgoPayload = {
        name: values.name,
        address: values.address,
        contactPerson: values.contactPerson,
        emailId: values.emailId,
        latitude: values.latitude,
        longitude: values.longitude,
        phoneNumber: values.phoneNumber,
      };

      try {
        const createNgoResponse = await dispatch(createNgo(createNgoPayload));
        if (createNgoResponse.payload) {
          responseNgoId = createNgoResponse.payload;
        }
      } catch (error) {
        console.log('error', error);
        // Handle the error if needed
      }
    }

    const payloadData = {
      blogTitle: values.blogTitle,
      blogBody: values.blogBody,
      category: values.category,
      imageId: values.imageId,
      postAsNgo: values.postAsNgo,
      ngoUserId: 0,
      hasActivity: values.hasActivity,
    };

    if (values.postAsNgo && responseNgoId) {
      payloadData.ngoUserId = responseNgoId;
    }

    try {
      const response = await axios.post<any>('api/blog-tables', payloadData);
      if (response.status === 201) {
        if (response.data.link) {
          setResponseUrl(response?.data?.link);
        }
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createBlogValidationSchema,
    onSubmit: values => {
      handleBlogSubmit(values);
    },
  });
  const handleModalClose = () => {
    setOpen(false);
    navigate('/blog-list');
  };

  return (
    <div className="mx-4 py-2" style={{ height: '100vh', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'none' }}>
      <style>
        {`
         ::-webkit-scrollbar {
        width: 0;
        background: transparent; 
      }     
      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    `}
      </style>
      <div className="row py-3">
        <div className=" col-xs-12 col-md-3">
          <ArrowCircleLeftIcon
            onClick={() => navigate(-1)}
            sx={{ cursor: 'pointer', color: '#FFCE00', borderRadius: '50%', fontSize: '29px' }}
          />
        </div>

        <div className="col-xs-12  col-sm-12 col-md-5">
          <Typography
            variant="h4"
            sx={{
              color: 'secondary.main',
              pb: 3,
              pt: { xs: 1, lg: 0 },
            }}
          >
            Create a blog
          </Typography>
        </div>
        <div className="col-xs-12 col-md-12 mb-5">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-lg-3 col-12 mb-2">
                <WriteBlogRightSection formik={formik} />
              </div>
              <div className="col-sm-12 col-lg-7 col-12">
                <WriteBlogLeftSection formik={formik} />
              </div>
            </div>
          </form>
        </div>
      </div>
      <PostSuccessModal
        handleModalClose={handleModalClose}
        fullScreen={fullScreen}
        open={open}
        onClose={handleModalClose}
        responseUrl={responseUrl}
        message={'Your blog has been posted successfully'}
      />
    </div>
  );
};

export default Blog;
