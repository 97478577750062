import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { Typography, useTheme } from '@mui/material';
import './activity-start-time.scss';

type ActivityStartTimeProps = { formik: any };

const ActivityStartTime = ({ formik }: ActivityStartTimeProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [startDate, setStartDate] = useState<Date | null>(null);

  useEffect(() => {
    if (formik.values.activityStartTime != null && formik.values.activityStartTime != '') {
      setStartDate(new Date(formik.values.activityStartTime));
    }
  }, [formik.values.activityStartTime]);

  // Check if the start date from formik.values.startDate is today
  const isStartDateToday = formik.values.startDate ? dayjs().isSame(dayjs(formik.values.startDate), 'day') : false;

  // Adjust minTime and maxTime based on whether the start date is today
  const getTimeOptions = () => {
    const now = dayjs();
    let options = [];
    let startHour = 0;
    let endHour = 23;

    if (isStartDateToday) {
      startHour = now.hour();
      let startMinute = Math.ceil(now.minute() / 15) * 15; // Round up to the nearest 15 minutes
      for (let hour = startHour; hour <= endHour; hour++) {
        for (let minute = hour === startHour ? startMinute : 0; minute < 60; minute += 15) {
          options.push(new Date().setHours(hour, minute, 0, 0));
        }
      }
    } else {
      // Generate time options for the whole day if the start date is not today
      for (let hour = 0; hour <= 23; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          options.push(new Date().setHours(hour, minute, 0, 0));
        }
      }
    }

    return {
      minTime: options[0],
      maxTime: options[options.length - 1],
    };
  };

  const { minTime, maxTime } = getTimeOptions();

  const settingValue = date => {
    const selectedTime = dayjs(date);
    const startDateDatePart = dayjs(formik.values.startDate).format('YYYY-MM-DD');
    const newActivityStartTime = dayjs(`${startDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();

    setStartDate(newActivityStartTime);
    formik.setFieldValue('activityStartTime', newActivityStartTime);
    formik.setFieldValue('startDate', newActivityStartTime);
  };

  return (
    <div style={{ height: '60px' }}>
      <style>
        {`.react-datepicker__time-container .react-datepicker__time{
          background-color: ${isDarkMode ? '#1F1F26 !important' : '#fff !important'};
          color: ${isDarkMode ? '#fff !important' : '#1F1F26 !important'}
        }
        `}
        {`.react-datepicker__header:not(.react-datepicker__header--has-time-select){
          background-color: ${isDarkMode ? '#1F1F26 !important' : '#fff !important'};
           border-bottom: none !important;        
        }
        `}
        {`.react-datepicker-time__header{
         display: none !important;
        }
        
        `}
        {`
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list{
  width:113% !important
}`}
      </style>
      <DatePicker
        className={isDarkMode ? 'rom-timepickerdark' : 'rom-timepicker'}
        selected={startDate}
        onChange={(date: Date | null) => {
          if (date) {
            console.log('yup enter 1');
            const selectedTime = dayjs(date);
            const startDateDatePart = dayjs(formik.values.startDate).format('YYYY-MM-DD');
            const newActivityStartTime = dayjs(`${startDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();

            setStartDate(newActivityStartTime);
            formik.setFieldValue('activityStartTime', newActivityStartTime);
            formik.setFieldValue('startDate', newActivityStartTime);
            settingValue(date);
          } else {
            setStartDate(null);
            formik.setFieldValue('activityStartTime', null);
          }
        }}
        onBlur={() => {
          formik.setFieldTouched('activityStartTime');
          formik.validateField('activityStartTime');
        }}
        onFocus={() => {
          formik.setFieldTouched('activityStartTime');
          formik.validateField('activityStartTime');
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="h:mm aa"
        isClearable={false}
        placeholderText="Start Time*"
        onKeyDown={e => e.preventDefault()}
        minTime={new Date(minTime)}
        maxTime={new Date(maxTime)}
      />
      {formik.touched.activityStartTime && formik.errors.activityStartTime && (
        <Typography variant="caption" sx={{ color: 'error.main' }}>
          {formik.touched.activityStartTime && formik.errors.activityStartTime ? 'Activity start time is required' : ''}
        </Typography>
      )}
    </div>
  );
};

export default ActivityStartTime;
