// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-button1 {
  display: flex !important;
  position: fixed;
  flex-direction: column;
  bottom: -8px;
  cursor: pointer;
  margin-bottom: 30px;
}

@media (max-width: 954px) {
  .fixed-button1 {
    display: block !important;
    position: unset;
    bottom: 0px;
    right: unset;
    z-index: unset;
    color: #fff;
    padding: 15px 20px;
    border-radius: 50%;
    cursor: pointer;
  }
}
@media (min-width: 955px) and (max-width: 1149px) {
  .fixed-button1 {
    display: flex !important;
    position: fixed;
    flex-direction: column;
    cursor: pointer;
    bottom: -8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/create-activity/create-activity-instruction-resources/create-activity-instruction-resources.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,eAAA;EACA,sBAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,yBAAA;IACA,eAAA;IACA,WAAA;IACA,YAAA;IACA,cAAA;IACA,WAAA;IACA,kBAAA;IACA,kBAAA;IACA,eAAA;EACF;AACF;AAEA;EACE;IACE,wBAAA;IACA,eAAA;IACA,sBAAA;IACA,eAAA;IACA,YAAA;EAAF;AACF","sourcesContent":[".fixed-button1 {\n  display: flex !important;\n  position: fixed;\n  flex-direction: column;\n  bottom: -8px;\n  cursor: pointer;\n  margin-bottom: 30px;\n}\n\n@media (max-width: 954px) {\n  .fixed-button1 {\n    display: block !important;\n    position: unset;\n    bottom: 0px;\n    right: unset;\n    z-index: unset;\n    color: #fff;\n    padding: 15px 20px;\n    border-radius: 50%;\n    cursor: pointer;\n  }\n}\n\n@media (min-width: 955px) and (max-width: 1149px) {\n  .fixed-button1 {\n    display: flex !important;\n    position: fixed;\n    flex-direction: column;\n    cursor: pointer;\n    bottom: -8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
