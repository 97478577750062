import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'app/config/store';
import { Grid, IconButton, useTheme, Typography, useMediaQuery } from '@mui/material';
import PhoneVerification from 'app/components/ui/phone-verification/phone-verification';
import { Storage } from 'react-jhipster';

const SettingsChangePhone = ({ setUpdatedSettings }: any) => {
  const userSettings = useAppSelector(state => state.settingsReducer.userSettings);
  const theme = useTheme();
  const [phoneVerificationModal, setPhoneVerificationModal] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [phoneInputCompleted, setPhoneInputCompleted] = useState(false);
  const [otpVerificationCompleted, setotpVerificationCompleted] = useState(false);
  const [myPhone, setMyPhone] = useState('');

  const handleChangePhoneNumber = () => {
    setPhoneVerificationModal(true);
  };
  const handlePhoneVerificationClose = () => {
    setPhoneVerificationModal(false);

    if (otpVerificationCompleted) {

    } else {
      setPhoneInputCompleted(false);
    }
  };
  useEffect(() => {
    const phoneDetails = Storage.local.get('getPhone');

    if (otpVerificationCompleted) {
      const myPhoneNumber = Storage.session.get('phone-number');
      setMyPhone(myPhoneNumber)
      if (phoneDetails?.verified && phoneDetails?.phoneNumber) {
        setUpdatedSettings(state => ({ ...state, contactNumber: phoneDetails?.phoneNumber }));
      }
    }
  }, [otpVerificationCompleted]);
  useEffect(() => {
    setMyPhone(userSettings?.contactNumber)
  }, [userSettings]);

  return (
    <Grid container direction="row" alignItems="center" justifyContent={'flex-start'}>
      <Grid container item md={4} xs={6} direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h5" sx={{ color: theme.textColor.mainHeading, fontSize: '14px' }}>
            Contact Phone
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ color: theme.textColor.heading, fontSize: 13 }}>{myPhone}</Typography>
        </Grid>
      </Grid>
      <Grid container item xs={6} md>
        <Grid item container direction="column">
          <IconButton
            onClick={handleChangePhoneNumber}
            color="primary"
            aria-label="change phone number"
            component="label"
            disableRipple
            sx={{ gap: 1, justifyContent: 'flex-end' }}
          >
            <Typography
              sx={{
                color: theme.textColor.mainHeading,
                textTransform: 'none',
                borderRadius: 1.875,
                py: 0.5,
                px: 3.25,
                border: `1px solid ${theme.palette.secondary.contrastText}`,
                fontSize: theme.infoText.regular.fontSize,
                fontWeight: theme.infoText.regular.fontWeight,
                fontStyle: theme.infoText.regular.fontStyle,
              }}
            >
              Change
            </Typography>
          </IconButton>
        </Grid>
      </Grid>
      <PhoneVerification
        handleClose={handlePhoneVerificationClose}
        open={phoneVerificationModal}
        fullScreen={fullScreen}
        phoneInputCompleted={phoneInputCompleted}
        setPhoneInputCompleted={setPhoneInputCompleted}
        otpVerificationCompleted={otpVerificationCompleted}
        setotpVerificationCompleted={setotpVerificationCompleted}
      />
    </Grid>
  );
};

export default SettingsChangePhone;
