import React, { useRef, useState } from 'react';
import { CameraAlt } from '@mui/icons-material';
import { Avatar, Box, IconButton } from '@mui/material';

interface SettingsOrglogoProps {
  inputRef: React.RefObject<HTMLInputElement>;
  selectedFile: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
  handleAvatarClick: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hasPermission: boolean;
  logo: string;
}

const SettingsOrglogo: React.FC<SettingsOrglogoProps> = ({
  inputRef,
  selectedFile,
  logo,
  handleAvatarClick,
  handleFileChange,
  hasPermission,
}) => {
  return (
    <Box
      sx={{
        width: 50,
        height: 50,
        position: 'relative',
        overflow: 'hidden',
        cursor: 'pointer',
      }}
    >
      <Avatar alt="Organization Logo" src={selectedFile ? URL.createObjectURL(selectedFile) : logo} sx={{ width: 50, height: 50 }}></Avatar>
      <div
        className="overlay"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          zIndex: 0,
        }}
      />
      <IconButton
        disabled={!hasPermission}
        onClick={handleAvatarClick}
        color="primary"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 50,
          height: 50,
        }}
      >
        <CameraAlt sx={{ color: '#fff' }} />
      </IconButton>

      <input ref={inputRef} type="file" accept="image/*" style={{ display: 'none' }} onChange={handleFileChange} />
    </Box>
  );
};

export default SettingsOrglogo;
