import React, { useEffect, useRef, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import DarkModeToggleIcon from '../../icons/header-icons/dark-mode-toggle-icon';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SquareChatIcon from 'app/components/icons/header-icons/square-chat-icon';
import Avatar from '@mui/material/Avatar';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from 'app/components/icons/header-icons/serach-icon';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import { Button, ButtonGroup, ListItemIcon, Typography, debounce, useMediaQuery, useTheme } from '@mui/material';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { darkModeToggle, setTheme } from 'app/shared/reducers/dark-mode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import './primary-header.scss';
import { Close, Logout } from '@mui/icons-material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { logout, resetLogin, toggleModalLogin } from 'app/shared/reducers/authentication';
import {
  clearExistingActivities,
  clearSearchKeyword,
  clearSearhedActivities,
  filterActivities,
  searchActivities,
  searchActivitiesCount,
  searchBlogs,
  searchUnauthorizedActivitiesCount,
  unAuthSearchActivities,
  updateEventType,
  updateFilterProperties,
  updateSearchKeyword,
} from 'app/shared/reducers/activity-filter';
import CloseIcon from '@mui/icons-material/Close';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { Storage } from 'react-jhipster';
import { onForegroundMessage } from 'app/firebase';
import { fetchNotifications, submitNotifications } from 'app/shared/reducers/categories';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from 'axios';
import { convertDateToYear, generateGMapsLinkCords } from 'app/utils/common-utils/common-utils';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { LeaveActivity } from 'app/shared/reducers/activity/activity-actions';
import { fetchBanned, fetchBookMarkedItems, fetchProfileDetails } from 'app/shared/reducers/profile';
import toast, { LoaderIcon } from 'react-hot-toast';
import StaticGMapWithMarker from 'app/components/common/google-maps/static-g-map-with-marker';
import { postDisputeReport, updateResponse } from 'app/shared/reducers/notifications';
import AlertDialog from 'app/components/common/alert-dialog/alert-dialog';
import WebSocketContext from 'app/components/common/cards/chat-card/WebSocketContext';
import { clearPhoneVerification } from 'app/shared/reducers/phone-verification';
import { clearUserPreferences } from 'app/shared/reducers/user-preferences';
import Cookies from 'js-cookie';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';

const useLocalStorageToken = (key: string) => {
  const [storedValue, setStoredValue] = useState<string | null>(localStorage.getItem(key));

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(localStorage.getItem(key));
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  return storedValue;
};
interface PrimarySearchAppBarProps {
  searchKeyword?: string;
  setSearchKeyword?: React.Dispatch<React.SetStateAction<string>>;
  isSearching?: boolean;
  setIsSearching?: React.Dispatch<React.SetStateAction<boolean>>;
  localSearchInput?: string;
  setLocalSearchInput?: React.Dispatch<React.SetStateAction<string>>;
  changePrimary?: any;
}

const NewPrimarySearchAppBar = ({
  searchKeyword,
  setSearchKeyword,
  setIsSearching,
  isSearching,
  localSearchInput,
  setLocalSearchInput,
  changePrimary,
}: PrimarySearchAppBarProps) => {
  const token = useLocalStorageToken('jhi-authenticationToken');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const reduxDarkModeValue = useAppSelector(state => state.darkMode.darkMode);
  const userPreferences = useAppSelector(state => state.userPreferencesSettings.userPreferencesCurrentUser);
  const showAuthModal = useAppSelector(state => state.authentication.showModalLogin);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const belowLg = useMediaQuery(theme.breakpoints.down(900));
  const searchResults = useAppSelector(state => state.filters.searchResults);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(true);
  const location = useLocation();
  const account = JSON.parse(localStorage.getItem('MyDetailsAccount') || '{}');
  const [isForegroundMessage, setIsForegroundMessage] = useState(false);
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [myBoolean, setMyBoolean] = useState<boolean>(false);
  const [leaved, setLeaved] = useState(false);
  const [displayGoalInput, setDisplayGoalInput] = useState<number | null>(null);
  const [displayJoinNow, setDisplayJoinNow] = useState<number | null>(null);
  const [displaySignPetition, setDisplaySignPetition] = useState<number | null>(null);
  const [displayJustifyACtivity, setDisplayJustifyACtivity] = useState<number | null>(null);
  const [displayLeaveACtivity, setDisplayLeaveACtivity] = useState<number | null>(null);
  const [displayJustifyPetition, setDisplayJustifyPetition] = useState<number | null>(null);
  const [displayJustifyButton, setDisplayJustifyButton] = useState<number | null>(null);
  const [displayTargetSig, setDisplayTargetSig] = useState<number | null>(null);
  const [displayTargetMap, setDisplayTargetMap] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [isResponseSaved, setIsResponseSaved] = useState(false);
  const [isDisputeSubmitted, setDisputeSubmitted] = useState(false);
  const [announceArrival, setAnnounceArrival] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down(575));
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const { filter, eventType } = useAppSelector(state => state.filters.RequestBody);

  const EXPIRATION_TIME = 1 * 60 * 1000;

  useEffect(() => {
    const tokenData = JSON.parse(localStorage.getItem('jhi-authenticationToken'));
    if (tokenData) {
      const currentTime = new Date().getTime();
      const expirationTime = tokenData.timestamp + EXPIRATION_TIME;

      if (currentTime >= expirationTime) {
        // Token is expired
        dispatch(logout);
      } else {
        // Set timeout to check token expiration
        const timeout = setTimeout(() => {
          dispatch(logout);
        }, expirationTime - currentTime);

        return () => clearTimeout(timeout);
      }
    }
  }, []);

  // handle search in small screens.
  const { disconnect } = useContext(WebSocketContext);
  const handleSearchIconClick = () => {
    if (searchKeyword?.length) {
      disconnect();
      isSearching ? handleSearchClear() : handleSearchButton();
    } else if (!searchKeyword?.length) {
      if (localStorage.getItem('searchKeyword')) {
        disconnect();
        handleSearchButton();
      } else {
        setIsSearchBarVisible(prevState => !prevState);
      }
    }
  };

  const [unreadCount, setUnreadCount] = useState<number | null>(null);
  const fetchUnreadCount = async () => {
    try {
      const response = await axios.get<any>(`/api/notification/unreadChatCount`);
      if (response) {
        setUnreadCount(response?.data);
      }
    } catch (error) {
      console.log('Error fetching goal:', error);
    }
  };

  useEffect(() => {
    console.log('prmheader');
    setTimeout(() => {
      fetchUnreadCount();
    }, 100);
  }, [changePrimary]);

  onForegroundMessage()
    .then(payload => {
      console.log('Received foreground message header: ', payload);
      Storage.local.set('Foreground', payload);
      setIsForegroundMessage(true);
      fetchUnreadCount();
    })
    .catch(err => console.log('An error occurred while retrieving foreground message. ', err));

  useEffect(() => {
    const channel = new BroadcastChannel('sw-messages');

    const handleMessage = (event: any) => {
      console.log(event.data);
      Storage.local.set('Foreground', event.data);
      setIsForegroundMessage(true);
      fetchUnreadCount();
    };

    channel.addEventListener('message', handleMessage);

    return () => {
      channel.removeEventListener('message', handleMessage);
      channel.close();
    };
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [isBlogListPage, setIsBlogListPage] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(' ');
  const [authModalOpen, setAuthModalOpen] = useState(false);

  const handleChatIconClick = async () => {
    if (localStorage.getItem('dataKey')) {
      localStorage.removeItem('dataKey');
    }
    if (isAuthenticated) {
      const res = await dispatch(fetchBanned({ userId: JSON.parse(localStorage.getItem('MyDetailsAccount'))?.id }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }
    }
    if (isAuthenticated) {
      fetchUnreadCount();
      isMobile ? navigate('/chat/list') : navigate('/chat');
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());
      setAuthModalOpen(true);
    }
  };

  const handleDashboardIconClick = () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());
      setAuthModalOpen(true);
    } else {
      navigate('/');
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const [disabledInputs, setDisabledInputs] = useState<number[]>([]);
  const handleSubmit = (data: any, index: number) => {
    const payload = {
      activityId: data?.eventId,
      achievedGoalCount: Number(inputValue),
    };

    handleGoalInputSubmit(payload);
    setDisabledInputs(prevState => [...prevState, index]);
  };

  const handleDispute = async (notification: any, index: number) => {
    // dispatch(postDisputeReport({ notificationId: notification?.id, disputeMessage: inputValue }));

    try {
      const actionResult = await dispatch(postDisputeReport({ notificationId: notification?.id, disputeMessage: inputValue }));
      if (postDisputeReport.fulfilled.match(actionResult)) {
        setDisputeSubmitted(true);
      }
    } catch (error) {
      toast.dismiss();
      toast.error('An error occurred', error);
    }
  };

  const [isSubmitting, setSubmitting] = useState(false);
  const [goalSubmitted, setGoalSubmitted] = useState(false);

  const handleGoalInputSubmit = async (payload: any) => {
    setSubmitting(true);
    try {
      const response = await axios.post<any>('api/activity/goals/achieved', payload);
      if (response) {
        dispatch(clearExistingActivities());
        setSubmitting(false);
        setGoalSubmitted(true);
        setInputValue('');
        setIsResponseSaved(true);
        const updatedProperties = {
          nearMe: null,
          country: null,
          state: null,
          city: null,
          organizationId: null,
          remoteEvents: false,
          petition: false,
          mySelf: false,
          categoryId: null,
          subCategoryId: null,
          currentLatitude: JSON.parse(localStorage.getItem('currentLatitude') || 'null'),
          currentLongitude: JSON.parse(localStorage.getItem('currentLongitude') || 'null'),
        };
        dispatch(updateFilterProperties(updatedProperties));
        dispatch(updateFilterProperties(updatedProperties));
      }
    } catch (error) {
      setSubmitting(false);
      toast.error('An error occurred');
    }
  };
  const handleParticipantSubmit = (data: any, index: number, toogle: boolean) => {
    const payload = {
      activityId: data?.eventId,
      goalAchieved: toogle,
      notificationId: data?.id,
    };

    handleGoalParticipantSubmit(payload);
    setDisabledInputs(prevState => [...prevState, index]);
  };

  const handleLeaveActivity = async (eventId: any, notificationId: any) => {
    setSubmitting(true);
    const params = {
      activityIds: [eventId],
      message: '',
    };

    try {
      const postResponse = await dispatch(LeaveActivity(params));
      if (postResponse.payload != undefined) {
        dispatch(fetchProfileDetails({ id: myProfileId, eventType: 'Bookmarked' }));
        dispatch(updateResponse({ notificationId }));

        toast.dismiss();
        toast.success('Left successfully');
        setDisplayLeaveACtivity(null);
        setLeaved(true);
        setSubmitting(false);
      } else {
        setSubmitting(false);
        toast.dismiss();
        toast.error('Status not updated');
      }
    } catch (error) {
      setSubmitting(false);
      toast.error('Something went wrong');
      console.error(error);
    }
  };

  const handleLeaveSubmit = (data: any, index: number) => {
    handleLeaveActivity(data?.eventId, data?.id);
    setDisabledInputs(prevState => [...prevState, index]);
  };

  const handleGoalParticipantSubmit = async (payload: any) => {
    try {
      const response = await axios.post<any>('api/activity/goals/achieved/user', payload);
      if (response) {
        setInputValue('');
        setIsResponseSaved(true);
        setDisplayJustifyButton(null);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const joinAndProceed = async (data: any) => {
    navigate(`/activity/${data?.eventId}`);
  };

  const signPetition = async (data: any) => {
    navigate(`/petition/${data?.eventId}`);
  };

  const back = async () => {
    navigate(`/`);
  };

  const popupRef = useRef(null);
  const dialogRef = useRef(null);

  const handleNotificationIconClick = () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());
      setAuthModalOpen(true);
    } else {
      setMyBoolean(!myBoolean);
      setIsForegroundMessage(false);
    }
  };
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (announceArrival) {
        if (popupRef.current && !popupRef.current.contains(event.target) && dialogRef.current && dialogRef.current.contains(event.target)) {
          setMyBoolean(false);
        }
      } else {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setMyBoolean(false);
        }
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dialogRef, popupRef, announceArrival]);

  useEffect(() => {
    const box = popupRef.current;

    if (!box) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = box;
      const scrollPercentage = (scrollTop / (scrollHeight - clientHeight)) * 100;
      console.log('check scroll ', scrollPercentage);
      if (scrollPercentage >= 99) {
        setPage(prev => prev + 1);
      }
      if (scrollPercentage === 0) {
        setPage(0);
      }
    };

    const debouncedHandleScroll = debounce(handleScroll, 100);

    box.addEventListener('scroll', debouncedHandleScroll);
    return () => box.removeEventListener('scroll', debouncedHandleScroll);
  }, [popupRef.current, myBoolean]);

  useEffect(() => {
    if (isResponseSaved) {
      const timer = setTimeout(() => {
        setIsResponseSaved(false);
        setDisplayGoalInput(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [isResponseSaved]);

  const handleNotificationClick = async (data: any) => {
    const response = await dispatch(submitNotifications(data?.id));
    for (let i = 0; i < notificationData.length; i++) {
      if (notificationData[i].id === data?.id && response) {
        notificationData[i].read = true;
        break;
      }
    }
    setDisplayGoalInput(null);
    setDisplayJoinNow(null);
    setDisplayJustifyACtivity(null);
    setDisplayLeaveACtivity(null);
    setDisplayJustifyPetition(null);
    setDisplayJustifyButton(null);
    setDisplayTargetSig(null);
    setDisplayTargetMap(null);
    setDisplaySignPetition(null);
    setInputValue('');
    setIsResponseSaved(false);

    const notificationIndex = notificationData.findIndex(notification => notification.id === data.id);
    if (data?.notificationType === 'PUBLIC_ACTIVITY_CREATE_PREVIOUS_ACTIVITY_PARTICIPANTS_OF_ORGANIZER') {
      setDisplayJoinNow(notificationIndex);
    } else if (data?.notificationType === 'Organization_Invitation') {
      if (data?.eventId) navigate(`/organization/${data?.eventId}`);
    } else if (data?.notificationType === 'PRIVATE_ACTIVITY_CREATE_PREVIOUS_ACTIVITY_ORGANIZER_INVITEES') {
      setDisplayJoinNow(notificationIndex);
    } else if (data?.notificationType === 'PUBLIC_ACTIVITY_CREATE_ACTIVITY_FAVOURITE') {
      setDisplayJoinNow(notificationIndex);
    } else if (data?.notificationType === 'PRIVATE_ACTIVITY_CREATE_ACTIVITY_ALL_PARTICIPANTS') {
      setDisplayJoinNow(notificationIndex);
    } else if (data?.notificationType === 'ACTIVITY_UPDATE_ACTIVITY_ORGANIZER') {
      navigate(`/activity/${data?.eventId}`);
    } else if (data?.notificationType === 'ACTIVITY_UPDATE_ACTIVITY_ALL_PARTICIPANTS') {
      setDisplayLeaveACtivity(notificationIndex);
    } else if (data?.notificationType === 'EVENT_REPORT_ACTIVITY_ORGANIZER') {
      setDisplayJustifyACtivity(notificationIndex);
    } else if (data?.notificationType === 'EVENT_REPORT_PETITION_ORGANIZER') {
      setDisplayJustifyPetition(notificationIndex);
    } else if (data?.notificationType === 'EVENT_REPORT_ACTIVITY_ALL_PARTICIPANTS') {
    } else if (data?.notificationType === 'EVENT_REPORT_PETITION_ALL_PARTICIPANTS') {
      navigate(`/petition/${data?.eventId}`);
    } else if (data?.notificationType === 'PUBLIC_PETITION_CREATE_PETITION_FAVOURITE') {
      setDisplaySignPetition(notificationIndex);
    } else if (data?.notificationType === 'PETITION_GOAL_REACHED_PETITION_ORGANIZER') {
      navigate(`/petition/${data?.eventId}`);
      setDisplayTargetSig(notificationIndex);
    } else if (
      data?.notificationType === 'ACTIVITY_START_ACTIVITY_ALL_PARTICIPANTS' ||
      data?.notificationType === 'ACTIVITY_START_1H_ACTIVITY_ALL_PARTICIPANTS' ||
      data?.notificationType === 'ACTIVITY_START_24H_ACTIVITY_ALL_PARTICIPANTS'
    ) {
      setDisplayTargetMap(notificationIndex);
    } else if (data?.notificationType === 'PUBLIC_ACTIVITY_CLOSE_ACTIVITY_ORGANIZER') {
      setDisplayGoalInput(notificationIndex);
    } else if (data?.notificationType === 'PUBLIC_ACTIVITY_CLOSE_ACTIVITY_VOLUNTEERS') {
      setDisplayJustifyButton(notificationIndex);
    } else if (data?.notificationType === 'PRIVATE_ACTIVITY_CLOSE_ACTIVITY_ALL_PARTICIPANTS') {
      setDisplayJustifyButton(notificationIndex);
    } else if (data?.notificationType === 'PRIVATE_ACTIVITY_CLOSE_ACTIVITY_ORGANIZER') {
      setDisplayGoalInput(notificationIndex);
    } else if (data?.notificationType === 'CHAT_REPORT_CHAT_POSTED_BY') {
      setDisplayJustifyACtivity(notificationIndex);
    } else if (data?.notificationType === 'PUBLIC_ACTIVITY_CREATE_URGENT_PREVIOUS_ACTIVITY_PARTICIPANTS_OF_ORGANIZER') {
      setDisplayJoinNow(notificationIndex);
    } else if (
      data?.notificationType === 'ACTIVITY_ANNOUNCE_ARRIVAL_ACTIVITY_ALL_PARTICIPANTS' ||
      data?.notificationType === 'ACTIVITY_JOIN_ACTIVITY_CURRENT_VOLUNTEER'
    ) {
      setAnnounceArrival(true);
    } else if (data?.notificationType === 'ACTIVITY_JOIN_ACTIVITY_ORGANIZER' || data?.notificationType === 'CHAT_POST_ALL_PARTICIPANTS') {
      navigate(`/activity/${data?.eventId}`);
    }
  };

  const [notificationsFetching, setNotificationsFetching] = useState(false);
  const [notificationSetting, setNotificationsSetting] = useState(true);
  useEffect(() => {
    setNotificationsFetching(true);
    const fetchData = async () => {
      if (myBoolean) {
        if (page === 0 && notificationSetting) {
          setNotificationData([]);
        }
        try {
          const response = await dispatch(fetchNotifications(page));
          setNotificationsFetching(false);
          setNotificationsSetting(false);
          setNotificationData(prevData => [...prevData, ...response]);
        } catch (error) {
          setNotificationsFetching(false);
          console.log(error);
        }
      }
    };

    fetchData();
  }, [myBoolean, dispatch, page]);

  useEffect(() => {
    setPage(0);
    const userId = localStorage.getItem('MyDetailsAccount') ? JSON.parse(localStorage.getItem('MyDetailsAccount')).id : null;
    const filteredNotifications = notificationData.filter(newActivity => newActivity.userId === userId);

    setNotificationData(filteredNotifications);
  }, []);

  const formatTime = (isoTime: string) => {
    const date = new Date(isoTime);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };
  // open authentication modal dialog
  const handleAuthModalOpen = () => {
    dispatch(toggleModalLogin());
    setAuthModalOpen(true);
  };
  // close authentication modal dialog
  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
    dispatch(resetLogin());
  };

  const handleNo = () => {
    setOpen(false);
  };
  const handleLogin = () => {
    dispatch(toggleModalLogin());
    setCurrentComponent('login');
    handleAuthModalOpen();
    handleMenuClose();
  };

  const handleLogout = () => {
    setCurrentComponent('logout');
    handleAuthModalOpen();
    handleMenuClose();
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const toggleThemeHandler = () => {
    dispatch(darkModeToggle(!reduxDarkModeValue));
    Storage.local.set('dark-mode', !reduxDarkModeValue);
    if (isAuthenticated) {
      setTimeout(() => {
        dispatch(setTheme({ darkMode: reduxDarkModeValue }));
      }, 2000);
    }
  };
  const handleSearchClear = () => {
    if (location.pathname === '/') {
      if (setIsSearching) {
        setIsSearching(false);
      }
      setSearchKeyword('');
      setLocalSearchInput('');
      dispatch(clearSearchKeyword());
      dispatch(clearSearhedActivities());
      localStorage.removeItem('searchKeyword');
    }
  };
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallerSearch = useMediaQuery(theme.breakpoints.down(599));

  useEffect(() => {
    if (!showAuthModal && isAuthenticated) {
      setAuthModalOpen(false);
    }
    setNotificationData([]);
  }, [showAuthModal, isAuthenticated]);

  const handleSearchButton = () => {
    if (location.pathname === '/') {
      if (searchKeyword) {
        setIsSearching(true);
        if (isAuthenticated) {
          dispatch(searchActivities({ keyword: searchKeyword }));
          dispatch(searchActivitiesCount({ keyword: searchKeyword, startDate: null, endDate: null }));
        } else {
          dispatch(unAuthSearchActivities({ keyword: searchKeyword }));
          dispatch(searchUnauthorizedActivitiesCount({ keyword: searchKeyword, startDate: null, endDate: null }));
        }
        dispatch(updateSearchKeyword(searchKeyword));
      }
    } else if (location.pathname !== '/blog-list' && location.pathname !== '/') {
      navigate('/');
    } else if (location.pathname === '/blog-list') {
      if (isAuthenticated) {
        dispatch(searchBlogs({ keyword: localStorage.getItem('searchKeyword') }));
      }
      dispatch(updateSearchKeyword(localStorage.getItem('searchKeyword')));
    }
  };

  useEffect(() => {
    if (localStorage.getItem('searchKeyword') && location.pathname === '/') {
      setIsSearching(true);
      setSearchKeyword(localStorage.getItem('searchKeyword') || '');
      if (isAuthenticated) {
        dispatch(searchActivities({ keyword: localStorage.getItem('searchKeyword') }));
        dispatch(searchActivitiesCount({ keyword: searchKeyword, startDate: null, endDate: null }));
      } else {
        dispatch(unAuthSearchActivities({ keyword: localStorage.getItem('searchKeyword') }));
        dispatch(searchUnauthorizedActivitiesCount({ keyword: searchKeyword, startDate: null, endDate: null }));
      }
      dispatch(updateSearchKeyword(localStorage.getItem('searchKeyword') || ''));
    }
  }, [localStorage.getItem('searchKeyword')]);

  const handleSearchInput = (input: any) => {
    setSearchKeyword(input.trim());
    setLocalSearchInput(input);
  };
  const iconStyle = {
    color: '#674BFF',
  };
  const menuId = 'primary-search-menu';
  const renderMenu = (
    <Menu
      sx={{ top: '37px', left: '15px' }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isAuthenticated ? (
        <>
          {location.pathname !== '/profile' && (
            <MenuItem
              onClick={() => {
                disconnect();
                navigate('/profile');
              }}
              style={{ fontSize: '14px' }}
            >
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              Profile
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              disconnect();
              navigate('/settings');
            }}
            style={{ fontSize: '14px' }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout} style={{ fontSize: '14px' }}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            Logout
          </MenuItem>
        </>
      ) : (
        <MenuItem onClick={handleLogin} style={{ fontSize: '14px' }}>
          <ListItemIcon>
            <LoginIcon />
          </ListItemIcon>
          Login
        </MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = 'menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton onClick={toggleThemeHandler} size="large" aria-label="dark-mode-toggler" color="inherit">
          <Badge>{theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeToggleIcon />}</Badge>
        </IconButton>
      </MenuItem>
      {isAuthenticated && location.pathname !== '/profile' && (
        <MenuItem>
          <IconButton
            onClick={() => {
              disconnect();
              navigate('/profile');
            }}
          >
            <AccountBoxIcon />
          </IconButton>
        </MenuItem>
      )}
      {isAuthenticated && (
        <MenuItem>
          <IconButton
            onClick={() => {
              disconnect();
              navigate('/settings');
            }}
          >
            <SettingsIcon />
          </IconButton>
        </MenuItem>
      )}

      {isAuthenticated ? (
        <MenuItem onClick={handleLogout}>
          <IconButton size="large" color="inherit">
            <LogoutIcon />
          </IconButton>
        </MenuItem>
      ) : (
        <MenuItem onClick={handleLogin}>
          <IconButton size="large" color="inherit">
            <LoginIcon />
          </IconButton>
        </MenuItem>
      )}
    </Menu>
  );

  useEffect(() => {
    if (belowLg) {
      setIsSearchBarVisible(false);
    }
    return () => {
      setIsSearchBarVisible(true);
    };
  }, [belowLg]);

  const processNotificationMessage = (message: string) => {
    const dateTimePattern = /##(.*?)##/g;
    message = message.replace(dateTimePattern, (_, dateTime) => formatTime(dateTime));
    const dateYearPattern = /#(.*?)#/g;
    message = message.replace(dateYearPattern, (_, dateTime) => convertDateToYear(dateTime));

    return message;
  };

  return (
    <>
      {announceArrival && (
        <div ref={dialogRef}>
          <AlertDialog
            agreeAction={() => { }}
            title="Hey there!"
            description="Announce your arrival by downloading the ReachAMY mobile app from the App Store or Google Play Store"
            open={announceArrival}
            setAlertOpen={setAnnounceArrival}
            okayButtonText="Get Now"
            closeButtonText="Close"
          />
        </div>
      )}
      <Box
        sx={{
          borderBottom: 'none',
          borderLeft: 'none',
          background: isDarkMode ? '#1F1F26' : '#f6f5f1',
        }}
      >
        <div className="d-flex align-items-center" style={{ gap: '50px' }}>
          <div
            className="icon-class"
            style={{ paddingLeft: '4%', cursor: 'pointer' }}
            onClick={() => {
              navigate('/');
            }}
          >
            <AmyIcon sx={{ width: '159px', height: '100px' }} />
          </div>
          <div className="container ml-0 contain-1 " style={{ maxWidth: 'inherit', paddingLeft: '0px', paddingTop: '.5rem' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: ' center',
                flexDirection: 'row',
              }}
            >
              <div
                className="form-spacing"
                style={{ width: isSmallerSearch ? '' : '190%', visibility: isSearchBarVisible ? 'visible' : 'hidden' }}
              >
                <FormControl className="w-100" sx={{ width: '100%' }} variant="outlined">
                  <OutlinedInput
                    onMouseLeave={e => {
                      if (!searchKeyword?.length && searchResults) {
                        handleSearchClear();
                      }
                    }}
                    onKeyDown={e => {
                      if (location.pathname === '/') {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSearchButton();
                        }
                      } else if (location.pathname !== '/blog-list' && location.pathname !== '/') {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          handleSearchButton();
                        }
                      }
                    }}
                    className=" phone-se phone-search phone-search-1 phone-search-2 .phone-search-3"
                    sx={{
                      height: '40px',
                      color: 'secondary.main',
                      border: isDarkMode ? '#ff5500' : 'none',
                      '& .MuiOutlinedInput-input::placeholder': {
                        color: 'secondary.contrastText',
                        fontSize: '16px',
                      },
                      '&.MuiOutlinedInput-root': {
                        background: isDarkMode ? theme.palette.background.default : '#FCFCFC',
                        borderRadius: '14px',
                      },
                      '& fieldset': {
                        borderColor: isDarkMode ? 'none' : '#FCFCFC',
                      },
                    }}
                    size="small"
                    id="searchBar"
                    type={'text'}
                    value={localSearchInput}
                    placeholder={location.pathname === '/blog-list' ? 'Search for blogs' : 'Search for any activity'}
                    onChange={e => {
                      if (location.pathname === '/') {
                        setIsSearching(false);
                        handleSearchInput(e.target.value);
                      } else if (location.pathname !== '/blog-list' && location.pathname !== '/') {
                        localStorage.setItem('searchKeyword', e.target.value);
                      } else if (location.pathname === '/blog-list') {
                        localStorage.setItem('searchKeyword', e.target.value);
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end" className="mob-search">
                        <IconButton
                          aria-label="search button"
                          edge="end"
                          className="mob-search"
                          onClick={isSearching ? handleSearchClear : handleSearchButton}
                        >
                          {isSearching ? <Close /> : <SearchOutlinedIcon className="mob-search" />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>

              <div className="w-100 amy-top-nav-button media-900" style={{ color: 'primary.light' }}>
                <ButtonGroup variant="text" sx={{ color: 'primary.light' }} className="mx-3" aria-label=" button group">
                  <Button
                    className={`group-btn ${isBlogListPage ? 'underlined' : ''}`}
                    sx={{
                      color: 'primary.light',
                      fontWeight: '600',
                    }}
                    onClick={() => {
                      dispatch(clearExistingActivities());
                      dispatch(clearSearhedActivities());
                      navigate('/blog-list');
                    }}
                  >
                    BLOG
                  </Button>

                  <Button sx={{ color: 'primary.light', fontWeight: '600' }} className="group-btn" onClick={() => navigate('/stats')}>
                    STATS
                  </Button>
                </ButtonGroup>
              </div>
              <div
                className="profile-item"
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: isSmallerScreen ? 'start' : 'end',
                  flexDirection: 'row',
                }}
              >
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <IconButton size="large" aria-label="dark-mode-toggler" onClick={toggleThemeHandler}>
                    <Badge>
                      {theme.palette.mode === 'dark' ? (
                        <LightModeIcon sx={{ fontSize: 'large', color: '#ffff' }} />
                      ) : (
                        <DarkModeToggleIcon sx={{ fontSize: 'large', color: 'rgba(0, 0, 0, 0.54)' }} />
                      )}
                    </Badge>
                  </IconButton>
                  <IconButton size="large" aria-label="chat" onClick={handleChatIconClick}>
                    <Badge badgeContent={unreadCount} color="primary">
                      {theme.palette.mode === 'dark' ? <QuestionAnswerRoundedIcon /> : <MessageOutlinedIcon />}
                    </Badge>
                  </IconButton>
                  <IconButton size="large" aria-label="notifications" onClick={handleNotificationIconClick}>
                    {isForegroundMessage ? (
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        badgeContent={
                          <div
                            style={{ background: '#674bff', width: '8px', height: '8px', borderRadius: '50%', transform: 'rotate(-45deg)' }}
                          ></div>
                        }
                      >
                        {theme.palette.mode === 'dark' ? (
                          <NotificationsRoundedIcon style={{ transform: 'rotate(-45deg)' }} />
                        ) : (
                          <NotificationsNoneOutlinedIcon style={{ transform: 'rotate(-45deg)' }} />
                        )}
                      </Badge>
                    ) : theme.palette.mode === 'dark' ? (
                      <NotificationsRoundedIcon />
                    ) : (
                      <NotificationsNoneOutlinedIcon />
                    )}
                  </IconButton>

                  {isAuthenticated ? (
                    <Avatar
                      className="m-2 my-3"
                      alt={userPreferences?.profilePic ? '' : account?.firstName ? account.firstName[0] : null}
                      src={userPreferences?.profilePic}
                      sx={{
                        width: '42px',
                        height: '42px',
                        cursor: 'pointer',
                        backgroundColor: isAuthenticated ? 'primary.light' : '',
                        color: isAuthenticated ? 'common.white' : '',
                      }}
                      onClick={handleClick}
                    >
                      {account?.firstName && account?.firstName.charAt(0)?.toUpperCase()}
                      {account?.lastName && account?.lastName.charAt(0)?.toUpperCase()}
                    </Avatar>
                  ) : (
                    <Avatar
                      className="m-2 my-3"
                      alt="avatar-logo"
                      sx={{
                        width: '42px',
                        height: '42px',
                        cursor: 'pointer',
                      }}
                      onClick={handleClick}
                    />
                  )}
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                  <IconButton size="large" aria-label="s" onClick={handleSearchIconClick} sx={{ p: 1 }}>
                    {isSearching ? (
                      <Close />
                    ) : (
                      <Badge>{theme.palette.mode === 'dark' ? <SearchRoundedIcon /> : <SearchOutlinedIcon />}</Badge>
                    )}
                  </IconButton>
                  {isSearchBarVisible && <SearchOutlinedIcon className="mob-search" />}
                  {!isSearchBarVisible && (
                    <>
                      <IconButton size="large" aria-label="chat" color="inherit" onClick={handleChatIconClick}>
                        <Badge badgeContent={unreadCount} color="primary">
                          {theme.palette.mode === 'dark' ? <QuestionAnswerRoundedIcon /> : <SquareChatIcon />}
                        </Badge>
                      </IconButton>
                      <IconButton size="large" aria-label="notifications" onClick={handleNotificationIconClick}>
                        {isForegroundMessage ? (
                          <Badge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            badgeContent={
                              <div
                                style={{
                                  background: '#674bff',
                                  width: '8px',
                                  height: '8px',
                                  borderRadius: '50%',
                                  transform: 'rotate(-45deg)',
                                }}
                              ></div>
                            }
                          >
                            {theme.palette.mode === 'dark' ? (
                              <NotificationsRoundedIcon style={{ transform: 'rotate(-45deg)' }} />
                            ) : (
                              <NotificationsNoneOutlinedIcon style={{ transform: 'rotate(-45deg)' }} />
                            )}
                          </Badge>
                        ) : theme.palette.mode === 'dark' ? (
                          <NotificationsRoundedIcon />
                        ) : (
                          <NotificationsNoneOutlinedIcon />
                        )}
                      </IconButton>
                      {isAuthenticated ? (
                        <Avatar
                          aria-controls={mobileMenuId}
                          alt={userPreferences?.profilePic ? '' : account?.firstName ? account.firstName[0] : null}
                          src={userPreferences?.profilePic}
                          sx={{
                            width: 25,
                            height: 25,
                            cursor: 'pointer',
                            top: '10px',
                            fontSize: 12,
                            backgroundColor: isAuthenticated ? 'primary.light' : '',
                            color: isAuthenticated ? 'common.white' : '',
                          }}
                          onClick={handleMobileMenuOpen}
                        >
                          {account?.firstName && account?.firstName.charAt(0)?.toUpperCase()}
                          {account?.lastName && account?.lastName.charAt(0)?.toUpperCase()}
                        </Avatar>
                      ) : (
                        <Avatar
                          className="m-2 my-3"
                          alt="avatar-logo"
                          sx={{
                            width: 25,
                            height: 25,
                            cursor: 'pointer',
                          }}
                          onClick={handleMobileMenuOpen}
                        />
                      )}
                    </>
                  )}
                </Box>
              </div>
            </div>
          </div>
        </div>

        {renderMobileMenu}
        {renderMenu}
        {myBoolean && (
          <div
            className="popup px-0"
            ref={popupRef}
            style={{
              background: isDarkMode ? '#373742' : ' ',
            }}
          >
            <div className="col-12 w-80 w-md-100 d-flex justify-content-end" style={{ cursor: 'pointer', paddingRight: '10px' }}>
              <Close onClick={handleNotificationIconClick} />
            </div>
            {notificationData?.length > 0
              ? notificationData?.map((notification, index) => (
                <div
                  key={notification.id}
                  className="popup-content"
                  style={{ background: isDarkMode ? (notification?.read ? '' : 'rgb(28 27 27)') : notification?.read ? '' : '#f0f8ff' }}
                >
                  <div className="profile-icon " onClick={() => handleNotificationClick(notification)}>
                    <Avatar
                      alt="avatar"
                      src={notification?.eventProfile ? notification.eventProfile : 'content/images/login-page/profile_avatar.png'}
                      sx={{ width: '30px', height: '29px', cursor: 'pointer' }}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <div
                      className="notification-text"
                      style={{
                        color: isDarkMode ? '#fff' : '',
                        textAlign: 'justify',
                        fontWeight: notification?.read ? 'normal' : 'normal',
                      }}
                      onClick={() => handleNotificationClick(notification)}
                    >
                      {processNotificationMessage(notification?.message)}
                    </div>
                    <div className="timestamp" onClick={() => handleNotificationClick(notification)}>
                      <CalendarMonthIcon sx={{ height: '12px', width: '15px', color: '#674BFF' }} />{' '}
                      {convertDateToYear(notification?.notificationTime)} {formatTime(notification.notificationTime)}
                    </div>

                    {displayGoalInput === index && (
                      <Box mt={0.7}>
                        {!goalSubmitted && (
                          <>
                            <input
                              type="number"
                              style={{
                                border: 'none',
                                borderBottom: '1px solid #9b9b9b',
                                width: '70px',
                                fontSize: '12px',
                                background: '#f5f5f5',
                              }}
                              value={inputValue}
                              maxLength={8}
                              onChange={event => {
                                const value = event.target.value;
                                if (/^[1-9][0-9]*$/.test(value) && value.length <= 7) {
                                  handleInputChange(event);
                                } else if (value === '') {
                                  handleInputChange(event);
                                }
                              }}
                              onKeyPress={event => {
                                if (event.key === '-' || event.key === 'e' || event.key === 'E') {
                                  event.preventDefault();
                                }
                              }}
                            />
                            <span style={{ color: isDarkMode ? '#FFCE00' : '#674bff', fontSize: '12px', marginRight: '5px' }}>
                              {notification?.textValue2}
                            </span>
                            <Button
                              sx={{
                                background: isDarkMode ? '#FFCE00' : '#674bff',
                                borderRadius: '25px',
                                padding: '2px 13px',
                                color: isDarkMode ? '#373742' : '#fff',
                                fontSize: '11px',
                                '&.MuiButtonBase-root:hover': {
                                  background: isDarkMode ? '#FFCE00' : '#674bff',
                                },
                                '&.MuiButton-root.Mui-disabled': {
                                  color: isDarkMode ? '#373742' : '#fff',
                                },
                              }}
                              endIcon={isSubmitting ? <LoaderIcon /> : null}
                              onClick={() => handleSubmit(notification, index)}
                              disabled={disabledInputs.includes(index) || !inputValue || isSubmitting}
                            >
                              Submit
                            </Button>
                          </>
                        )}
                        {isResponseSaved && (
                          <div style={{ color: isDarkMode ? '#FFCE00' : '#674bff', fontSize: '12px' }}>Your response is saved</div>
                        )}
                      </Box>
                    )}
                    {displayJoinNow === index && (
                      <Box mt={0.8}>
                        <Button
                          sx={{
                            background: isDarkMode ? '#FFCE00' : '#674bff',
                            borderRadius: '25px',
                            padding: '2px 14px',
                            color: isDarkMode ? '#373742' : '#fff',
                            fontSize: '11px',
                            '&.MuiButtonBase-root:hover': {
                              background: isDarkMode ? '#FFCE00' : '#674bff',
                            },
                            '&.MuiButton-root.Mui-disabled': {
                              color: isDarkMode ? '#373742' : '#fff',
                            },
                          }}
                          onClick={() => joinAndProceed(notification)}
                        >
                          Join now
                        </Button>
                      </Box>
                    )}
                    {displaySignPetition === index && (
                      <Box mt={1}>
                        <Button
                          sx={{
                            background: isDarkMode ? '#FFCE00' : '#674bff',
                            borderRadius: '25px',
                            padding: '2px 17px',
                            color: isDarkMode ? '#373742' : '#fff',
                            fontSize: '11px',
                            '&.MuiButtonBase-root:hover': {
                              background: isDarkMode ? '#FFCE00' : '#674bff',
                            },
                            '&.MuiButton-root.Mui-disabled': {
                              color: isDarkMode ? '#373742' : '#fff',
                            },
                          }}
                          onClick={() => signPetition(notification)}
                        >
                          Sign this petition
                        </Button>
                      </Box>
                    )}
                    {displayJustifyACtivity === index && (
                      <Box mt={0.7}>
                        {!isDisputeSubmitted ? (
                          <>
                            <input
                              type="text"
                              style={{
                                border: 'none',
                                borderBottom: '1px solid #000',
                                width: '100%',
                                marginBottom: '3px',
                                fontSize: '12px',
                              }}
                              value={inputValue}
                              onChange={handleInputChange}
                            />
                            <Button
                              disabled={!inputValue}
                              sx={{
                                background: isDarkMode ? '#FFCE00' : '#674bff',
                                borderRadius: '25px',
                                padding: '2px 13px',
                                color: isDarkMode ? '#373742' : '#fff',
                                fontSize: '11px',
                                '&.MuiButtonBase-root:hover': {
                                  background: isDarkMode ? '#FFCE00' : '#674bff',
                                },
                                '&.MuiButton-root.Mui-disabled': {
                                  color: isDarkMode ? '#373742' : '#fff',
                                },
                              }}
                              onClick={() => handleDispute(notification, index)}
                            >
                              Submit
                            </Button>
                          </>
                        ) : (
                          <div style={{ color: isDarkMode ? '#FFCE00' : '#674bff', fontSize: '12px' }}>Your response is saved</div>
                        )}
                      </Box>
                    )}
                    {displayJustifyPetition === index && (
                      <Box mt={0.7}>
                        {!isDisputeSubmitted ? (
                          <>
                            <input
                              type="text"
                              style={{
                                border: 'none',
                                borderBottom: '1px solid #000',
                                width: '100%',
                                marginBottom: '3px',
                                fontSize: '12px',
                              }}
                              value={inputValue}
                              onChange={handleInputChange}
                            />
                            <Button
                              disabled={!inputValue}
                              sx={{
                                background: isDarkMode ? '#FFCE00' : '#674bff',
                                borderRadius: '25px',
                                padding: '2px 13px',
                                color: isDarkMode ? '#373742' : '#fff',
                                fontSize: '11px',
                                '&.MuiButtonBase-root:hover': {
                                  background: isDarkMode ? '#FFCE00' : '#674bff',
                                },
                                '&.MuiButton-root.Mui-disabled': {
                                  color: isDarkMode ? '#373742' : '#fff',
                                },
                              }}
                              onClick={() => handleDispute(notification, index)}
                            >
                              Submit
                            </Button>
                          </>
                        ) : (
                          <div style={{ color: isDarkMode ? '#FFCE00' : '#674bff', fontSize: '12px' }}>Your response is saved</div>
                        )}
                      </Box>
                    )}

                    {displayJustifyButton === index && (
                      <Box mt={1}>
                        <Button
                          sx={{
                            background: isDarkMode ? '#FFCE00' : '#674bff',
                            borderRadius: '25px',
                            padding: '1px 0',
                            color: isDarkMode ? '#373742' : '#fff',
                            fontSize: '12px',
                            '&.MuiButtonBase-root:hover': {
                              background: isDarkMode ? '#FFCE00' : '#674bff',
                            },
                            '&.MuiButton-root.Mui-disabled': {
                              color: isDarkMode ? '#373742' : '#fff',
                            },
                          }}
                          className="me-1"
                          onClick={() => handleParticipantSubmit(notification, index, true)}
                        >
                          Yes
                        </Button>
                        <Button
                          sx={{
                            background: isDarkMode ? 'transparent' : '#fff',
                            borderRadius: '25px',
                            padding: '0',
                            color: isDarkMode ? '#FFCE00' : '#674bff',
                            border: isDarkMode ? '1px solid #FFCE00' : '1px solid #674bff',
                            fontSize: '12px',
                            '&.MuiButtonBase-root:hover': {
                              background: isDarkMode ? 'transparent' : '#fff',
                            },
                          }}
                          onClick={() => handleParticipantSubmit(notification, index, false)}
                        >
                          No
                        </Button>
                        {isResponseSaved && (
                          <div style={{ color: isDarkMode ? '#FFCE00' : '#674bff', fontSize: '12px' }}>Your response is saved</div>
                        )}
                      </Box>
                    )}
                    {displayLeaveACtivity === index && (
                      <Box mt={0.7}>
                        <Button
                          sx={{
                            background: isDarkMode ? '#FFCE00' : '#674bff',
                            borderRadius: '25px',
                            padding: '2px 15px',
                            color: isDarkMode ? '#373742' : '#fff',
                            fontSize: '12px',
                            '&.MuiButtonBase-root:hover': {
                              background: isDarkMode ? '#FFCE00' : '#674bff',
                              color: isDarkMode ? '#373742' : '#fff',
                            },
                            '&.MuiButton-root.Mui-disabled': {
                              color: isDarkMode ? '#373742' : '#fff',
                            },
                          }}
                          className="me-1"
                          endIcon={isSubmitting ? <LoaderIcon /> : null}
                          onClick={() => handleLeaveSubmit(notification, index)}
                          disabled={isSubmitting}
                        >
                          Click here to opt out
                        </Button>
                      </Box>
                    )}
                    {displayTargetMap === index && (
                      <Box
                        mt={0.8}
                        style={{ cursor: 'pointer' }}
                        onClick={() => generateGMapsLinkCords(notification?.textValue1, notification?.textValue2)}
                      >
                        <StaticGMapWithMarker
                          latitude={Number(notification?.textValue1)}
                          longitude={Number(notification?.textValue2)}
                          height={'100px'}
                          width={'100%'}
                        />
                      </Box>
                    )}
                  </div>
                </div>
              ))
              : !notificationsFetching && (
                <Box p={3} textAlign="center">
                  <Box
                    component="img"
                    sx={{
                      height: 200,
                      width: 160,
                      maxHeight: { xs: 155, md: 167 },
                      maxWidth: { xs: 150, md: 250 },
                    }}
                    alt="The house from the offer."
                    src="content/images/empty.svg"
                  />
                  <Typography variant="body2"> No Notifications Available</Typography>
                </Box>
              )}
          </div>
        )}
        <AuthorisationModal
          currentComponent={currentComponent}
          fullScreen={fullScreen}
          authModalOpen={authModalOpen}
          onClose={handleAuthModalClose}
          handleAuthModalClose={handleAuthModalClose}
          setCurrentComponent={setCurrentComponent}
          handleSearchClear={handleSearchClear}
        />
      </Box>
    </>
  );
};

export default NewPrimarySearchAppBar;
