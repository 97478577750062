import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, TextField, useTheme } from '@mui/material';
import { useAppDispatch } from 'app/config/store';
import { useParams } from 'react-router-dom';
import { editCommentById } from 'app/shared/reducers/blogs';

const EditCommentDialog = ({ open, onClose, comment, handleEdit }) => {
  const [editedComment, setEditedComment] = useState(handleEdit.comment);
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const blogId = Number(id);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleSave = async () => {
    if (editedComment.length > 400) {
      setError('Comment cannot exceed 400 characters');
      return;
    }
    const params = {
      commentId: handleEdit?.id,
      blogId: blogId,
      commentMessage: editedComment,
    };
    try {
      const postResponse = await dispatch(editCommentById(params));
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setEditedComment('');
    setError('');
    onClose();
  };

  const handleChange = e => {
    setEditedComment(e.target.value);
    if (e.target.value.length > 400) {
      setError('Comment cannot exceed 400 characters');
    } else {
      setError('');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <TextField
          multiline
          rows={4}
          variant="outlined"
          label="Enter the new comment"
          fullWidth
          value={editedComment}
          onChange={handleChange}
          error={!!error}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} sx={{ color: isDarkMode ? '#FFCE00' : '#674bff' }}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          disabled={!editedComment?.trim() || editedComment?.length > 400}
          sx={{ color: isDarkMode ? '#FFCE00' : '#674bff' }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCommentDialog;
