import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Box,
  MenuItem,
  Select,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import './sign-petition-modal.scss';
import { leavePetition, reportCommentPetition, reportPetition, signedPetition } from 'app/shared/reducers/bookmark-activity';
import { Close } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { appConstants } from 'app/utils/data/constants/constants';

interface SignPetitionModalProps {
  fullScreen: boolean;
  id?: any;
  open: boolean;
  respectiveId?: any;
  onClose: (data?: any) => void;
  message?: string;
}

const SignPetitionModal = ({ fullScreen, onClose, id, open, message, respectiveId }: SignPetitionModalProps) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const [showComment, setShowComment] = useState(false);
  const [selectedOption, setSelectedOption] = React.useState('');
  const reportingReasons = useAppSelector(state => state.reportActivityReducer.reportingReasons);

  useEffect(() => {
    if (open) {
      setInputValue('');
      setError('');
    }
  }, [open]);

  useEffect(() => {
    if (setSelectedOption?.length) {
      setInputValue(selectedOption);
    }
  }, [selectedOption]);

  const handleInputChange = e => {
    const value = e.target.value;
    if (value.length > 245) {
      setError('Input exceeds the maximum limit of 245 characters.');
    } else {
      setError('');
    }
    setInputValue(value);
  };

  const handleSubmit = async () => {
    try {
      if (message === 'You have signed because ... ( Optional )') {
        let response;
        const petitionData = {
          reason: inputValue,
          display: showComment,
        };

        response = await dispatch(signedPetition({ number: Number(id), petitionData }));

        onClose('submission');
      } else if (message === 'You have leaving petition because ... ( Optional )') {
        let response;
        const petitionData = {
          reason: inputValue,
        };
        response = await dispatch(leavePetition({ number: Number(id), petitionData }));
        onClose('submission');
      } else if (message === 'Please select a reason for reporting the petition.') {
        let response;
        const petitionData = {
          reason: inputValue,
        };
        response = await dispatch(reportPetition({ number: Number(id), petitionData }));
        if (reportPetition.fulfilled.match(response)) {
          const reportPayload = response?.payload?.message;
          toast.success(reportPayload);
        } else if (reportPetition.rejected.match(response)) {
          const reportError = response.error;
          toast.error(reportError.message);
        }
        onClose('submission');
      } else if (message === 'You are report this comment  because') {
        const petitionData = inputValue,
          response = await dispatch(
            reportCommentPetition({ petitionId: Number(id), signatureId: respectiveId, petitionData: petitionData })
          );

        onClose('submission');
      }
    } catch (error) {
      console.error('Something went wrong', error);
    }
  };
  const showTextField = selectedOption == appConstants?.reportingReasonEnd;

  useEffect(() => {
    return () => {
      setInputValue('');
      setSelectedOption('');
    };
  }, []);
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{
        sx: {
          borderRadius: { xs: 0, sm: '14px' },
          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
          padding: '0.5%',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          cursor: 'pointer',
          paddingTop: '2%',
          paddingRight: '2%',
          paddingBottom: '2%',
          gap: 1,
        }}
      >
        <Close onClick={onClose} />
      </Box>
      <DialogContent sx={{ px: 2 }}>
        <div className="row">
          <div className="col-12">
            <Typography variant="body1">{message}</Typography>
          </div>
          {message === 'You have signed because ... ( Optional )' && (
            <div className="col-12">
              <FormControl component="fieldset" fullWidth>
                <FormControlLabel
                  sx={{ color: 'secondary.main', fontSize: '14px' }}
                  control={
                    <Checkbox
                      name="urgent"
                      disableRipple
                      checked={showComment}
                      onChange={() => setShowComment(!showComment)}
                      sx={{
                        color: 'transparent',
                        '&.Mui-checked': { color: 'primary.light' },
                        '& .MuiSvgIcon-root': {
                          fontSize: 23,
                          borderRadius: '25%',
                          border: '1px solid #BFBFBF',
                          padding: { xs: 'none', md: '3px' },
                        },
                      }}
                    />
                  }
                  label="Show others my comment"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
          )}
          <div className="col-12 mt-3">
            {(message === 'Please select a reason for reporting the petition.' || message === 'You are report this comment  because') && (
              <Box>
                <FormControl fullWidth variant="outlined" sx={{ marginTop: '16px' }}>
                  <Select
                    value={selectedOption}
                    onChange={e => {
                      setSelectedOption(e.target.value);
                    }}
                    size="small"
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: {
                          overflowY: 'scroll',
                        },
                      },
                      MenuListProps: {
                        style: {
                          maxHeight: '200px',
                        },
                      },
                    }}
                  >
                    <MenuItem value="">Select a reason</MenuItem>
                    {reportingReasons?.map((data: any) => (
                      <MenuItem value={data.label} key={data.id}>
                        <Typography sx={{ fontSize: '16px' }}>{data.label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {showTextField && (
                  <TextField
                    label="Details (Optional)"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                    inputProps={{ maxLength: 245 }}
                    // value={reportReason}
                    onChange={handleInputChange}
                    sx={{ marginTop: 2 }}
                  />
                )}
              </Box>
            )}
            {message !== 'Please select a reason for reporting the petition.' && message !== 'You are report this comment  because' && (
              <TextField
                multiline
                rows={4}
                fullWidth
                value={inputValue}
                inputProps={{ maxLength: 245 }}
                onChange={handleInputChange}
                error={!!error}
                helperText={error}
              ></TextField>
            )}
          </div>
          <div className="col-12 mt-2 d-flex justify-content-end">
            <Button onClick={onClose} color="primary">
              Cancel
            </Button>
            <Button
              type="submit"
              sx={{
                textTransform: 'none',
                borderRadius: '15px',
                color: '#FFCE00',
                height: '40px',
              }}
              onClick={handleSubmit}
              disabled={
                !selectedOption &&
                (message === 'Please select a reason for reporting the petition.' || message === 'You are report this comment  because')
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SignPetitionModal;
