import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Button, useTheme } from '@mui/material';
import './profile-details.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SingleActivityCard from 'app/components/common/cards/activity-card/activity-card';
import { deleteActivityById } from 'app/shared/reducers/activity-home';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PetitionCard from 'app/components/common/cards/petition-card/petition-card';
import { deletePetitionById } from 'app/shared/reducers/petition-home';
import BlogCard from 'app/components/common/cards/blog-card/blog-card';
import { TabPanelProps } from 'app/components/layout/event-filter-tabs/event-filter-tabs';
import { updateEventType } from 'app/shared/reducers/activity-filter';
import { fetchBookMarkedItems, fetchCount, fetchParticipatedDetails, fetchProfileDetails } from 'app/shared/reducers/profile';
import { fetchDraftedItems } from 'app/shared/reducers/activity/activity-draft';
import DraftTable from 'app/components/common/table/draft-table';
import RadioDialog from 'app/components/common/alert-dialog/radio-dialog';
import { fetchUserConnectedOrganizations } from 'app/shared/reducers/organization';
import SomethingWentWrong from 'app/components/icons/something-went-wrong';
import { useNavigate } from 'react-router-dom';
import SingleActivityReccuringCard from 'app/components/common/cards/activity-recurring-card/activity-recurring-card';

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && <Box sx={{ py: 3, px: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`,
  };
}

const ProfileActivityDetails = ({ id, profileData, hasParamId }: any) => {
  const [value, setValue] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [currentActivityId, setCurrentActivityId] = useState(null);
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);
  const [loggedInUserOrganizations, setLoggedInUserOrganizations] = useState([]);
  const [bookmarkSuccess, setBookmarkSuccess] = useState(false);
  const [likeSuccess, setLikemarkSuccess] = useState(false);
  const profileDetails = useAppSelector(state => state.profileReducer.profileDetails);
  const profileDetailsLoading = useAppSelector(state => state.profileReducer.profileDetailsLoading);
  const draftedItems = useAppSelector(state => state.draftReducer.draftedItems);
  const bookMarkCount = useAppSelector(state => state.profileReducer.profileBookmarkedCount);
  const bookmarkedItems = useAppSelector(state => state.profileReducer.profileBookmarkedItems);
  const draftLoading = useAppSelector(state => state.draftReducer.loading);
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const filterData = useAppSelector(state => state.filters.RequestBody.filter);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  console.log('check profiledetails', profileData);
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const bookMarkCountApi = useAppSelector(state => state.profileReducer.bookmarkCount);
  const draftCountApi = useAppSelector(state => state.profileReducer.draftCount);
  const participatedCountApi = useAppSelector(state => state.profileReducer.participationCount);
  const moderatorCountApi = useAppSelector(state => state.profileReducer.moderatorCount);
  const organizedCountApi = useAppSelector(state => state.profileReducer.organizedCount);
  const bookmarkCountByCategory = useAppSelector(state => state.profileReducer.bookmarkCountByCategory);
  const participationCountByCategory = useAppSelector(state => state.profileReducer.participationCountByCategory);
  const organizedCountByCategory = useAppSelector(state => state.profileReducer.organizedCountByCategory);
  const moderatedCountByCategory = useAppSelector(state => state.profileReducer.moderatedCountByCategory);

  console.log(
    bookMarkCountApi,
    draftCountApi,
    participatedCountApi,
    moderatorCountApi,
    organizedCountApi,
    bookmarkCountByCategory,
    participationCountByCategory,
    organizedCountByCategory,
    moderatedCountByCategory
  );

  const commonParams = {
    id: id,
    userId: profileData?.createdBy,
    categoryId: filterData?.categoryId,
    subCategoryId: filterData?.subCategoryId,
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (profileData?.ownProfile && id) {
      switch (newValue) {
        case 0:
          console.log('asd', commonParams);
          dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Bookmarked' }));
          break;
        case 1:
          dispatch(fetchDraftedItems({ ...commonParams, eventType: 'Draft' }));
          break;
        case 2:
          dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Participated' }));
          break;
        case 3:
          dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Organized' }));
          break;
        case 4:
          dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Moderated' }));
          break;
        default:
          dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Bookmarked' }));
          break;
      }
    } else {
      if (id) {
        switch (newValue) {
          case 0:
            dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Participated' }));
            break;
          case 1:
            dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Organized' }));
            break;
          case 2:
            dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Moderated' }));
            break;
          default:
            dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Participated' }));
            break;
        }
      }
    }

    setValue(newValue);
  };

  const handleBookmarkSuccess = () => {
    setBookmarkSuccess(s => !s);
  };

  useEffect(() => {
    console.log('check mine', commonParams);
    const filterType =
      value === 1 ? 'Draft' : value === 2 ? 'Participated' : value === 3 ? 'Organized' : value === 4 ? 'Moderated' : 'Bookmarked';

    if (isAuthenticated && id) dispatch(fetchProfileDetails({ ...commonParams, eventType: filterType }));
  }, [id, filterData]);

  useEffect(() => {
    if (id && profileData?.ownProfile) {
      dispatch(fetchProfileDetails({ id, eventType: 'Bookmarked' }));
      dispatch(fetchDraftedItems({ id, eventType: 'Draft' }));
    } else if (id && !profileData?.ownProfile) {
      dispatch(fetchProfileDetails({ id, eventType: 'Participated' }));
    }
  }, [id, profileData?.ownProfile]);

  useEffect(() => {
    if (id && filterData?.categoryId === null) {
      console.log('romal first');
      dispatch(fetchCount({ userId: id, categoryId: null }));
    } else if (id && filterData?.categoryId) {
      console.log('romal first1');
      dispatch(fetchCount({ userId: id, categoryId: filterData?.categoryId }));
    }
  }, [id, filterData?.categoryId]);

  useEffect(() => {
    if (localStorage.getItem('profileDetailsTab') === 'YES') {
      dispatch(fetchProfileDetails({ id, eventType: 'Bookmarked' }));
    }
  }, [localStorage.getItem('profileDetailsTab')]);

  const handleLikeSuccess = () => {
    setLikemarkSuccess(s => !s);
  };
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1030));
  const isSmallScreenNew = useMediaQuery(theme.breakpoints.down('sm'));
  const marginRightVW = isSmallScreen ? 4 : (16 / window.innerWidth) * 100;
  const customTheme = createTheme({
    ...theme,
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            minWidth: isSmallScreen ? 'auto' : '',
            fontSize: isSmallScreen ? '0.675rem' : '0.975rem',
            marginRight: isSmallScreen ? `${marginRightVW}vw` : '',
            padding: isSmallScreen ? '0px' : 'auto',
            '&.Mui-selected': {
              color: '#674BFF',
            },
          },
        },
      },
    },
  });

  const handleDeleteClick = async (id: string) => {
    try {
      const response = await dispatch(deleteActivityById({ id }));
      if (response) {
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeletePetition = async (petitionId: string) => {
    try {
      const response = await dispatch(deletePetitionById({ petitionId }));
      if (response) {
        window.location.reload();
        try {
          dispatch(updateEventType('Latest'));
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDraftDeleteSuccess = async () => {
    console.log('Draft deleted successfully. Triggering additional logic...');
  };
  const tabStyle = { textTransform: 'capitalize', color: theme.profile.color, fontWeight: 600, fontSize: '14px' };

  const [showOrgDialog, setShowOrgDialog] = useState(false);
  const handleOrgsDialog = value => {
    setShowOrgDialog(value);
  };

  useEffect(() => {
    const fetchUserOrganizations = async userId => {
      try {
        await dispatch(fetchUserConnectedOrganizations(userId))
          .then(res => {
            setLoggedInUserOrganizations(res.payload);
          })
          .catch(() => {});
      } catch (error) {
        console.log(error);
      }
    };
    if (hasParamId && myProfileId) {
      fetchUserOrganizations(myProfileId);
    }
  }, [hasParamId, myProfileId]);

  const getCurrentActivity = id => {
    setCurrentActivityId(id);
  };
  const clearCurrentActivity = () => {
    setCurrentActivityId(null);
  };

  const handleOptionChange = event => {
    const selectedValue = event.target.value;

    if (parseInt(selectedValue, 10) === 1) {
      setSelectedOrgId(1);
      setSelectedOption('My Self');
    } else {
      const selectedOrganization = loggedInUserOrganizations.find(organization => organization.id === parseInt(selectedValue, 10));

      if (selectedOrganization) {
        setSelectedOption(selectedOrganization.label);
        setSelectedOrgId(selectedOrganization.id);
      }
    }
  };

  const handleInstructionsModal = value => {
    setIsVolunteerModalOpen(value);
  };

  const handleCancel = () => {
    setShowOrgDialog(false);
    setSelectedOrgId(null);
    setSelectedOption('');
  };

  const changeOrgId = value => {
    setSelectedOrgId(value);
  };
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  };
  const [selectedVolunteerOptions, setSelectedVolunteerOptions] = useState<any>([]);
  const [selectedDeleteOptions, setSelectedDeletedOptions] = useState<any>([]);

  return (
    <Box>
      {profileData?.profileVisible && !profileData?.ownProfile && (
        <>
          <RadioDialog
            selectedOrgId={selectedOrgId}
            open={showOrgDialog}
            handleShow={handleOrgsDialog}
            handleOptionChange={handleOptionChange}
            handleCancel={handleCancel}
            handleInstructionsModal={handleInstructionsModal}
            loggedInUserOrganizations={loggedInUserOrganizations}
            hasParamId={hasParamId}
          />
          <ThemeProvider theme={customTheme}>
            <Tabs
              className="hi"
              value={value}
              onChange={handleChange}
              aria-label="profile tabs"
              sx={{ borderBottom: '1px solid grey', position: 'sticky', top: 0, zIndex: 1000 }}
            >
              {profileData?.ownProfile && (
                <Tab
                  disableRipple
                  label={`Bookmarked `}
                  icon={
                    bookMarkCountApi !== null || bookmarkCountByCategory !== null ? (
                      <span
                        style={{
                          fontSize: '10px',
                          paddingTop: '2px',
                          backgroundColor: bookMarkCountApi !== null || bookmarkCountByCategory !== null ? '#674BFF' : '',
                          width: '24px',
                          height: '16px',
                          borderRadius: '8px',
                          color: bookMarkCountApi !== null || bookmarkCountByCategory !== null ? '#fff' : '',
                        }}
                      >
                        {bookMarkCountApi !== null ? bookMarkCountApi : bookmarkCountByCategory}
                      </span>
                    ) : null
                  }
                  iconPosition="end"
                  {...a11yProps(0)}
                  sx={{ ...tabStyle, minHeight: '72px' }}
                />
              )}
              {profileData?.ownProfile && (
                <Tab
                  disableRipple
                  label="Draft"
                  {...a11yProps(1)}
                  sx={{ ...tabStyle, minHeight: '72px' }}
                  icon={
                    draftCountApi ? (
                      <span
                        style={{
                          fontSize: '10px',
                          paddingTop: '2px',
                          backgroundColor: draftCountApi ? '#674BFF' : '',
                          width: '24px',
                          height: '16px',
                          borderRadius: '8px',
                          color: draftCountApi ? '#fff' : '',
                        }}
                      >
                        {draftCountApi}
                      </span>
                    ) : null
                  }
                  iconPosition="end"
                />
              )}
              <Tab
                disableRipple
                label="Participated"
                {...a11yProps(2)}
                sx={{ ...tabStyle, minHeight: '72px' }}
                icon={
                  participatedCountApi !== null || participationCountByCategory !== null ? (
                    <span
                      style={{
                        fontSize: '10px',
                        paddingTop: '2px',
                        backgroundColor: participatedCountApi !== null || participationCountByCategory !== null ? '#674BFF' : '',
                        width: '24px',
                        height: '16px',
                        borderRadius: '8px',
                        color: participatedCountApi !== null || participationCountByCategory !== null ? '#fff' : '',
                      }}
                    >
                      {participatedCountApi !== null ? participatedCountApi : participationCountByCategory}
                    </span>
                  ) : null
                }
                iconPosition="end"
              />

              <Tab
                disableRipple
                label="Organized"
                {...a11yProps(3)}
                sx={{ ...tabStyle, minHeight: '72px' }}
                icon={
                  organizedCountApi !== null || organizedCountByCategory !== null ? (
                    <span
                      style={{
                        fontSize: '10px',
                        paddingTop: '2px',
                        backgroundColor: organizedCountApi !== null || organizedCountByCategory !== null ? '#674BFF' : '',
                        width: '24px',
                        height: '16px',
                        borderRadius: '8px',
                        color: organizedCountApi !== null || organizedCountByCategory !== null ? '#fff' : '',
                      }}
                    >
                      {organizedCountApi !== null ? participatedCountApi : organizedCountByCategory}
                    </span>
                  ) : null
                }
                iconPosition="end"
              />
              <Tab
                disableRipple
                label="Moderated"
                {...a11yProps(4)}
                sx={{ ...tabStyle, minHeight: '72px' }}
                icon={
                  moderatorCountApi !== null || moderatedCountByCategory !== null ? (
                    <span
                      style={{
                        fontSize: '10px',
                        paddingTop: '2px',
                        backgroundColor: moderatorCountApi !== null || moderatedCountByCategory !== null ? '#674BFF' : '',
                        width: '24px',
                        height: '16px',
                        borderRadius: '8px',
                        color: moderatorCountApi !== null || moderatedCountByCategory !== null ? '#fff' : '',
                      }}
                    >
                      {moderatorCountApi !== null ? moderatorCountApi : moderatedCountByCategory}
                    </span>
                  ) : null
                }
                iconPosition="end"
              />
            </Tabs>
          </ThemeProvider>
          <div
            className="example1"
            style={{
              width: isSmallScreenNew ? '100%' : '100%',
              overflowY: 'scroll',
              height: '80vh',
              overflow: 'auto',
            }}
          >
            <Box>
              {profileData?.ownProfile && value === 0 && (
                <CustomTabPanel value={value} index={0}>
                  <Stack
                    gap={4}
                    sx={{
                      marginBottom: '100px',
                    }}
                  >
                    {profileDetails?.size > 0 ? (
                      profileDetails?.homeDTOS?.map((activity: any) =>
                        activity?.eventType === 'Activity' ? (
                          <>
                            {activity?.recurring && (
                              <>
                                <SingleActivityReccuringCard
                                  changeOrgId={changeOrgId}
                                  handleCancel={handleCancel}
                                  isVolunteerModalOpen={isVolunteerModalOpen}
                                  handleInstructionsModal={handleInstructionsModal}
                                  currentActivityId={currentActivityId}
                                  getCurrentActivity={getCurrentActivity}
                                  clearCurrentActivity={clearCurrentActivity}
                                  selectedOption={selectedOption}
                                  selectedOrgId={selectedOrgId}
                                  handleOrgsDialog={handleOrgsDialog}
                                  onLikeSuccess={handleLikeSuccess}
                                  onBookmarkSuccess={handleBookmarkSuccess}
                                  fromProfile={true}
                                  commonParams={commonParams}
                                  activity={activity}
                                  key={activity.id}
                                  eventGallery={activity.eventGallery}
                                  handleDeleteClick={handleDeleteClick}
                                  selectedVolunteerOptions={selectedVolunteerOptions}
                                  setSelectedVolunteerOptions={setSelectedVolunteerOptions}
                                  selectedDeleteOptions={selectedDeleteOptions}
                                  setSelectedDeletedOptions={setSelectedDeletedOptions}
                                  handleDeleteRecurringClick={function (id: string): void {
                                    throw new Error('Function not implemented.');
                                  }}
                                />
                              </>
                            )}

                            {!activity?.recurring && (
                              <SingleActivityCard
                                changeOrgId={changeOrgId}
                                handleCancel={handleCancel}
                                isVolunteerModalOpen={isVolunteerModalOpen}
                                handleInstructionsModal={handleInstructionsModal}
                                currentActivityId={currentActivityId}
                                getCurrentActivity={getCurrentActivity}
                                clearCurrentActivity={clearCurrentActivity}
                                showOrgDialog={showOrgDialog}
                                selectedOption={selectedOption}
                                selectedOrgId={selectedOrgId}
                                handleOrgsDialog={handleOrgsDialog}
                                onLikeSuccess={handleLikeSuccess}
                                onBookmarkSuccess={handleBookmarkSuccess}
                                activity={activity}
                                fromProfile={true}
                                commonParams={commonParams}
                                key={activity.id}
                                eventGallery={activity.eventGallery}
                                handleDeleteClick={handleDeleteClick}
                              />
                            )}
                          </>
                        ) : activity?.eventType === 'Blog' ? (
                          <BlogCard blog={activity} key={activity.id} />
                        ) : (
                          <PetitionCard
                            key={activity.id}
                            value={value}
                            petition={activity}
                            handleDeletePetition={handleDeletePetition}
                            fromProfile={true}
                            commonParams={commonParams}
                          />
                        )
                      )
                    ) : (
                      <Box>
                        <p>No bookmarked items.</p>
                      </Box>
                    )}
                  </Stack>
                </CustomTabPanel>
              )}
              {profileData?.ownProfile && value === 1 && (
                <CustomTabPanel value={value} index={1}>
                  <Stack
                    gap={4}
                    sx={{
                      marginBottom: '100px',
                    }}
                  >
                    {draftedItems?.size > 0 ? (
                      <DraftTable
                        data={draftedItems?.homeDTOS}
                        profileId={parseFloat(id)}
                        onDraftDeleteSuccess={handleDraftDeleteSuccess}
                      />
                    ) : (
                      <Box>{draftLoading ? <p>Loading...</p> : <p>No drafted items</p>}</Box>
                    )}
                  </Stack>
                </CustomTabPanel>
              )}
              <CustomTabPanel value={value} index={!profileData?.ownProfile ? 0 : 2}>
                <Stack
                  gap={4}
                  sx={{
                    marginBottom: '100px',
                  }}
                >
                  {profileDetails?.size > 0 ? (
                    profileDetails?.homeDTOS?.map((activity: any) =>
                      activity?.eventType === 'Activity' ? (
                        <SingleActivityCard
                          changeOrgId={changeOrgId}
                          handleCancel={handleCancel}
                          isVolunteerModalOpen={isVolunteerModalOpen}
                          handleInstructionsModal={handleInstructionsModal}
                          currentActivityId={currentActivityId}
                          getCurrentActivity={getCurrentActivity}
                          clearCurrentActivity={clearCurrentActivity}
                          showOrgDialog={showOrgDialog}
                          selectedOption={selectedOption}
                          selectedOrgId={selectedOrgId}
                          handleOrgsDialog={handleOrgsDialog}
                          onLikeSuccess={handleLikeSuccess}
                          onBookmarkSuccess={handleBookmarkSuccess}
                          activity={activity}
                          fromProfile={true}
                          commonParams={commonParams}
                          key={activity.id}
                          eventGallery={activity.eventGallery}
                          handleDeleteClick={handleDeleteClick}
                        />
                      ) : activity?.eventType === 'Blog' ? (
                        <BlogCard blog={activity} key={activity.id} />
                      ) : (
                        <PetitionCard
                          value={value}
                          key={activity.id}
                          petition={activity}
                          handleDeletePetition={handleDeletePetition}
                          fromProfile={true}
                          commonParams={commonParams}
                        />
                      )
                    )
                  ) : (
                    <Box>{profileDetailsLoading ? <p>Loading...</p> : <p>No participated events</p>}</Box>
                  )}
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={!profileData?.ownProfile ? 1 : 3}>
                <Stack
                  gap={4}
                  sx={{
                    marginBottom: '100px',
                  }}
                >
                  {profileDetails?.size > 0 ? (
                    profileDetails?.homeDTOS?.map((activity: any) =>
                      activity?.eventType === 'Activity' ? (
                        <SingleActivityCard
                          changeOrgId={changeOrgId}
                          handleCancel={handleCancel}
                          isVolunteerModalOpen={isVolunteerModalOpen}
                          handleInstructionsModal={handleInstructionsModal}
                          currentActivityId={currentActivityId}
                          getCurrentActivity={getCurrentActivity}
                          clearCurrentActivity={clearCurrentActivity}
                          showOrgDialog={showOrgDialog}
                          selectedOption={selectedOption}
                          selectedOrgId={selectedOrgId}
                          handleOrgsDialog={handleOrgsDialog}
                          onLikeSuccess={handleLikeSuccess}
                          onBookmarkSuccess={handleBookmarkSuccess}
                          activity={activity}
                          fromProfile={true}
                          commonParams={commonParams}
                          key={activity.id}
                          eventGallery={activity.eventGallery}
                          handleDeleteClick={handleDeleteClick}
                        />
                      ) : activity?.eventType === 'Blog' ? (
                        <BlogCard blog={activity} key={activity.id} />
                      ) : (
                        <PetitionCard
                          value={value}
                          key={activity.id}
                          petition={activity}
                          handleDeletePetition={handleDeletePetition}
                          fromProfile={true}
                          commonParams={commonParams}
                        />
                      )
                    )
                  ) : (
                    <Box>{profileDetailsLoading ? <p>Loading...</p> : <p>No organized events</p>}</Box>
                  )}
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={!profileData?.ownProfile ? 2 : 4}>
                <Stack
                  gap={4}
                  sx={{
                    marginBottom: '100px',
                  }}
                >
                  {profileDetails?.size > 0 ? (
                    profileDetails?.homeDTOS?.map((activity: any) =>
                      activity?.eventType === 'Activity' ? (
                        <SingleActivityCard
                          changeOrgId={changeOrgId}
                          handleCancel={handleCancel}
                          isVolunteerModalOpen={isVolunteerModalOpen}
                          handleInstructionsModal={handleInstructionsModal}
                          currentActivityId={currentActivityId}
                          getCurrentActivity={getCurrentActivity}
                          clearCurrentActivity={clearCurrentActivity}
                          showOrgDialog={showOrgDialog}
                          selectedOption={selectedOption}
                          selectedOrgId={selectedOrgId}
                          handleOrgsDialog={handleOrgsDialog}
                          onLikeSuccess={handleLikeSuccess}
                          onBookmarkSuccess={handleBookmarkSuccess}
                          activity={activity}
                          key={activity.id}
                          fromProfile={true}
                          commonParams={commonParams}
                          eventGallery={activity.eventGallery}
                          handleDeleteClick={handleDeleteClick}
                        />
                      ) : activity?.eventType === 'Blog' ? (
                        <BlogCard blog={activity} key={activity.id} />
                      ) : (
                        <PetitionCard
                          value={value}
                          key={activity.id}
                          petition={activity}
                          handleDeletePetition={handleDeletePetition}
                          fromProfile={true}
                          commonParams={commonParams}
                        />
                      )
                    )
                  ) : (
                    <Box>{profileDetailsLoading ? <p>Loading...</p> : <p>No moderated events</p>}</Box>
                  )}
                </Stack>
              </CustomTabPanel>
            </Box>
          </div>
        </>
      )}
      {profileData?.ownProfile && (
        <>
          <RadioDialog
            selectedOrgId={selectedOrgId}
            open={showOrgDialog}
            handleShow={handleOrgsDialog}
            handleOptionChange={handleOptionChange}
            handleCancel={handleCancel}
            handleInstructionsModal={handleInstructionsModal}
            loggedInUserOrganizations={loggedInUserOrganizations}
            hasParamId={hasParamId}
          />
          <ThemeProvider theme={customTheme}>
            <Tabs
              className="hi"
              value={value}
              onChange={handleChange}
              aria-label="profile tabs"
              sx={{ borderBottom: '1px solid grey', position: 'sticky', top: 0, zIndex: 1000 }}
            >
              {profileData?.ownProfile && (
                <Tab
                  disableRipple
                  label={`Bookmarked `}
                  icon={
                    bookMarkCountApi !== null || bookmarkCountByCategory !== null ? (
                      <span
                        style={{
                          fontSize: '10px',
                          paddingTop: '2px',
                          backgroundColor: bookMarkCountApi !== null || bookmarkCountByCategory !== null ? '#674BFF' : '',
                          width: '24px',
                          height: '16px',
                          borderRadius: '8px',
                          color: bookMarkCountApi !== null || bookmarkCountByCategory !== null ? '#fff' : '',
                        }}
                      >
                        {bookMarkCountApi !== null ? bookMarkCountApi : bookmarkCountByCategory}
                      </span>
                    ) : null
                  }
                  iconPosition="end"
                  {...a11yProps(0)}
                  sx={{ ...tabStyle, minHeight: '72px' }}
                />
              )}
              {profileData?.ownProfile && (
                <Tab
                  disableRipple
                  label="Draft"
                  {...a11yProps(1)}
                  sx={{ ...tabStyle, minHeight: '72px' }}
                  icon={
                    draftCountApi ? (
                      <span
                        style={{
                          fontSize: '10px',
                          paddingTop: '2px',
                          backgroundColor: draftCountApi ? '#674BFF' : '',
                          width: '24px',
                          height: '16px',
                          borderRadius: '8px',
                          color: draftCountApi ? '#fff' : '',
                        }}
                      >
                        {draftCountApi}
                      </span>
                    ) : null
                  }
                  iconPosition="end"
                />
              )}
              <Tab
                disableRipple
                label="Participated"
                {...a11yProps(2)}
                sx={{ ...tabStyle, minHeight: '72px' }}
                icon={
                  participatedCountApi !== null || participationCountByCategory !== null ? (
                    <span
                      style={{
                        fontSize: '10px',
                        paddingTop: '2px',
                        backgroundColor: participatedCountApi !== null || participationCountByCategory !== null ? '#674BFF' : '',
                        width: '24px',
                        height: '16px',
                        borderRadius: '8px',
                        color: participatedCountApi !== null || participationCountByCategory !== null ? '#fff' : '',
                      }}
                    >
                      {participatedCountApi !== null ? participatedCountApi : participationCountByCategory}
                    </span>
                  ) : null
                }
                iconPosition="end"
              />
              <Tab
                disableRipple
                label="Organized"
                {...a11yProps(3)}
                sx={{ ...tabStyle, minHeight: '72px' }}
                icon={
                  organizedCountApi !== null || organizedCountByCategory !== null ? (
                    <span
                      style={{
                        fontSize: '10px',
                        paddingTop: '2px',
                        backgroundColor: organizedCountApi !== null || organizedCountByCategory !== null ? '#674BFF' : '',
                        width: '24px',
                        height: '16px',
                        borderRadius: '8px',
                        color: organizedCountApi !== null || organizedCountByCategory !== null ? '#fff' : '',
                      }}
                    >
                      {organizedCountApi !== null ? organizedCountApi : organizedCountByCategory}
                    </span>
                  ) : null
                }
                iconPosition="end"
              />
              <Tab
                disableRipple
                label="Moderated"
                {...a11yProps(4)}
                sx={{ ...tabStyle, minHeight: '72px' }}
                icon={
                  moderatorCountApi !== null || moderatedCountByCategory !== null ? (
                    <span
                      style={{
                        fontSize: '10px',
                        paddingTop: '2px',
                        backgroundColor: moderatorCountApi !== null || moderatedCountByCategory !== null ? '#674BFF' : '',
                        width: '24px',
                        height: '16px',
                        borderRadius: '8px',
                        color: moderatorCountApi !== null || moderatedCountByCategory !== null ? '#fff' : '',
                      }}
                    >
                      {moderatorCountApi !== null ? moderatorCountApi : moderatedCountByCategory}
                    </span>
                  ) : null
                }
                iconPosition="end"
              />
            </Tabs>
          </ThemeProvider>
          <div
            className="example1"
            style={{
              width: isSmallScreenNew ? '100%' : '100%',
              overflowY: 'scroll',
              height: '80vh',
              overflow: 'auto',
            }}
          >
            <Box>
              {profileData?.ownProfile && value === 0 && (
                <CustomTabPanel value={value} index={0}>
                  <Stack
                    gap={4}
                    sx={{
                      marginBottom: '100px',
                    }}
                  >
                    {profileDetails?.size > 0 ? (
                      profileDetails?.homeDTOS?.map((activity: any) =>
                        activity?.eventType === 'Activity' ? (
                          // <SingleActivityCard
                          //   changeOrgId={changeOrgId}
                          //   handleCancel={handleCancel}
                          //   isVolunteerModalOpen={isVolunteerModalOpen}
                          //   handleInstructionsModal={handleInstructionsModal}
                          //   currentActivityId={currentActivityId}
                          //   getCurrentActivity={getCurrentActivity}
                          //   clearCurrentActivity={clearCurrentActivity}
                          //   showOrgDialog={showOrgDialog}
                          //   selectedOption={selectedOption}
                          //   selectedOrgId={selectedOrgId}
                          //   handleOrgsDialog={handleOrgsDialog}
                          //   onLikeSuccess={handleLikeSuccess}
                          //   onBookmarkSuccess={handleBookmarkSuccess}
                          //   activity={activity}
                          //   key={activity.id}
                          //   eventGallery={activity.eventGallery}
                          //   handleDeleteClick={handleDeleteClick}
                          // />
                          <>
                            {activity?.recurring && (
                              <>
                                <SingleActivityReccuringCard
                                  changeOrgId={changeOrgId}
                                  handleCancel={handleCancel}
                                  isVolunteerModalOpen={isVolunteerModalOpen}
                                  handleInstructionsModal={handleInstructionsModal}
                                  currentActivityId={currentActivityId}
                                  getCurrentActivity={getCurrentActivity}
                                  clearCurrentActivity={clearCurrentActivity}
                                  selectedOption={selectedOption}
                                  selectedOrgId={selectedOrgId}
                                  handleOrgsDialog={handleOrgsDialog}
                                  onLikeSuccess={handleLikeSuccess}
                                  onBookmarkSuccess={handleBookmarkSuccess}
                                  activity={activity}
                                  key={activity.id}
                                  eventGallery={activity.eventGallery}
                                  fromProfile={true}
                                  commonParams={commonParams}
                                  handleDeleteClick={handleDeleteClick}
                                  selectedVolunteerOptions={selectedVolunteerOptions}
                                  setSelectedVolunteerOptions={setSelectedVolunteerOptions}
                                  selectedDeleteOptions={selectedDeleteOptions}
                                  setSelectedDeletedOptions={setSelectedDeletedOptions}
                                  handleDeleteRecurringClick={function (id: string): void {
                                    throw new Error('Function not implemented.');
                                  }}
                                />
                              </>
                            )}

                            {!activity?.recurring && (
                              <SingleActivityCard
                                changeOrgId={changeOrgId}
                                handleCancel={handleCancel}
                                isVolunteerModalOpen={isVolunteerModalOpen}
                                handleInstructionsModal={handleInstructionsModal}
                                currentActivityId={currentActivityId}
                                getCurrentActivity={getCurrentActivity}
                                clearCurrentActivity={clearCurrentActivity}
                                showOrgDialog={showOrgDialog}
                                selectedOption={selectedOption}
                                selectedOrgId={selectedOrgId}
                                handleOrgsDialog={handleOrgsDialog}
                                onLikeSuccess={handleLikeSuccess}
                                onBookmarkSuccess={handleBookmarkSuccess}
                                activity={activity}
                                fromProfile={true}
                                commonParams={commonParams}
                                key={activity.id}
                                eventGallery={activity.eventGallery}
                                handleDeleteClick={handleDeleteClick}
                              />
                            )}
                          </>
                        ) : activity?.eventType === 'Blog' ? (
                          <BlogCard blog={activity} key={activity.id} />
                        ) : (
                          <PetitionCard
                            value={value}
                            key={activity.id}
                            petition={activity}
                            handleDeletePetition={handleDeletePetition}
                            fromProfile={true}
                            commonParams={commonParams}
                          />
                        )
                      )
                    ) : (
                      <Box>
                        <p>No bookmarked items.</p>
                      </Box>
                    )}
                  </Stack>
                </CustomTabPanel>
              )}
              {profileData?.ownProfile && value === 1 && (
                <CustomTabPanel value={value} index={1}>
                  <Stack
                    gap={4}
                    sx={{
                      marginBottom: '100px',
                    }}
                  >
                    {draftedItems?.size > 0 ? (
                      <DraftTable
                        data={draftedItems?.homeDTOS}
                        profileId={parseFloat(id)}
                        onDraftDeleteSuccess={handleDraftDeleteSuccess}
                      />
                    ) : (
                      <Box>{draftLoading ? <p>Loading...</p> : <p>No drafted items</p>}</Box>
                    )}
                  </Stack>
                </CustomTabPanel>
              )}
              <CustomTabPanel value={value} index={!profileData?.ownProfile ? 0 : 2}>
                <Stack
                  gap={4}
                  sx={{
                    marginBottom: '100px',
                  }}
                >
                  {profileDetails?.size > 0 ? (
                    profileDetails?.homeDTOS?.map((activity: any) =>
                      activity?.eventType === 'Activity' ? (
                        <SingleActivityCard
                          changeOrgId={changeOrgId}
                          handleCancel={handleCancel}
                          isVolunteerModalOpen={isVolunteerModalOpen}
                          handleInstructionsModal={handleInstructionsModal}
                          currentActivityId={currentActivityId}
                          getCurrentActivity={getCurrentActivity}
                          clearCurrentActivity={clearCurrentActivity}
                          showOrgDialog={showOrgDialog}
                          selectedOption={selectedOption}
                          selectedOrgId={selectedOrgId}
                          handleOrgsDialog={handleOrgsDialog}
                          onLikeSuccess={handleLikeSuccess}
                          onBookmarkSuccess={handleBookmarkSuccess}
                          activity={activity}
                          fromProfile={true}
                          commonParams={commonParams}
                          key={activity.id}
                          eventGallery={activity.eventGallery}
                          handleDeleteClick={handleDeleteClick}
                        />
                      ) : activity?.eventType === 'Blog' ? (
                        <BlogCard blog={activity} key={activity.id} />
                      ) : (
                        <PetitionCard
                          value={value}
                          key={activity.id}
                          petition={activity}
                          handleDeletePetition={handleDeletePetition}
                          fromProfile={true}
                          commonParams={commonParams}
                        />
                      )
                    )
                  ) : (
                    <Box>{profileDetailsLoading ? <p>Loading...</p> : <p>No participated events</p>}</Box>
                  )}
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={!profileData?.ownProfile ? 1 : 3}>
                <Stack
                  gap={4}
                  sx={{
                    marginBottom: '100px',
                  }}
                >
                  {profileDetails?.size > 0 ? (
                    profileDetails?.homeDTOS?.map((activity: any) =>
                      activity?.eventType === 'Activity' ? (
                        <SingleActivityCard
                          changeOrgId={changeOrgId}
                          handleCancel={handleCancel}
                          isVolunteerModalOpen={isVolunteerModalOpen}
                          handleInstructionsModal={handleInstructionsModal}
                          currentActivityId={currentActivityId}
                          getCurrentActivity={getCurrentActivity}
                          clearCurrentActivity={clearCurrentActivity}
                          showOrgDialog={showOrgDialog}
                          selectedOption={selectedOption}
                          selectedOrgId={selectedOrgId}
                          handleOrgsDialog={handleOrgsDialog}
                          onLikeSuccess={handleLikeSuccess}
                          onBookmarkSuccess={handleBookmarkSuccess}
                          activity={activity}
                          fromProfile={true}
                          commonParams={commonParams}
                          key={activity.id}
                          eventGallery={activity.eventGallery}
                          handleDeleteClick={handleDeleteClick}
                        />
                      ) : activity?.eventType === 'Blog' ? (
                        <BlogCard blog={activity} key={activity.id} />
                      ) : (
                        <PetitionCard
                          value={value}
                          key={activity.id}
                          petition={activity}
                          handleDeletePetition={handleDeletePetition}
                          fromProfile={true}
                          commonParams={commonParams}
                        />
                      )
                    )
                  ) : (
                    <Box>{profileDetailsLoading ? <p>Loading...</p> : <p>No organized events</p>}</Box>
                  )}
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={!profileData?.ownProfile ? 2 : 4}>
                <Stack
                  gap={4}
                  sx={{
                    marginBottom: '100px',
                  }}
                >
                  {profileDetails?.size > 0 ? (
                    profileDetails?.homeDTOS?.map((activity: any) =>
                      activity?.eventType === 'Activity' ? (
                        <SingleActivityCard
                          changeOrgId={changeOrgId}
                          handleCancel={handleCancel}
                          isVolunteerModalOpen={isVolunteerModalOpen}
                          handleInstructionsModal={handleInstructionsModal}
                          currentActivityId={currentActivityId}
                          getCurrentActivity={getCurrentActivity}
                          clearCurrentActivity={clearCurrentActivity}
                          showOrgDialog={showOrgDialog}
                          selectedOption={selectedOption}
                          selectedOrgId={selectedOrgId}
                          handleOrgsDialog={handleOrgsDialog}
                          onLikeSuccess={handleLikeSuccess}
                          onBookmarkSuccess={handleBookmarkSuccess}
                          activity={activity}
                          fromProfile={true}
                          commonParams={commonParams}
                          key={activity.id}
                          eventGallery={activity.eventGallery}
                          handleDeleteClick={handleDeleteClick}
                        />
                      ) : activity?.eventType === 'Blog' ? (
                        <BlogCard blog={activity} key={activity.id} />
                      ) : (
                        <PetitionCard
                          value={value}
                          key={activity.id}
                          petition={activity}
                          handleDeletePetition={handleDeletePetition}
                          fromProfile={true}
                          commonParams={commonParams}
                        />
                      )
                    )
                  ) : (
                    <Box>{profileDetailsLoading ? <p>Loading...</p> : <p>No moderated events</p>}</Box>
                  )}
                </Stack>
              </CustomTabPanel>
            </Box>
          </div>
        </>
      )}
      {!profileData?.profileVisible && !profileData?.ownProfile && (
        <div className="col-12 d-flex justify-content-start flex-column align-items-center mt-3">
          {/* <SomethingWentWrong sx={{ height: '200px', width: '155px' }} /> */}
          <hr></hr>
          <div className="mt-3">
            <p style={{ color: '#787b7f', font: 'normal normal 600 24px/37px Manrope' }}>Oops! This profile is locked .</p>
          </div>
          <div className="mt-1">
            <p style={{ color: '#7F8286', font: ' normal normal normal 14px/20px Manrope' }}>You don't have access.</p>
          </div>
          <div>
            {' '}
            <Button
              variant="contained"
              className="me-1"
              sx={{
                fontSize: '14px',
                borderRadius: '20px',
                height: '28px',
                lineHeight: '12px',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'common.black',
                },
                width: 'fit-content',
              }}
              onClick={handleClick}
            >
              Go back home
            </Button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default ProfileActivityDetails;
