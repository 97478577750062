import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import CreateActivityPostingRules from './create-activity-posting-rules/create-activity-posting-rules';
import CreateActivityInstructionResources from './create-activity-instruction-resources/create-activity-instruction-resources';
import CreateActivityMainSec from './create-activity-main-sec/create-activity-main-sec';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Typography from '@mui/material/Typography';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';

import PostSuccessModal from 'app/components/common/post-success-modal/post-success-modal';
import { createActivitySchema } from 'app/utils/validation-schema/activity-schema';
import { extractIdFromUrlName, findDifferentFields, getAddressDetails, getNearbyPlacesGmaps } from 'app/utils/common-utils/common-utils';
import GenericModal from 'app/components/common/modals/generic-modal';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { sendActivityDraft } from 'app/shared/reducers/activity/activity-draft';
import toast from 'react-hot-toast';
import PaymentInfoModal from 'app/components/common/cards/chat-card/modals/info-window-payment';
import AlertDialog from 'app/components/common/alert-dialog/alert-dialog';
import { createCheckoutSession } from 'app/shared/reducers/payment';
import { clearExistingActivities } from 'app/shared/reducers/activity-filter';

const initialValues = {
  categoryID: '',
  header: '',
  subCategoryID: null,
  activityTypeId: '',
  goalId: '',
  quantity: '',
  currency: 'USD',
  details: '',
  multimedia: [],
  bloodTypeId: '',
  units: '',
  ageLimit: '',
  organizationId: '',
  includeMicroFunding: false,
  amountToRaise: '',
  place: '',
  online: false,
  activityStartTime: '',
  activityEndTime: '',
  startDate: '',
  postAsOrganisation: false,
  instructions: [],
  resources: [],
  urgent: false,
  makeThisPrivateActivity: false,
  latitude: '',
  longitude: '',
  onlineUrl: '',
  activityFrequencyId: '',
  endDate: null,
  customFrequency: [
    {
      days: 1,
      weeks: 0,
      weekDays: [],
      months: 0,
    },
  ],
  lostPet: {
    petCategoryID: '',
    animal: '',
    nameOfThePet: '',
    breed: '',
    color: '',
    dateLostOn: '',
    reward: 0,
    currencyCode: '',
    keyIdentifier: '',
    countryCode: '',
    phoneNumber: '',
    subCategoryID: '',
  },
};
const CreateActivity = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [createActivityData, setCreateActivityData] = useState<{}>({});
  const [routeBack, setRouteBack] = useState(false);
  const [confirmGoBack, setConfirmGoBack] = useState(false);
  const [responseUrl, setResponseUrl] = useState<string>('');
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const dispatch = useAppDispatch();
  const [confirmPayment, setConfirmPayment] = useState<boolean>(false);
  const [createBody, setCreateBody] = useState(null);
  const { checkoutSession, checkoutSessionSuccess } = useAppSelector(state => state.payment);
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const [passId, setPassId] = useState<any>();
  const [payload, setPayload] = useState<any>();
  const [isError, setError] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status');
  const transaction_id = searchParams.get('transaction_id');

  useEffect(() => {
    const status = searchParams.get('status');
    if (status === 'success') {
      setResponseUrl(localStorage.getItem('responseUrl'));
      setPdfUrl(null);
      setTimeout(() => {
        setOpen(true);
      }, 900);
    }
  }, [searchParams]);

  useEffect(() => {
    if (checkoutSessionSuccess && checkoutSession?.checkoutUrl) {
      window.location.href = checkoutSession.checkoutUrl;
    }
  }, [checkoutSessionSuccess, checkoutSession]);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const handlePayment = () => {
    handleCreateActivitySubmit(createBody, 'PRIVATE')
      .then(res => {
        setPaymentLoading(true);
        console.log('checkmine', res);
        localStorage.setItem('responseUrl', res.link);
        localStorage.setItem('pdfUrl', res.pdfUrl);
        // setResponseUrl(res.link);
        // setPdfUrl(res?.pdfUrl);
        const activityId = res?.link.substring(res?.link.lastIndexOf('/') + 1);
        setPassId(activityId);
        const userId = myProfileId;
        handleGetCatalogue(2);
      })
      .catch(err => {
        setPaymentLoading(false);
        console.log(err);
      });
  };
  const handleGetCatalogue = async id => {
    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        setPaymentLoading(true);
        setPayload(response.data);
        // if (response.data) {
        //   handlePaymentSite();
        // }
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (payload?.productId) handlePaymentSite();
  }, [payload?.productId]);

  const handlePaymentSite = async () => {
    setPaymentLoading(true);
    let passData = {
      eventId: +extractIdFromUrlName(passId),
      eventType: 2,
      quantity: 1,
      productId: payload?.productId,
      plannedFeature: false,
    };

    localStorage.setItem('passEventId', extractIdFromUrlName(passId));
    localStorage.setItem('passEventType', JSON.stringify(passData?.eventType));

    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          setPaymentLoading(false);
          // setOpen(true);
          window.location.href = checkoutUrl; // Open checkout URL in current tab
        } else {
          setPaymentLoading(false);
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleCreateActivitySubmit = async (activity, type) => {
    setIsButtonDisabled(true);
    let payload = { ...activity };

    if (activity?.latitude && activity?.longitude) {
      const response = await getNearbyPlacesGmaps(activity?.latitude, activity?.longitude);
      if (response) {
        const addressDetails = await getAddressDetails(response);
        const { state, city, country } = addressDetails;
        if (addressDetails) {
          payload = { ...activity, state, city, country };
        }
        if (!activity?.online && !activity?.place?.length) {
          const place = await response?.formatted_address;
          if (place) {
            payload = { ...payload, place };
          }
        }
      }
    }

    try {
      const response = await axios.post<any>('api/activity/submit', payload);
      if (response.status === 201 || response.status === 200) {
        setResponseUrl(response.data.link);

        dispatch(clearExistingActivities());

        if (type !== 'PRIVATE') {
          if (response.data?.pdfUrl) {
            setPdfUrl(response.data?.pdfUrl);
          }
          setOpen(true);
        }
        return response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createActivitySchema,
    onSubmit: async (values, { resetForm }) => {
      const { lostPet, ...restValues } = values;
      const updatedValues = {
        ...restValues,
        lostPet: {
          ...lostPet,
          subCategoryID: undefined,
        },
      };
      if (values?.makeThisPrivateActivity) {
        setConfirmPayment(true);
        setCreateBody(updatedValues);
      } else {
        handleCreateActivitySubmit(updatedValues, 'NORMAL');
      }
      // handleCreateActivitySubmit(updatedValues);
      // resetForm(); // You may uncomment this line if you want to reset the form after submission.
    },
  });

  useEffect(() => {
    if (formik?.values && initialValues) {
      const userChanges = findDifferentFields(initialValues, formik.values);
      setFieldsChanged(userChanges);
    }
  }, [formik?.values, initialValues]);

  useEffect(() => {
    const unloadHandler = (e: any) => {
      if (fieldsChanged) {
        e.preventDefault();
        // setRouteBack(true);
        e.returnValue = 'Are you sure you want to leave this page? Your changes may not be saved.';
      }
    };

    window.addEventListener('beforeunload', unloadHandler);

    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [fieldsChanged]);

  const handleModalClose = () => {
    setOpen(false);
    navigate('/');
  };
  const modalActionButtons = [
    {
      label: 'Save Draft',
      onClick: async () => {
        setConfirmGoBack(true);
        const response = await dispatch(sendActivityDraft(formik.values));
        if (sendActivityDraft.fulfilled.match(response)) {
          toast.success('Draft saved successfully');
          navigate(-1);
        } else if (sendActivityDraft.rejected.match(response)) {
          toast.error('Failed to save draft');
        }
      },
    },
    { label: 'Discard', onClick: () => navigate(-1) },
  ];

  const handleNavigationArrowButton = () => {
    if (fieldsChanged) {
      setRouteBack(true);
    } else {
      navigate('/');
    }
  };
  const handleFormSubmit = event => {
    event.preventDefault();
  };
  return (
    <div className="py-2 mx-4" style={{ height: '100vh', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'none' }}>
      <style>
        {`
         ::-webkit-scrollbar {
        width: 0;
        background: transparent; 
      }     
      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    `}
      </style>
      <div className="row   py-3">
        <div className="col-3">
          <ArrowCircleLeftIcon
            onClick={handleNavigationArrowButton}
            sx={{ cursor: 'pointer', color: '#FFCE00', borderRadius: '50%', fontSize: '29px' }}
          />
        </div>
        <div className="col-9">
          <Typography variant="h4" sx={{ color: 'secondary.main' }}>
            Create an activity
          </Typography>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-3 d-none d-sm-block ">
            <CreateActivityPostingRules formik={formik} />
          </div>
          <div className="col-sm-12 col-md-9 ">
            <div className="row d-flex">
              <div className="col-sm-12 col-md-8 ">
                <CreateActivityMainSec setError={setError} formik={formik} setCreateActivityData={setCreateActivityData} />
              </div>
              <div className="col-sm-12 col-md-4">
                <CreateActivityInstructionResources
                  isError={isError}
                  formik={formik}
                  setCreateActivityData={setCreateActivityData}
                  isButtonDisabled={isButtonDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </form>

      <PostSuccessModal
        handleModalClose={handleModalClose}
        fullScreen={fullScreen}
        open={open}
        onClose={handleModalClose}
        responseUrl={responseUrl}
        pdfUrl={pdfUrl}
        message="Your activity has been posted succesfully"
      />
      <GenericModal
        handleClose={() => setRouteBack(false)}
        fullScreen={fullScreen}
        open={routeBack}
        title="Do you want to keep your changes?"
        buttons={modalActionButtons}
      />

      <AlertDialog
        paymentLoading={paymentLoading}
        agreeAction={handlePayment}
        title=""
        description="Creating a 'By Invitation Only' activity is a paid service. Do you wish to continue?"
        open={confirmPayment}
        setAlertOpen={setConfirmPayment}
        closeButtonText="Cancel"
      />
    </div>
  );
};

export default CreateActivity;
