import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Button, Checkbox, Dialog, FormControlLabel, OutlinedInput, Stack, useMediaQuery, useTheme } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { fetchCategories } from 'app/shared/reducers/categories';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import CreatePetitionImageUpload from './create-petition-image-upload';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import MapBoxMapView from 'app/components/common/map-box/map-view-mapbox';
import { StyledTextarea } from 'app/components/common/text-area/styled-textare';
import { sideBaricons } from 'app/utils/data/side-bar-nav/side-nav-icon-list';
import { fetchUserGrantedOrganizations } from 'app/shared/reducers/organization';

const CreatePetitionMainSec = ({ setCreatePetitionData, formik, isButtonDisabled }: any) => {
  const theme = useTheme();
  const isDarkMode = useAppSelector(state => state.darkMode);
  const navigate = useNavigate();
  const [categories, setCategories] = useState<any>([]);

  const handleSelectCategory = (event: SelectChangeEvent) => {
    formik.setFieldValue('categoryID', event.target.value);
  };

  const [selectedValue, setSelectedValue] = useState('');
  const postAsData = ['Myself', 'Org1', 'Org2'];
  const handleCancelButtonClick = () => {
    navigate('/');
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateError, setIsdateError] = useState(false);
  const [mapTouched, setMapTouched] = useState<boolean>(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number }>(null);
  const [locationFomSearch, setLocationFromSearch] = useState<string>('');
  const [postAsSelectedValue, setPostAsSelectedValue] = useState('');
  const userGrantedOrganizations = useAppSelector(state => state.organizationReducer.userGrantedOrganizations);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (formik.values.latitude === '' || formik.values.longitude === '') {
      formik.setFieldValue('latitude', 12.97912);
      formik.setFieldValue('longitude', 77.5913);
    }
  };
  const handleSelectLocation = location => {
    const { lat, lng } = location;
    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);
    setSelectedLocation(location);
  };
  const handleLocationFromSearchBox = (location: string) => {
    setLocationFromSearch(location);
    formik.setFieldValue('place', location);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setMapTouched(true);
  };
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCategories());
        setCategories(response.payload);
        formik.setFieldValue('categoryID', '');
        formik.setFieldValue('targetSignature', '');
        validateStartDate(dayjs(new Date()));
        validateEndDate(dayjs(new Date()));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  console.log('formik errors', formik.errors);

  useEffect(() => {
    const getUserGrantedOrganizations = async () => {
      try {
        await dispatch(fetchUserGrantedOrganizations());
      } catch (error) {
        console.log(error);
      }
    };
    getUserGrantedOrganizations();
  }, [dispatch]);

  useEffect(() => {
    console.log('mine petiton', formik.values?.petitionTo);
    if (formik.values?.petitionTo?.length === 1 && formik.values?.petitionTo[0]?.trim() === '') {
      formik.setFieldValue('petitionTo', []);
    }
  }, [formik.values?.petitionTo]);
  const validateStartDate = (date: dayjs.Dayjs) => {
    const dates = dayjs(date);
    const startOfDay = dates.startOf('day');
    formik.setFieldValue('startDate', startOfDay);
    if (date.isBefore(dayjs(), 'day')) {
      console.error('Invalid startDate: Start date cannot be before the current date.');
      formik.setFieldValue('startDate', '');
    }
  };

  const validateEndDate = (date: dayjs.Dayjs) => {
    const endDateWithTime = date.hour(22).minute(58);
    const dates = dayjs(date);
    const endOfDay = dates.endOf('day');
    formik.setFieldValue('endDate', endOfDay);
    if (formik.values.startDate && endDateWithTime.isBefore(formik.values.startDate, 'minute')) {
      console.error('Invalid endDate: End date cannot be before the start date.');
      formik.setFieldValue('endDate', '');
    }
  };

  const input = document.querySelector('input[name="targetSignature"]');

  if (input) {
    input.addEventListener('wheel', function (event) {
      event.preventDefault();
    });
  }

  const handleFocus = () => {
    formik.setFieldError('details', '');
  };

  const handleBlur = () => {
    const details = formik.values.details;
    if (!details || details === '<p><br></p>') {
      formik.setFieldError('details', 'Petition details is required');
    }
  };

  return (
    <Stack direction="column" gap={3}>
      <Grid container direction="row" gap={{ xs: 2, md: 2 }} alignItems={'center'} justifyContent={'space-between'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl fullWidth sx={{ backgroundColor: 'secondary.dark', borderRadius: '15px' }}>
            <Select
              size="small"
              name="categoryID"
              fullWidth
              displayEmpty
              placeholder="Select Category *"
              labelId="select-categories"
              id="select-categories"
              value={formik.values.categoryID}
              onChange={handleSelectCategory}
              error={formik.touched.categoryID && Boolean(formik.errors.categoryID)}
              MenuProps={{
                PaperProps: {
                  style: {
                    overflowY: 'scroll',
                  },
                },
                MenuListProps: {
                  style: {
                    maxHeight: '200px',
                  },
                },
              }}
              sx={{
                border: 'none',
                '&.MuiMenu-paper': {
                  borderRadius: '15px',
                  border: 'none',
                },
                '&.MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  height: 45,
                },
              }}
            >
              <MenuItem disabled value="">
                <em>
                  <Typography sx={{ fontStyle: 'normal', color: theme.textColor.paragraph }}>Select Category *</Typography>
                </em>
              </MenuItem>
              {categories?.map((category: any) => {
                const matchingIcon = sideBaricons.find(iconData => iconData.id === category.id);
                return (
                  <MenuItem value={category.id} key={category.id}>
                    <Stack direction={'row'} pl={1} gap={1}>
                      {matchingIcon?.icon ?? null}
                      <Typography sx={{ fontSize: '16px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {category.category}
                      </Typography>
                    </Stack>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {formik.touched.categoryID && formik.errors.categoryID && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.categoryID && formik.errors.categoryID}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <FormControl
            fullWidth
            sx={{ backgroundColor: 'secondary.dark', color: 'secondary.main', borderRadius: '15px' }}
            variant="outlined"
          >
            <OutlinedInput
              name="title"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              // error={formik.touched.title && Boolean(formik.errors.title)}
              sx={{
                height: '45px',
                color: 'secondary.main',
                border: '1px solid #BFBFBF',
                '&.MuiOutlinedInput-input::placeholder': {
                  color: 'secondary.contrastText',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  border: 'none',
                },
                '& fieldset': {
                  borderColor: isDarkMode ? 'background.default' : 'secondary.dark',
                },
              }}
              size="small"
              type={'text'}
              placeholder="Title of your petition *"
            />
          </FormControl>
          {formik.touched.title && formik.errors.title && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.title && formik.errors.title}
            </Typography>
          )}
        </Grid>
        <div className="col-12">
          <FormControl fullWidth error={formik.touched.details && Boolean(formik.errors.details)}>
            <StyledTextarea
              name="details"
              value={formik.values.details}
              onChange={e => {
                const typedText = e.target.value;
                formik.setFieldValue('details', typedText);
                if (typedText === '<p><br></p>') {
                  formik.setFieldError('details', 'Petition details is required');
                } else if (typedText.length > 0) {
                  formik.setFieldError('details', '');
                }
              }}
              onFocus={handleFocus}
              onBlur={formik.handleBlur}
              onPaste={e => {
                e.preventDefault();
                const pastedText = e.clipboardData.getData('text/plain');
                const currentDetails = formik.values.details || '';
                const newDetails = currentDetails + pastedText;

                formik.setFieldValue('details', newDetails);

                if (newDetails.trim() === '') {
                  formik.setFieldError('details', 'Petition details is required');
                } else {
                  formik.setFieldError('details', '');
                }
              }}
              placeholder="Details of your petition *"
            />
            {formik.touched.details && formik.errors.details && (
              <Typography variant="caption" sx={{ color: 'error.main' }}>
                {formik.errors.details}
              </Typography>
            )}
          </FormControl>
        </div>

        <Grid item xs={12} md={5}>
          <FormControl
            fullWidth
            sx={{ backgroundColor: 'secondary.dark', color: 'secondary.main', borderRadius: '15px' }}
            variant="outlined"
          >
            <OutlinedInput
              name="targetSignature"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.targetSignature}
              error={formik.touched.targetSignature && Boolean(formik.errors.targetSignature)}
              sx={{
                height: '45px',
                color: 'secondary.main',
                border: '1px solid #BFBFBF',
                '&.MuiOutlinedInput-input::placeholder': {
                  color: 'secondary.contrastText',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  border: 'none',
                },
                '& fieldset': {
                  borderColor: isDarkMode ? 'background.default' : 'secondary.dark',
                },
              }}
              size="small"
              type={'number'}
              placeholder="Target signature(s) *"
              onKeyDown={event => {
                const target = event.target as HTMLInputElement;

                if (event.key === 'Backspace' || event.key === 'Tab') {
                  return true;
                }
                if (!/^[0-9]+$/.test(event.key)) {
                  event.preventDefault();
                }
                if (target.value.length >= 7 && /^[0-9]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </FormControl>
          {formik.touched.targetSignature && formik.errors.targetSignature && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.targetSignature && formik.errors.targetSignature}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl
            component="fieldset"
            fullWidth
            sx={{
              backgroundColor: 'secondary.dark',
              color: 'secondary.main',
              borderRadius: '15px',
              width: '100%',
            }}
          >
            <Select
              size="small"
              displayEmpty
              name="organizationId"
              fullWidth
              placeholder="Post as"
              labelId="organization-id"
              id="organizationId"
              value={postAsSelectedValue === 'My Self' ? 'My Self' : postAsSelectedValue === '' ? '' : formik.values.organizationId}
              onChange={event => {
                const selectedValue = event.target.value;
                setPostAsSelectedValue(selectedValue);

                formik.handleChange(event);
                const isPostAsOrganisation = selectedValue !== 'My Self';
                formik.setFieldValue('postAsOrganisation', isPostAsOrganisation);
                formik.setFieldValue('organizationId', isPostAsOrganisation ? selectedValue : '');
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    overflowY: 'scroll',
                  },
                },
                MenuListProps: {
                  style: {
                    maxHeight: '200px',
                  },
                },
              }}
              sx={{
                border: 'none',

                '&.MuiMenu-paper': {
                  backgroundColor: '#FF5500',
                  borderRadius: '15px',
                  border: 'none',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  height: 45,
                },
              }}
            >
              <MenuItem value="">
                <em>
                  <Typography sx={{ fontStyle: 'normal', color: theme.textColor.paragraph }}>Post As</Typography>
                </em>
              </MenuItem>
              <MenuItem value="My Self">Myself</MenuItem>
              {userGrantedOrganizations
                ?.filter(item => !item.deleted)
                .map((item: any) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={5}>
          <FormControl
            fullWidth
            sx={{ backgroundColor: 'secondary.dark', color: 'secondary.main', borderRadius: '15px' }}
            variant="outlined"
          >
            <OutlinedInput
              name="petitionTo"
              autoComplete="off"
              onChange={event => {
                const petitionToValues = event.target.value.split(',');
                formik.setFieldValue('petitionTo', petitionToValues);
              }}
              onFocus={() => {
                formik.setFieldTouched('petitionTo');
                formik.validateField('petitionTo');
              }}
              onBlur={formik.handleBlur}
              value={(formik.values.petitionTo || []).join(',')}
              error={formik.touched.petitionTo && Boolean(formik.errors.petitionTo)}
              sx={{
                height: '45px',
                color: 'secondary.main',
                border: '1px solid #BFBFBF',
                '&.MuiOutlinedInput-input::placeholder': {
                  color: 'secondary.contrastText',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  border: 'none',
                },
                '& fieldset': {
                  borderColor: isDarkMode ? 'background.default' : 'secondary.dark',
                },
              }}
              size="small"
              type={'text'}
              placeholder="Petiton to *"
            />
          </FormControl>
          {formik.touched.petitionTo && formik.errors.petitionTo && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.petitionTo && formik.errors.petitionTo}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl
            fullWidth
            sx={{ backgroundColor: 'secondary.dark', color: 'secondary.main', borderRadius: '15px' }}
            variant="outlined"
          >
            <OutlinedInput
              onChange={formik.handleChange}
              readOnly
              onBlur={formik.handleBlur}
              value={formik.values.location}
              error={formik.touched.location && Boolean(formik.errors.location)}
              sx={{
                height: '45px',
                color: 'secondary.main',
                border: '1px solid #BFBFBF',
                '&.MuiOutlinedInput-input::placeholder': {
                  color: 'secondary.contrastText',
                },
                '&.MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  border: 'none',
                },
                '& fieldset': {
                  borderColor: isDarkMode ? 'background.default' : 'secondary.dark',
                },
              }}
              size="small"
              type={'text'}
              name="latitude"
              placeholder={
                formik.values.place
                  ? formik.values.place
                  : formik.values.latitude && formik.values.longitude
                  ? `Lat: ${formik.values.latitude}, Long: ${formik.values.longitude}`
                  : 'Location'
              }
              onClick={handleOpenModal}
            />
          </FormControl>
          {formik.touched.location && formik.errors.location && (
            <Typography variant="caption" sx={{ color: 'error.main' }}>
              {formik.touched.location && formik.errors.location}
            </Typography>
          )}
        </Grid>

        {formik.values.categoryID !== 19 && (
          <Grid item xs={12} md={5}>
            <FormControl component="fieldset" fullWidth>
              <FormControlLabel
                sx={{ color: 'secondary.main', fontSize: '14px' }}
                control={
                  <Checkbox
                    name="urgent"
                    onChange={event => formik.setFieldValue('urgent', event.target.checked)}
                    disableRipple
                    sx={{
                      color: 'transparent',
                      '&.Mui-checked': { color: 'primary.light' },
                      '& .MuiSvgIcon-root': {
                        fontSize: 23,
                        borderRadius: '25%',
                        border: '1px solid #BFBFBF',
                        padding: { xs: 'none', md: '3px' },
                      },
                    }}
                  />
                }
                label="Mark as urgent"
                labelPlacement="end"
              />
            </FormControl>
          </Grid>
        )}
        <Grid item container xs={12} direction="column" gap={1}>
          <Grid
            item
            xs={12}
            style={{
              outline: formik.touched.multimedia && formik.values.multimedia?.length <= 0 ? '1px solid red' : '1px solid #888A8E',
              borderRadius: '18px',
            }}
          >
            <CreatePetitionImageUpload formik={formik} />
          </Grid>
          <Grid item xs={12}>
            {formik.touched.multimedia && formik.values.multimedia?.length <= 0 ? (
              <Typography variant="caption" sx={{ color: 'error.main' }}>
                Image is required
              </Typography>
            ) : null}
          </Grid>
        </Grid>

        <Grid container direction={'row'} gap={{ xs: 2, md: 0.5 }} alignItems={'center'} justifyContent={'space-between'}>
          <Grid item xs={12} md sx={{ marginRight: { md: '18px' } }}>
            <div style={{ height: '60px' }}>
              <DatePicker
                value={dayjs(new Date(formik.values.startDate))}
                minDate={dayjs(new Date()).startOf('day')}
                defaultValue={dayjs(new Date()).startOf('day')}
                label="Start date"
                sx={{
                  width: '100%',

                  '& .MuiInputBase-root': {
                    borderRadius: '18px',

                    height: '45px',
                  },

                  '& .MuiInputAdornment-root': {},
                }}
                onChange={newValue => {
                  const enteredDate = dayjs(newValue);
                  validateStartDate(enteredDate);
                }}
              />

              {formik.touched.startDate && formik.errors.startDate && (
                <Typography variant="caption" sx={{ color: 'error.main', mb: { xs: 1, lg: 0 } }}>
                  {formik.touched.startDate && formik.errors.startDate}
                </Typography>
              )}
            </div>
          </Grid>

          <Grid item xs={12} md>
            <div style={{ height: '60px' }} onClick={() => {}}>
              <DatePicker
                value={dayjs(formik.values.endDate)}
                minDate={dayjs(formik.values.startDate)}
                defaultValue={dayjs(new Date())}
                label="End date"
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    borderRadius: '15px',
                    height: '45px',
                  },
                  '& .MuiInputAdornment-root': {
                    padding: 0,
                  },
                }}
                onChange={newValue => {
                  const enteredDate = dayjs(newValue);
                  console.log('enteredDate', enteredDate);
                  validateEndDate(enteredDate);
                }}
              />
              {formik.touched.endDate && formik.errors.endDate && (
                <Typography variant="caption" sx={{ color: 'error.main' }}>
                  {formik.touched.endDate && formik.errors.endDate}
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid item xs={2} md={2} lg={2}>
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: 'primary.light',
              textTransform: 'none',
              borderRadius: '15px',
              color: 'secondary.dark',
              height: '40px',
            }}
            onClick={handleCancelButtonClick}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={2} md={2} lg={2}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: dateError
                ? 'secondary.contrastText'
                : !formik.dirty || !formik.isValid
                ? 'secondary.contrastText'
                : 'primary.main',

              textTransform: 'none',
              borderRadius: '15px',
              color: dateError ? 'secondary.contrastText' : !formik.dirty || !formik.isValid ? 'secondary.dark' : 'common.black',
              height: '40px',
              '&:hover': {
                backgroundColor: !formik.dirty || !formik.isValid ? 'secondary.contrastText' : 'primary.main',
                color: !formik.dirty || !formik.isValid ? 'secondary.dark' : 'common.black',
              },
            }}
            disabled={dateError || !formik.dirty || !formik.isValid || formik.isSubmitting || isButtonDisabled}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      <Dialog fullScreen={fullScreen} open={isModalOpen} onClose={handleCloseModal}>
        <MapBoxMapView
          handleCloseModal={handleCloseModal}
          formik={formik}
          handleSelectLocation={handleSelectLocation}
          handleLocationFromSearchBox={handleLocationFromSearchBox}
        />
      </Dialog>
    </Stack>
  );
};

export default CreatePetitionMainSec;
