import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import { fetchProfile, fetchUnAuthorizedProfile } from 'app/shared/reducers/profile';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import toast from 'react-hot-toast';
import { Button, Card, Grid, useMediaQuery } from '@mui/material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { resetLogin, toggleModalLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import NewPrimarySearchAppBar from 'app/components/layout/header/new-primary-header';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { List, ListItem, Link } from '@mui/material';

const drawerWidth = 315;
const drawerWidthSm = 248;

interface Props {
  window?: () => Window;
}
const Faq = () => {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [routeBack, setRouteBack] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  const isBackHome = location.state?.isBackHome;
  const [htmlContent, setHtmlContent] = useState('');
  // const isBackHome = true;
  const fetchAboutUs = async () => {
    try {
      const response = await axios.get<any>(`api/staticContent/faq`);
      console.log('community-guidelines', response);
      setHtmlContent(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);
  useEffect(() => {
    if (isBackHome) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', e => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  const StyledList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column', // Change to column for better vertical alignment
    padding: 0,
    margin: 0,
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: 0,
  }));

  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#4351f1',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  // const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box>
      <NewPrimarySearchAppBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          margin: 'auto',
          overflowY: 'scroll',
          height: '100vh',
          paddingBottom: '120px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Card style={{ background: isDarkMode ? '#2c2c38' : '' }}>
          <Box sx={{ p: 4 }}>
            <Box sx={{ my: 3 }}>
              <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                ReachAMY FAQ
              </Typography>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  What is ReachAMY, and how does it work?
                </AccordionSummary>
                <AccordionDetails>
                  ReachAMY is a community platform for volunteers that organically generates an impact report based on a user or
                  organization's participation or engagement. Our platform connects volunteers and organizers, making it easier to engage in
                  meaningful activities that align with the Sustainable Development Goals (SDGs).Our brand motto, “AM with You (AMY),”
                  reflects our commitment to supporting users in their community service journey.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Is there a mobile app available for ReachAMY? If so, how can I download it?
                </AccordionSummary>
                <AccordionDetails>Yes, ReachAMY is available on multiple platforms: the website, iOS, and Android.</AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How do I sign up for ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  You do not need to sign up to browse through the content. To volunteer or organize an activity, you can sign up using
                  Gmail or create an account directly on ReachAMY.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Is ReachAMY free to use?
                </AccordionSummary>
                <AccordionDetails>
                  ReachAMY operates on a freemium model. Most features are free, but premium features like Invite Only & Featured Activities
                  and Impact Reports are available for a fee.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  What kind of volunteering opportunities can I find on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  You can find activities that cater to multiple categories aligned with the sustainable development goals.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How can I create a volunteering activity or event on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  Click on the “Create Activity” button on the website or the “Add” button on the mobile app.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Can I connect with other volunteers and organizations on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  Yes, you can participate in activities organized by anyone on ReachAMY unless the activity is Invite Only.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How do I search for volunteering opportunities in my area on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  Click on the “Near Me” feature to view activities in your neighborhood. You will need to enable location access on your
                  device.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  What safety measures are in place to protect volunteers on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  You can report activities or users responsible for any untoward incidents or behavior. For direct reports, contact us at
                  contact@reachamy.com.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How can I track my volunteering hours and contributions on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  Track your hours on the Profile page through individual activities or view a summary in your personal impact report.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Can organizations post volunteering opportunities and events on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>Yes, organizations can post opportunities through their representatives.</AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Is there a rating or review system for volunteers and organizations on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>Currently, we do not have a rating or review system for volunteers and organizers.</AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How can I edit or delete a volunteering activity I've created on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  You can only edit an activity if no participants have signed up for it. Once participants have signed up, only the time
                  and location can be edited. You cannot delete an activity that has already started; however, you can delete an activity
                  that has not yet started.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  What do I do if I encounter an issue or have a question while using ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  For app-related questions, contact support@reachamy.com. For activity-related questions, post your inquiries in the chat
                  for organizers and participants to respond.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How can I report inappropriate content or behavior on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  Report activities, petitions, comments, or individuals by clicking on the three dots (vert) menu.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Can I get a refund?
                </AccordionSummary>
                <AccordionDetails>
                  Since these services are delivered digitally and provide immediate access to valuable features and insights, refunds are
                  not available. We appreciate your understanding.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How can I share my volunteering activities on social media through ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  You can share your activities directly on social media platforms by using the share button available on each activity's
                  page.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Are there any age restrictions for using ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  ReachAMY is open to users aged 13 and above for security and safety reasons. We recommend that participants under 18 be
                  accompanied by an adult. Organizers may also set specific age restrictions for their activities.
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How can I get involved in organizing large-scale events on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  If you’re interested in organizing large-scale events, you can contact our support team at support@reachamy.com for
                  guidance and support.
                </AccordionDetails>
              </Accordion>
            </Box> */}
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Does ReachAMY offer any tools for tracking the impact of my volunteering activities?
                </AccordionSummary>
                <AccordionDetails>
                  Yes, ReachAMY provides impact reports that summarize your volunteering contributions and hours.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Can I customize the notifications I receive from ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  Yes, you can customize your notification preferences in the settings section of your profile.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How do I reset my password if I forget it?
                </AccordionSummary>
                <AccordionDetails>
                  If you forget your password, click on the “Forgot Password” link on the login page, and follow the instructions to reset
                  it.
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Can I participate in activities organized by international organizations on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  Yes, you can participate in activities organized by international organizations, depending on the nature and location of
                  the activity.
                </AccordionDetails>
              </Accordion>
            </Box> */}
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How can I invite friends to join an activity on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  You can invite friends by sharing the activity link or using the invite feature available on the activity’s page.
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  What types of premium features are available on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  Premium features include Invite Only activities, Featured Activities, and detailed Impact Reports.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How do I update my profile information on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  You can update your profile information by going to the profile section and clicking on the “Edit Profile” button.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Are there any tutorials or guides available for new users of ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  You can explore the blog section to find activities that can help you get started. Additionally, by following activities
                  organized by others, you can gain inspiration to launch your own initiatives.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Can I save activities I’m interested in for later?
                </AccordionSummary>
                <AccordionDetails>
                  Yes, you can save activities by adding them to your bookmarked list in your profile by clicking on the bookmark icon.
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How does ReachAMY ensure the quality and legitimacy of the volunteering opportunities listed?
                </AccordionSummary>
                <AccordionDetails>
                  ReachAMY conducts verification processes for organizations and activities to ensure quality and legitimacy.
                </AccordionDetails>
              </Accordion>
            </Box> */}
            {/* <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  What is the process for becoming a verified organizer on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  To become a verified organizer, you need to submit an application through the platform, which includes providing relevant
                  documentation and information about your organization.
                </AccordionDetails>
              </Accordion>
            </Box> */}

            {/* <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Can I link my ReachAMY account with my social media accounts?
                </AccordionSummary>
                <AccordionDetails>
                  TYes, you can link your ReachAMY account with your social media accounts for easier sharing and sign-in options.
                </AccordionDetails>
              </Accordion>
            </Box> */}
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How can I set my ReachAMY profile privacy?
                </AccordionSummary>
                <AccordionDetails>
                  You can choose from three options, Default (visible to registered users only), public (visible to everyone), private
                  (visible to co-participants and organisers only)
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Can I provide feedback or suggestions for improving ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  Yes, we welcome your feedback and suggestions. You can provide feedback through the app or by contacting us at
                  support@reachamy.com
                </AccordionDetails>
              </Accordion>
            </Box>
            {/* <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  How are featured activities selected on ReachAMY?
                </AccordionSummary>
                <AccordionDetails>
                  Featured activities are selected based on their impact, popularity, and relevance to current community needs.
                </AccordionDetails>
              </Accordion>
            </Box> */}
            {/* <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  Does ReachAMY offer any support for corporate volunteering programs?
                </AccordionSummary>
                <AccordionDetails>
                  Yes, ReachAMY offers support for corporate volunteering programs. Organizations can contact us for more information on how
                  to get started.
                </AccordionDetails>
              </Accordion>
            </Box> */}
            <Box sx={{ my: 3 }}>
              {/* <Typography variant="body1" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Consequences of Violation
              </Typography> */}
              {/* <Typography variant="body1" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                These additional questions can help provide comprehensive information to users and enhance their understanding and
                experience of using ReachAMY.
              </Typography> */}
            </Box>
            <Box>
              <Typography variant="body1" paragraph>
                <strong>Other Relevant Links :</strong>
              </Typography>

              <StyledList sx={{ display: 'flex', flexDirection: 'row' }}>
                <StyledListItem>
                  <StyledLink href="https://reachamy.com/community-guidelines">Community Guidelines</StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink href="https://reachamy.com/privacy-policy">Privacy Policy</StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink href="https://reachamy.com/cookie-policy">Cookie Policy</StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink href="https://reachamy.com/legal-notices">Legal Notices</StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink href="https://reachamy.com/terms-and-condition">Terms and Conditions</StyledLink>
                </StyledListItem>
              </StyledList>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default Faq;
