import React from 'react';
import { Dialog, DialogActions, Button, Typography, useTheme } from '@mui/material';
import { deleteCommentById } from 'app/shared/reducers/blogs';
import { useAppDispatch } from 'app/config/store';

const DeleteCommentDialog = ({ open, onClose, onDelete, comment }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleDelete = async () => {
    const params = {
      commentId: comment?.id,
    };

    try {
      const postResponse = await dispatch(deleteCommentById(params));
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOKClick = payload => {
    onClose(payload);
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="xs" fullWidth>
      <div style={{ padding: '16px' }}>
        <Typography variant="h6" gutterBottom>
          Confirm Deletion
        </Typography>
        <Typography variant="body1">Are you sure you want to delete this comment?</Typography>
      </div>
      <DialogActions>
        <Button onClick={handleCancel} sx={{ color: isDarkMode ? '#FFCE00' : '#674bff' }}>
          Cancel
        </Button>
        <Button onClick={handleDelete} sx={{ color: isDarkMode ? '#FFCE00' : '#674bff' }}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCommentDialog;
