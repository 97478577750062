import React, { useMemo, useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import { Link, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import EventFilterTabs from './event-filter-tabs/event-filter-tabs';
import PrimaryHeader from './header/primary-header';
import SideNavigation from './side-navigaion/side-navigation';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import './common-layout.scss';
import { getReportingReasons } from 'app/shared/reducers/activity/activity-report';
import AmyIcon from '../common/cards/chat-card/amy-icons';

const drawerWidth = 315;
const drawerWidthSm = 248;

interface Props {
  window?: () => Window;
}
export default function CommonLayout(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const eventType = useAppSelector(state => state.homeActivity.eventType);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [toggleFeatureValue, setToggleFeatureValue] = useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [isSearching, setIsSearching] = React.useState(false);
  const [localSearchInput, setLocalSearchInput] = React.useState('');
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const navigate = useNavigate();
  const memoizedImage = useMemo(
    () => (
      <Box style={{ paddingLeft: '0px', paddingRight: '50px' }} onClick={() => navigate(-1)}>
        <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" />
      </Box>
    ),
    [navigate]
  );
  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };
  const handleAccordionClick = (category: any, currentState: any) => {
    console.log('check category clikc');
    setCategory(category);
  };
  const handleButtonClicked = () => {
    const newToggleValue = !toggleValue;
    if (toggleMapValue || toggleFeatureValue) {
      setToggleMapValue(false);
      setToggleFeatureValue(false);
    }
    setToggleValue(newToggleValue);
  };

  const handleButtonMapClicked = () => {
    const newToggleMapValue = !toggleMapValue;
    if (toggleValue || toggleFeatureValue) {
      setToggleValue(false);
      setToggleFeatureValue(false);
    }
    setToggleMapValue(newToggleMapValue);
  };

  const handleButtonFeatureClicked = () => {
    const newToggleFeatureValue = !toggleFeatureValue;
    if (toggleValue || toggleMapValue) {
      setToggleValue(false);
      setToggleMapValue(false);
    }
    setToggleFeatureValue(newToggleFeatureValue);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const handleLogClick = () => {
    location.href = '/';
  };
  const drawer = (
    <Box
      sx={{
        border: 'none',
        maxHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',

        background:
          theme.palette.mode === 'dark'
            ? '#1F1F26'
            : 'transparent linear-gradient(182deg, #F7F5EF 0%, #F2F2FA 49%, #f1f1f8 100%) 0% 0% no-repeat padding-box;',
        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',
        minHeight: '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Box style={{ paddingLeft: '0px', paddingRight: '50px' }} onClick={handleLogClick}>
          <Link to={'/'}>
            <AmyIcon sx={{ width: '159px', height: '100px' }} />
          </Link>
        </Box>
      </DrawerHeader>

      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={handleSubCategoryClick}
        onhandleAccordionClick={handleAccordionClick}
        onButtonClicked={handleButtonClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onMapClicked={handleButtonMapClicked}
        onFeatureClicked={handleButtonFeatureClicked}
      />
      <Divider />
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const eventFilterTabs = useMemo(
    () => (
      <EventFilterTabs
        eventType={eventType}
        toggleValue={toggleValue}
        toggleMapValue={toggleMapValue}
        toggleFeatureValue={toggleFeatureValue}
        clearSearchKeyWords={setSearchKeyword}
        setIsSearching={setIsSearching}
        setLocalSearchInput={setLocalSearchInput}
        localSearchInput={localSearchInput}
        category={category}
      />
    ),
    [eventType, toggleValue, toggleMapValue, toggleFeatureValue, category]
  );

  //removed due to repeatation
  // useEffect(() => {
  //   if (isAuthenticated) {
  //     dispatch(getReportingReasons());
  //   }
  // }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        p: 0,
        border: 'none',
        backdropFilter: 'blur(10px)',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />

      {/* header */}
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
          background: isDarkMode ? '#1F1F26' : 'none',
        }}
      >
        <Toolbar
          className="amy-top-nav"
          sx={{
            backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
            border: 'none',
            backdropFilter: 'blur(10px)',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, top: '10px' }}
          >
            <MenuIcon />
          </IconButton>
          <PrimaryHeader
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            isSearching={isSearching}
            setIsSearching={setIsSearching}
            setLocalSearchInput={setLocalSearchInput}
            localSearchInput={localSearchInput}
          />
        </Toolbar>
      </AppBar>
      {/* End Header */}

      {/* Side Nav */}
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth },
          flexShrink: { sm: 0 },
          minHeight: '100vh',
          border: 'none',
          background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSm },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth } },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* End side Nav */}

      {/* main body */}
      <Box
        className="inside-bg"
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          // width: {
          //   sm: `calc(100% - ${drawerWidthSm} px)`,
          //   md: `calc(100% - ${drawerWidthSm} px)`,
          //   lg: `calc(100% - ${drawerWidth} px)`},
          width: '0px',
          background: isDarkMode
            ? '#1F1F26'
            : ' transparent linear-gradient(172deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat padding-box',
          border: 'none',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Toolbar />
        <div className="container-fluid p-0" style={{ background: isDarkMode ? '#1F1F26' : '' }}>
          <div className="row screen-space">
            <div className=" col-sm-12 col-md-10 col-lg-8 col-scroll">
              <Box
                sx={{
                  border: 'none',
                  maxHeight: '100vh',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {eventFilterTabs}
              </Box>
            </div>
            <div className="col-md-2 col-lg-4 filter-response fixed-column">
              {/* <div className="fixed-img">
                <img src="content/images/add1.png" alt="ad1" className=" width borderRadius" />
                <img src="content/images/add2.png" alt="ad2" className="width" />
              </div> */}
              <Box display="flex" flexDirection="column" alignItems="center" gap={1} borderRadius={1.6} height="calc(100vh - 97px)" pt={3}>
                <img src="content/images/app-apple-store.webp" alt="ad1" width="65%" />
                <img src="content/images/app-google-play.webp" alt="ad2" width="65%" />
              </Box>
            </div>
          </div>
        </div>
      </Box>
      {/* End main body */}
    </Box>
  );
}
