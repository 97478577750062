import React from 'react';
import { Dialog, DialogActions, Button, Typography, TextField, useTheme } from '@mui/material';
import { LeaveActivity } from 'app/shared/reducers/activity/activity-actions';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import toast from 'react-hot-toast';
import { fetchBookMarkedItems } from 'app/shared/reducers/profile';

const LeaveActivityDialog = ({ open, onClose, handleData }) => {
  const [leaveReason, setLeaveReason] = React.useState('');
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleReport = async () => {
    const params = {
      activityIds: [handleData?.id],
      message: leaveReason ? leaveReason : '',
    };

    try {
      const postResponse = await dispatch(LeaveActivity(params));

      if (postResponse.payload != undefined) {
        toast.success('Left succesfully');
      } else {
        toast.error('Status not updated');
      }

      // Call onClose() only if the API call is successful
      setLeaveReason('');
      onClose();
    } catch (error) {
      toast.error('Something Went Wrong');
      console.error(error);
    }
  };

  const handleCancel = () => {
    setLeaveReason('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="xs" fullWidth>
      <div style={{ padding: '16px' }}>
        <Typography variant="h6" gutterBottom>
          Leave Activity
        </Typography>
        <Typography variant="body1">Please provide a reason for leaving the activity.</Typography>
        <TextField
          label="Reason for Leaving"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={leaveReason}
          onChange={e => setLeaveReason(e.target.value)}
          sx={{ marginTop: '16px' }}
        />
      </div>
      <DialogActions>
        <Button onClick={handleCancel} sx={{ color: isDarkMode ? '#FFCE00' : '#674BFF' }}>
          Cancel
        </Button>
        <Button onClick={handleReport} sx={{ color: isDarkMode ? '#FFCE00' : '#674BFF' }}>
          Leave
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveActivityDialog;
