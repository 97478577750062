import { Avatar, Box, Button, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './settings-org-invitations.scss';
import { fetchPendingInvitations, inviteMembers, removePendingInvitee } from 'app/shared/reducers/settings/settings-organization';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { settingOrgPermssionProps } from './settings-org-rightcard';

const SettingsOrgInvitations = ({ hasPermission }: settingOrgPermssionProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const dispatch = useAppDispatch();
  const { selectedOrgId, removeInviteePendingLoading, inviteLoading, pendingInvitations } = useAppSelector(
    state => state.settingsOrganization
  );

  useEffect(() => {
    if (selectedOrgId) dispatch(fetchPendingInvitations({ orgId: selectedOrgId }));
  }, [selectedOrgId, dispatch]);

  const handleRemove = async (orgId, email) => {
    try {
      await dispatch(removePendingInvitee({ orgId, email }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleReInviteClick = (organizationId, email) => {
    dispatch(inviteMembers({ organizationId, emailAddresses: [email] }))
      .then(() => {
        dispatch(fetchPendingInvitations({ orgId: organizationId }));
      })
      .catch(error => {
        toast.error('Failed to send invitations');
      });
  };

  return (
    <Grid container spacing={2} pt={1}>
      <Grid item xs={12} mb={2}>
        <Typography variant="h6" px={1} sx={{ color: theme.palette.mode === 'dark' ? '#ffff' : '#2a2a2a' }}>
          Pending invitations
        </Typography>
      </Grid>
      <Grid item xs={12} className="pt-1">
        {/* <Box
          sx={{
            width: '100%',
          }}
        > */}
        <Grid container spacing={2}>
          <Grid item xs={12} className="org-invitation-pt-0 ">
            {pendingInvitations.length > 0 ? (
              <Box
                className="example-scrol"
                sx={{
                  width: '100%',
                  minHeight: '100px',
                  maxHeight: '290px',
                }}
              >
                {pendingInvitations.map(user => (
                  <Grid container spacing={2} p="8px 31px" gap="10px" m={0} alignItems={'center'} justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap={2}>
                      <Grid item xs={2} md={2} className="org-invitation-avatar pl-0 ">
                        <Avatar alt={user?.email[0]} src="/static/images/avatar/1.jpg" />
                      </Grid>
                      <Grid item xs={6} md={6} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box sx={{ color: theme.palette.mode === 'dark' ? '#ffff' : '#2a2a2a' }}>
                          <Typography variant="subtitle2">{user.email}</Typography>
                        </Box>
                      </Grid>
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} textAlign={'end'}>
                        {user.status === 'PENDING' ? (
                          <div style={{ display: 'flex', gap: '8px' }}>
                            <Button
                              className="org-invitation-btn"
                              disableElevation
                              disableRipple
                              size="small"
                              onClick={() => handleRemove(selectedOrgId, user.email)}
                              sx={{
                                width: '75px',
                                color: 'primary.light',
                                borderRadius: 1.875,
                                border: '1px solid #674BFF',
                                '&.MuiButtonBase-root:hover': {
                                  bgcolor: 'transparent',
                                  border: '1px solid #674BFF  ',
                                },
                              }}
                              variant="outlined"
                              disabled={removeInviteePendingLoading}
                            >
                              Remove
                            </Button>
                            {user.status !== 'DECLINED' && (
                              <Button
                                onClick={() => handleReInviteClick(selectedOrgId, user.email)}
                                variant="outlined"
                                disableElevation
                                disableRipple
                                size="small"
                                sx={{
                                  borderRadius: 1.875,
                                  color: '#F6F7F8',
                                  textTransform: 'none',
                                  border: `1px solid`,
                                  borderColor: 'primary.light',
                                  bgcolor: 'primary.light',
                                  fontSize: theme.infoText.regular.fontSize,
                                  fontWeight: theme.infoText.regular.fontWeight,
                                  fontStyle: theme.infoText.regular.fontStyle,
                                  '&.MuiButtonBase-root:hover': {
                                    bgcolor: 'primary.light',
                                    borderColor: 'primary.light',
                                  },
                                }}
                              >
                                Reinvite
                              </Button>
                            )}
                          </div>
                        ) : user.status === 'DECLINED' ? (
                          <Button
                            className="org-invitation-btn"
                            disableElevation
                            disableRipple
                            size="small"
                            onClick={() => handleReInviteClick(selectedOrgId, user.email)}
                            sx={{
                              width: '75px',
                              color: '#F6F7F8',
                              borderRadius: 1.875,
                              bgcolor: 'primary.light',
                              border: '1px solid #674BFF',
                              '&.MuiButtonBase-root:hover': {
                                bgcolor: 'primary.light',
                                color: '#F6F7F8',
                                border: '1px solid #674BFF  ',
                              },
                            }}
                            variant="outlined"
                            disabled={inviteLoading}
                          >
                            Reinvite
                          </Button>
                        ) : null}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Box>
            ) : (
              <Box
                className="example-scrol"
                sx={{
                  width: '100%',
                  height: '100px',
                }}
              >
                <Grid container spacing={2} p={1} m={0} alignItems={'center'}>
                  <Grid item xs={12}>
                    <Typography variant="caption" sx={{ color: theme.palette.mode === 'dark' ? '#ffff' : 'rgba(0, 0, 0, 0.6)' }}>
                      There are no pending invitations
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
        {/* </Box> */}
      </Grid>
    </Grid>
  );
};

export default SettingsOrgInvitations;
