import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface bookmarkactivityType {
  data: string;
}

export interface bookmarkState {
  bookmarkedActivity: bookmarkactivityType | null;
  bookmarkFlag: boolean;
  loading: boolean;
  error: string | null;
  success: boolean;
}

export const initialState: bookmarkState = {
  bookmarkedActivity: null,
  bookmarkFlag: false,
  loading: false,
  error: null,
  success: false,
};

export const fetchBookmarkedActivity = createAsyncThunk<bookmarkactivityType, number, { rejectValue: string }>(
  'bookmark/fetchBookmarkedActivity',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get<bookmarkactivityType>(`/api/activities/bookmark/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const isRemoteActivity = createAsyncThunk<any, number, { rejectValue: string }>(
  'bookmark/isRemoteActivity',
  async (id, thunkAPI) => {
    try {
      const currentUTCDateTime = new Date().toISOString();
      const body = {
        dateAndTime: currentUTCDateTime,
        recurringId: id,
        activityId: 0,
      };
      const response = await axios.post<any>(`/api/remote-activity/join`, body);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const fetchBookmarkedPetition = createAsyncThunk<bookmarkactivityType, number, { rejectValue: string }>(
  'bookmark/fetchBookmarkedPetition',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get<bookmarkactivityType>(`/api/petition/bookmark/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.message || 'An error occurred');
    }
  }
);

export const signedPetition = createAsyncThunk<any, { number: number; petitionData: any }, { rejectValue: string }>(
  'bookmark/signedPetition',
  async number => {
    try {
      const response = await axios.post<any>(`/api/petition/signing/${number?.number}`, number?.petitionData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const leavePetition = createAsyncThunk<any, { number: number; petitionData: any }, { rejectValue: string }>(
  'bookmark/leavePetition',
  async number => {
    try {
      const response = await axios.post<any>(`/api/petition/leave/${number?.number}`, number?.petitionData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const reportPetition = createAsyncThunk<any, { number: number; petitionData: any }, { rejectValue: string }>(
  'bookmark/reportPetition',
  async number => {
    try {
      const response = await axios.post<any>(`/api/petition/report/${number?.number}`, number?.petitionData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const reportCommentPetition = createAsyncThunk<
  any,
  { petitionId: number; signatureId: any; petitionData: any },
  { rejectValue: string }
>('bookmark/reportPetition', async ({ petitionId, signatureId, petitionData }, { rejectWithValue }) => {
  try {
    const queryString = new URLSearchParams(petitionData).toString();
    const response = await axios.post<any>(`/api/petition/report/reason/${petitionId}/${signatureId}?reason=${petitionData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const bookmarkSlice = createSlice({
  name: 'bookmarkactivity',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBookmarkedActivity.pending, state => {
        state.loading = true;
        state.error = null;
        state.bookmarkFlag = false;
        state.success = false;
      })
      .addCase(fetchBookmarkedActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.bookmarkedActivity = action.payload;
        state.bookmarkFlag = true;
        state.success = true;
      })
      .addCase(fetchBookmarkedActivity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'An error occurred';
        state.bookmarkFlag = false;
        state.success = false;
      });
  },
});

export default bookmarkSlice.reducer;
