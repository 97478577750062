import React, { useEffect, useState } from 'react';

import { GoogleSocialLogin, login } from 'app/shared/reducers/authentication';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import FacebookIcon from 'app/components/icons/login-page-icons/facebook-icon';
import GoogleIcon from 'app/components/icons/login-page-icons/google-icon';
import TwitterIcon from 'app/components/icons/login-page-icons/twitter-icon';

import { Button, Grid, TextField, Typography, useTheme, Box } from '@mui/material';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import LoaderIcon from 'app/components/common/loader-icon/loader-icon';
import { useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import WhiteTwitterIcon from 'app/components/icons/login-page-icons/white-twitter-icon';
import { getPhoneNumber } from 'app/shared/reducers/phone-verification';
import { fetchOrganization } from 'app/shared/reducers/organization';

interface LoginPageProps {
  currentComponent?: any;
  setCurrentComponent?: any;
  setEnteredMail?: any;
  handleAuthModalClose?: any;
}
const LoginPage = ({ currentComponent, setCurrentComponent, setEnteredMail, handleAuthModalClose }: LoginPageProps) => {
  const handleForgotPassword = () => {
    setCurrentComponent('forgotPassword');
  };
  const handleSignUp = () => {
    setCurrentComponent('signup');
  };

  const socialLogin = useGoogleLogin({
    onSuccess: async codeResponse => {
      try {
        // Dispatch the social login action
        await dispatch(GoogleSocialLogin({ code: codeResponse.code }));
        console.log('check login insided');
        // Check user authentication and preferences
        const isUserPreferenceDone = sessionStorage.getItem('userPreferencesCompleted');
        const myCookieValue = Cookies.get('jhi-authenticationToken');
        const authenticationToken = myCookieValue;
        const authenticated = !!authenticationToken;

        if (authenticated) {
          if (invitedOrgId || isInvited) {
            dispatch(fetchOrganization(invitedOrgId));

            if (urlOrgId) {
              navigate(`/organization/${urlOrgId}`);
            } else {
              handleAuthModalClose();
            }
          } else {
            if (isUserPreferenceDone === 'true') {
              navigate('/');
            } else {
              navigate('/welcome');
            }
          }
        }
      } catch (error) {
        console.error('Error during social login:', error);
        // Handle the error as needed, e.g., show a message to the user
      }
    },
    flow: 'auth-code',
    onError: error => console.log('error:', error),
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const loading = useAppSelector(state => state.authentication.loading);
  const loginSuccess = useAppSelector(state => state.authentication.loginSuccess);
  const errorMessage = useAppSelector(state => state.authentication.errorMessage);
  const showModalLogin = useAppSelector(state => state.authentication.showModalLogin);
  // const isUserPreferenceDone = useAppSelector(state => state.authentication.userPreferencesDone);

  const buttonStyle = {
    marginBottom: '10px',
    backgroundColor: theme.palette.secondary.dark,
    border: '1px solid #674BFF',
    borderRadius: '32px',
    padding: '6px',
    color: '#292d34',
    boxShadow: 'none',
    '&:active, &:focus, &:hover': {
      backgroundColor: theme.palette.secondary.dark,
      border: '1px solid #674BFF !important',
      borderRadius: '32px',
      padding: '6px',
      color: '#292d34 !important',
      boxShadow: 'none',
    },
  };

  const heading = {
    color: theme.textColor.mainHeading,
    marginBottom: '10px',
    marginTop: '10px',
    fontSize: '15px',
    fontWeight: '600',
  };

  const signupText = {
    color: theme.textColor.heading,
    fontSize: '11px',
    fontWeight: '600',
  };

  const invitedOrgId = parseInt(localStorage.getItem('invitedOrgId'));

  const [searchParams] = useSearchParams();
  const invited = searchParams.get('invited');
  const isInvited = invited === 'true';
  const urlOrgId = searchParams.get('org');

  const handleLogin = async (email: string, password: string) => {
    try {
      let res = await dispatch(login(email, password));
      console.log('check', res);
      const isUserPreferenceDone = sessionStorage.getItem('userPreferencesCompleted');
      const myCookieValue = Cookies.get('jhi-authenticationToken');
      const authenticationToken = myCookieValue;
      const authenticated = !!authenticationToken;
      if (authenticated) {
        await dispatch(getPhoneNumber());
        if (invitedOrgId || isInvited) {
          dispatch(fetchOrganization(invitedOrgId));

          if (urlOrgId) {
            navigate(`/organization/${urlOrgId}`);
          } else {
            handleAuthModalClose();
          }
        } else {
          if (isUserPreferenceDone === 'true') {
            navigate('/');
          } else {
            navigate('/welcome');
          }
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
      // Handle the error as needed, e.g., show a message to the user
    }
  };

  const loginUser = ({ email, password }) => {
    handleLogin(email, password);
  };
  // Validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email cannot be empty!').email('Invalid email format!'),
    password: Yup.string().required('Password cannot be empty!').min(4, 'Password must be 8 characters long.'),
  });

  // Formik form submission handler
  const handleLoginSubmit = (values, { setSubmitting }) => {
    Cookies.remove('jhi-authenticationToken');
    // localStorage.clear();
    sessionStorage.clear();
    setSubmitting(true);
    loginUser(values);
    setSubmitting(false);
  };

  const isOrganizationPage = /^\/organization\/\d+/.test(location.pathname);

  return (
    <>
      <style>
        {`.css-9dyvjb-MuiFormControl-root-MuiTextField-root {
        background:none !important
      }`}
      </style>
      {showModalLogin && (
        <Grid
          container
          spacing={2}
          width={'100%'}
          padding={'10px'}
          direction={'column'}
          margin={'0'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <div onClick={() => navigate('/')}>
            <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" style={{ height: '58px' }} />
          </div>
          <h6 style={heading}>We look forward to your company!</h6>
          <Button
            variant="contained"
            color="primary"
            sx={buttonStyle}
            onClick={() => {
              socialLogin();
            }}
          >
            <GoogleIcon style={{ width: '20px', height: '20px', marginRight: '2px' }} />
            <span>
              <Typography sx={{ textTransform: 'capitalize', color: theme.palette.primary.light, fontSize: '11px', fontWeight: '600' }}>
                Continue with Google
              </Typography>
            </span>
          </Button>

          <div
            style={{
              marginBottom: '5px',
              alignItems: 'center',
            }}
          >
            <Button
              variant="text"
              style={{
                background: '#DFDFDF',
                borderRadius: '50%',
                minWidth: '30px',
                height: '25px',
                padding: '0',
                color: '#292D34',
              }}
            >
              <Typography textTransform={'capitalize'} fontSize={'10px'} fontWeight={'600'}>
                Or
              </Typography>
            </Button>
          </div>
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={validationSchema}
            onSubmit={handleLoginSubmit}
          >
            {({ errors, touched, isSubmitting, handleChange }) => (
              <Form>
                <Grid>
                  <Grid container sx={{ justifyContent: 'center' }}>
                    <Grid item container sx={{ justifyContent: 'center', direction: 'column' }} xs={6} md={6} gap={1}>
                      <Field
                        disableUnderline
                        type="text"
                        name="email"
                        placeholder="Email"
                        as={TextField}
                        required
                        inputProps={{
                          style: {
                            height: '12px',
                          },
                        }}
                        sx={{
                          backgroundColor: theme.palette.secondary.dark,
                          color: theme.textColor.heading,
                          width: '80%',
                          marginBottom: '6px',
                        }}
                        error={touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        onChange={(e: any) => {
                          setEnteredMail(e.target.value);
                          handleChange(e);
                        }}
                      />

                      <Field
                        type="password"
                        name="password"
                        placeholder="Password"
                        as={TextField}
                        required
                        inputProps={{
                          style: {
                            height: '12px',
                          },
                        }}
                        sx={{
                          backgroundColor: theme.palette.secondary.dark,
                          color: theme.textColor.heading,
                          width: '80%',
                          marginBottom: '6px',
                        }}
                        error={touched.password && !!errors.password}
                        helperText={touched.password && errors.password}
                      />

                      <Box
                        sx={{
                          backgroundColor: 'transparent',
                          border: 'none',
                          color: theme.palette.primary.light,
                          justifyContent: 'flex-end',
                          width: '80%',
                          height: '5px',
                          fontSize: '11px',
                          padding: '1px',
                          marginBottom: '10px',
                          textAlign: 'end',
                          cursor: 'pointer',
                        }}
                        onClick={handleForgotPassword}
                      >
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '11px' }}>Forgot password?</Typography>
                      </Box>
                      {errorMessage !== null && (
                        <Grid
                          container
                          sx={{
                            justifyContent: 'center',
                            padding: '5px',
                            color: theme.palette.error.main,
                            fontSize: '12px',
                            textAlign: 'center',
                          }}
                        >
                          {errorMessage === 'Rejected'
                            ? 'You have already signed up with us. Please log in using your email and password.'
                            : errorMessage}
                        </Grid>
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          marginBottom: '10px',
                          height: theme.createActivity.height,
                          backgroundColor: theme.palette.primary.light,
                          borderRadius: theme.createActivity.borderRadius,
                          color: theme.palette.secondary.dark,
                          borderColor: theme.palette.primary.main,
                          width: '80%',
                          boxShadow: 'none',
                          '&:active, &:focus, &:hover': {
                            backgroundColor: theme.createActivity.backgroundColor.hover,
                            boxShadow: 'none',
                          },
                        }}
                        endIcon={loading ? <LoaderIcon /> : null}
                        disabled={isSubmitting}
                      >
                        <span>
                          <Typography fontSize={'13px'}>Log in</Typography>
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <span style={signupText} className="d-flex">
            Do not have an account?
            <a style={{ paddingLeft: '1px', paddingTop: '1px' }} onClick={handleSignUp}>
              <Typography
                sx={{
                  textTransform: 'capitalize',
                  fontSize: '11px',
                  color: theme.palette.primary.light,
                  fontWeight: '600',
                }}
              >
                Sign Up
              </Typography>
            </a>
          </span>
          <span style={{ fontSize: '11px', textAlign: 'justify', width: '70%', fontWeight: '600' }}>
            Your information is safe and secure. By signing up, you agree to our{' '}
            <span
              style={{
                marginLeft: '1px',
                marginRight: '1px',
                color: theme.palette.primary.light,
                textTransform: 'capitalize',
                fontSize: '11px',
              }}
            >
              <span style={{ cursor: 'pointer' }} onClick={() => navigate('/privacy-policy')}>
                privacy policy
              </span>
              , &nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => navigate('/cookie-policy')}>
                cookie policy
              </span>
              , &nbsp;
              <span style={{ cursor: 'pointer' }} onClick={() => navigate('/member-agreement')}>
                Member agreement
              </span>
            </span>
            &nbsp;and that we may share your personal information with our partners to verify your account.
          </span>
        </Grid>
      )}
    </>
  );
};

export default LoginPage;
