̥import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    targetResult:null
}

const ChatList = createSlice({
  name: 'chat-list',
  initialState,
  reducers: {
    updateChatList(state,action) {
        state.targetResult = action.payload
    }
  }
});

export const {updateChatList} = ChatList.actions

export default ChatList.reducer