import { useAppDispatch } from 'app/config/store';
import { addMessage } from 'app/shared/reducers/chat-messages/chat-Panel';
import React, { createContext, useRef, useState, useEffect, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface WebSocketContextType {
  webSocket: React.MutableRefObject<WebSocket | null>;
  isConnected: boolean;
  disconnect: () => void;
  sendMessage: (message: string) => void;
}

const defaultContextValue: WebSocketContextType = {
  webSocket: { current: null },
  isConnected: false,
  disconnect: () => console.warn('WebSocketProvider is not yet initialized'),
  sendMessage: (message: string) => console.warn('sendMessage function is not yet initialized'),
};

const WebSocketContext = createContext<WebSocketContextType>(defaultContextValue);

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const webSocket = useRef<WebSocket | null>(null);
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 5;
  const reconnectDelay = 3000; // 3 seconds delay before attempting to reconnect

  const token = JSON.parse(localStorage?.getItem('jhi-authenticationToken')) || '';
  const id = JSON.parse(localStorage?.getItem('MyDetailsAccount'))?.id || '';

  const connectWebSocket = () => {
    const getBaseDomain = (url: string) => {
      const urlParts = url.split('/');
      return urlParts[2];
    };

    const domain = getBaseDomain(window.location.href);
    const socketUrl = `wss://${domain}/websocket?token=${token}&userId=${id}`;
    webSocket.current = new WebSocket(socketUrl);
    console.log('WebSocket URL:', socketUrl);

    webSocket.current.onopen = () => {
      console.log('WebSocket connection established');
      setIsConnected(true);
      reconnectAttempts.current = 0;
    };

    webSocket.current.onerror = error => {
      console.error('WebSocket error:', error);
      setIsConnected(false);
    };

    webSocket.current.onclose = event => {
      console.log('WebSocket connection closed:', event);
      setIsConnected(false);

      if (location.pathname === '/chat') {
        attemptReconnect();
      }
    };

    webSocket.current.onmessage = event => {
      const message = event.data;
      console.log('Received message:', message);
      setIsConnected(true);

      // Handle incoming message here
    };
  };

  const attemptReconnect = () => {
    if (reconnectAttempts.current < maxReconnectAttempts) {
      // reconnectAttempts.current += 1;
      // console.log(`Attempting to reconnect... (${reconnectAttempts.current})`);
      // setTimeout(connectWebSocket, reconnectDelay);
      setIsConnected(true);
    } else {
      console.warn('Max reconnect attempts reached. No more reconnection attempts will be made.');
    }
  };

  useEffect(() => {
    connectWebSocket();

    // Cleanup function to close WebSocket connection when the component unmounts
    // return () => {
    //   if (webSocket.current) {
    //     webSocket.current.close();
    //     console.log('WebSocket manually closed');
    //   }
    // };
  }, [token, id, location.pathname]);

  const disconnect = () => {
    console.log('Manually disconnected');
    if (webSocket.current && isConnected) {
      webSocket.current.close();
      console.log('Manually disconnected');
      setIsConnected(false);
    }
  };

  const sendMessage = (message: string) => {
    if (webSocket.current && isConnected) {
      webSocket.current.send(message);
      setIsConnected(true);
      console.log('Message sent:', message);
    } else {
      console.warn('WebSocket is not connected');
    }
  };

  return <WebSocketContext.Provider value={{ webSocket, isConnected, disconnect, sendMessage }}>{children}</WebSocketContext.Provider>;
};

export default WebSocketContext;
