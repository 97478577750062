import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import { fetchProfile, fetchUnAuthorizedProfile } from 'app/shared/reducers/profile';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import toast from 'react-hot-toast';
import { Button, Card, Grid, useMediaQuery } from '@mui/material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { resetLogin, toggleModalLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import NewPrimarySearchAppBar from 'app/components/layout/header/new-primary-header';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import { List, ListItem, Link } from '@mui/material';
import './community-guidelines.scss'

interface Props {
  window?: () => Window;
}
const CommunityGuidelines = () => {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [routeBack, setRouteBack] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  const isBackHome = location.state?.isBackHome;
  const [htmlContent, setHtmlContent] = useState('');
  // const isBackHome = true;
  const fetchAboutUs = async () => {
    try {
      const response = await axios.get<any>(`api/staticContent/community-guidelines`);
      console.log('community-guidelines', response);
      setHtmlContent(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);
  useEffect(() => {
    if (isBackHome) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', e => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };
  const handleAccordionClick = (category: any, currentState: any) => {
    setCategory(category);
  };
  const handleNavigationArrowButton = () => {
    if (fieldsChanged) {
      setRouteBack(true);
    } else if (isBackHome) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };
  const StyledList = styled(List)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column', // Change to column for better vertical alignment
    padding: 0,
    margin: 0,
  }));

  const StyledListItem = styled(ListItem)(({ theme }) => ({
    padding: 0,
  }));

  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: '#4351f1',
    '&:hover': {
      textDecoration: 'underline',
    },
  }));

  const handleButtonFeatureClicked = () => { };

  // const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box>
      <NewPrimarySearchAppBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          margin: 'auto',
          overflowY: 'scroll',
          height: '100vh',
          paddingBottom: '120px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Card style={{ background: isDarkMode ? '#2c2c38' : '' }}>
          <Box sx={{ p: 4 }}>
            <Box sx={{ my: 3 }}>
              <Typography variant="h5" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Community Guidelines
              </Typography>
              <Typography variant="subtitle1" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Welcome to ReachAMY Inc.! Our community is built on respect, collaboration, and a shared commitment to making a positive
                impact. To ensure a safe and supportive environment for all users, we have established these Community Guidelines. By using
                our platform, you agree to abide by these guidelines:
              </Typography>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  1. Respect and Kindness
                </AccordionSummary>
                <AccordionDetails>
                  Treat all members of the community with respect and kindness. Avoid harassment, bullying, discrimination, or any form of
                  hate speech based on race, ethnicity, gender, religion, sexual orientation, disability, or any other characteristic.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  2. Integrity and Authenticity
                </AccordionSummary>
                <AccordionDetails>
                  Provide accurate information and represent yourself truthfully. Do not impersonate others or mislead users about your
                  identity or intentions
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  3. Safety and Privacy
                </AccordionSummary>
                <AccordionDetails>
                  Respect the privacy and personal information of others. Do not share personal information about yourself or others without
                  permission. Exercise caution when sharing content or engaging in discussions that may impact the safety or privacy of
                  individuals or groups.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  4. Content Guidelines
                </AccordionSummary>
                <AccordionDetails>
                  Ensure that all content shared on ReachAMY Inc. complies with our Terms of Service and does not violate any laws or
                  regulations. Do not post content that is defamatory, fraudulent, deceptive, obscene, offensive, or infringes on
                  intellectual property rights.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  5. Community Contributions
                </AccordionSummary>
                <AccordionDetails>
                  Contribute positively to the community by participating in meaningful discussions, sharing relevant information, and
                  supporting others in their endeavors. Avoid spamming, excessive self-promotion, or disruptive behavior.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  6. Reporting and Enforcement
                </AccordionSummary>
                <AccordionDetails>
                  If you encounter behavior that violates these Community Guidelines or our Terms of Service, please report it to us
                  promptly. We will investigate reports thoroughly and take appropriate action, which may include removing content,
                  suspending accounts, or other measures as necessary.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  7. Compliance with Laws
                </AccordionSummary>
                <AccordionDetails>
                  Ensure that your activities and petitions on ReachAMY Inc. comply with applicable laws, regulations, and ethical
                  standards. Do not engage in illegal activities or encourage others to do so.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Accordion style={{ background: isDarkMode ? '#2c2c38' : '', boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ fontWeight: '600' }}
                >
                  8. Feedback and Improvement
                </AccordionSummary>
                <AccordionDetails>
                  We welcome feedback and suggestions for improving our platform and community. Please share your ideas constructively and
                  help us create a better experience for all users.
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box sx={{ my: 3 }}>
              <Typography variant="body1" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Consequences of Violation
              </Typography>
              <Typography variant="body2" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Violating these Community Guidelines may result in warnings, temporary or permanent suspension of account privileges, or
                other actions deemed necessary by ReachAMY Inc. We reserve the right to enforce these guidelines at our discretion to
                maintain a positive and safe community environment. Users, Activities & Petitions that are reported for not meeting these
                guidelines will be promptly reviewed and may result in removal. However, if additional information is provided that
                demonstrates compliance with our guidelines, the decision may be reconsidered, and the content or account may be reinstated.
              </Typography>
            </Box>
            <Box sx={{ my: 3 }}>
              <Typography variant="body1" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                Contact Us
              </Typography>
              <Typography variant="body2" gutterBottom style={{ color: isDarkMode ? 'white' : '' }}>
                If you have any questions about these Community Guidelines or encounter behavior that you believe violates them, please
                contact us at community@reachamy.com.
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" paragraph>
                <strong>Other Relevant Links :</strong>
              </Typography>

              <StyledList sx={{ display: 'flex', flexDirection: 'row' }} className='mob-community'>
                <StyledListItem>
                  <StyledLink sx={{ color: '674bff' }} onClick={() => navigate('/community-guidelines')}>
                    Community Guidelines
                  </StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink sx={{ color: '674bff' }} onClick={() => navigate('/privacy-policy')}>
                    Privacy Policy
                  </StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink sx={{ color: '674bff' }} onClick={() => navigate('/cookie-policy')}>
                    Cookie Policy
                  </StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink sx={{ color: '674bff' }} onClick={() => navigate('/legal-notices')}>
                    Legal Notices
                  </StyledLink>
                </StyledListItem>
                <StyledListItem>
                  <StyledLink sx={{ color: '674bff' }} onClick={() => navigate('/terms-and-condition')}>
                    Terms and Conditions
                  </StyledLink>
                </StyledListItem>
              </StyledList>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default CommunityGuidelines;
