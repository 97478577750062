import * as Yup from 'yup';

// validation schema
export const signupSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Your First Name is required.')
    .min(1, 'Your First Name is required to be at least 1 character.')
    .max(50, 'Your First Name cannot be longer than 50 characters.')
    .matches(/^[a-zA-Z]+$/, 'First Name cannot contain numbers or special characters'),
  lastName: Yup.string()
    .required('Your Last Name is required.')
    .min(1, 'Your Last Name is required to be at least 1 character.')
    .max(50, 'Your Last Name cannot be longer than 50 characters.')
    .matches(/^[a-zA-Z]+$/, 'Last Name cannot contain numbers or special characters'),
  email: Yup.string()
    .required('Your email is required.')
    .min(5, 'Your email is required to be at least 5 characters.')
    .max(254, 'Your email cannot be longer than 254 characters.')
    .email('Your email is invalid.'),
  password: Yup.string()
    .required('Your password is required.')
    .min(8, 'Your password is required to be at least 8 characters.')
    .max(50, 'Your password cannot be longer than 50 characters.')
    .matches(
      /^(?!\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
      'Password must contain at least one lowercase letter, one uppercase letter, one digit,one special character and no white spaces .'
    ),
  confirmPassword: Yup.string()
    .required('Your confirmation password is required.')
    .min(4, 'Your confirmation password is required to be at least 4 characters.')
    .max(50, 'Your confirmation password cannot be longer than 50 characters.')
    .oneOf([Yup.ref('password')], 'The password and its confirmation do not match!'),
});
