import { useAppSelector } from 'app/config/store';
import React, { useState, useEffect } from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow, OverlayView } from '@react-google-maps/api';
import { createSearchParams, Link, useNavigate } from 'react-router-dom';

import { apiKeys } from 'app/utils/data/constants/api-keys';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import $ from 'jquery';
import './map-events-page.scss';
import axios from 'axios';
import { BorderColor } from '@mui/icons-material';

interface NearMeEventsMap {
  latitude: number;
  longitude: number;
  width?: number | string;
  height?: number | string;
  zoom?: number;
  data?: any;
  open?: any;
  onClose: (data?: any) => void;
  fullScreen: boolean;
}

const InfoWindowComponent = ({ markerData, backgroundColor }) => {
  const infoWindowStyle = {
    backgroundColor: backgroundColor,
    borderRadius: '5px',
    width: '100px',
    height: '28px',
    border: '1px solid black',
  };
  return (
    <InfoWindow
      position={{
        lat: Number(markerData?.latitude),
        lng: Number(markerData?.longitude),
      }}
    >
      <div className="pt-1 ps-2" style={infoWindowStyle}>
        <Typography sx={{ fontSize: '10px' }}>
          {markerData.eventType === 'Activity' ? (
            <Link
              to={`/activity/${markerData.id}`}
              style={{
                textDecoration: 'none',
                fontSize: '14px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                maxWidth: '100%',
              }}
            >
              {markerData.title?.split(' ').length > 5 ? `${markerData.title?.split(' ')?.slice(0, 5)?.join(' ')}...` : markerData.title}
            </Link>
          ) : (
            <Link
              to={`/petition/${markerData.id}`}
              style={{
                textDecoration: 'none',
                fontSize: '14px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                maxWidth: '100%',
              }}
            >
              {markerData.title?.split(' ').length > 5 ? `${markerData.title?.split(' ')?.slice(0, 5)?.join(' ')}...` : markerData.title}
            </Link>
          )}
        </Typography>
      </div>
    </InfoWindow>
  );
};

const renderBgColor = (category: any) => {
  switch (category) {
    case 'LATEST':
      return '#F9CB00';
    case 'UPCOMING':
      return '#DC031E';
    case 'ONGOING':
      return '#22e1d3';
    case 'COMPLETED':
      return '#787EFE';
  }
};

const CustomMarkerComponent = ({ timelineCategory, eventType, id, text }) => (
  <Link
    className="link-container"
    to={eventType === 'Activity' ? `/activity/${id}` : `/petition/${id}`}
    style={{
      position: 'relative',
      background: renderBgColor(timelineCategory),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #fff',
      width: 40,
      height: 40,
      borderRadius: '50%',
    }}
  >
    <img style={{ display: 'block', width: '66%' }} src="content/images/logos/app-icon.png" alt="App Icon" />
    <Box className="hover-box" position="absolute">
      <Typography component="span" variant="body2">
        {text?.split(' ').length > 5 ? `${text?.split(' ')?.slice(0, 5)?.join(' ')}...` : text}
      </Typography>
    </Box>
  </Link>
);

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const MapEventsPage = () => {
  const [isLocationLoaded, setIsLocationLoaded] = useState(false);
  const [location, setLocation] = useState({ lat: 0, lng: 0 });
  useEffect(() => {
    const handleLocationSuccess = position => {
      console.log('location sucesss');
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLocation({ lat: latitude, lng: longitude });
      localStorage.setItem('currentLatitude', JSON.stringify(position.coords.latitude));
      localStorage.setItem('currentLongitude', JSON.stringify(position.coords.longitude));

      localStorage.setItem('latitude', latitude.toString());
      localStorage.setItem('longitude', longitude.toString());
    };

    const handleLocationError = error => {
      console.log('location denied');
      let errorMessage =
        'Location Service message: Geo-location based services will no longer be accessible to you. Please enable location services if you wish to use these features.';
      if (error.code === error.PERMISSION_DENIED) {
        errorMessage =
          'You have denied access to location services. Please enable location services in your browser settings to use geo-location based features.';
      } else if (error.code === error.POSITION_UNAVAILABLE) {
        errorMessage = 'Location information is unavailable. Please check your device settings and try again.';
      } else if (error.code === error.TIMEOUT) {
        errorMessage = 'The request to get your location timed out. Please try again.';
      } else if (error.code === error.UNKNOWN_ERROR) {
        errorMessage = 'An unknown error occurred while trying to access your location.';
      }
      alert(errorMessage);
    };

    if ('geolocation' in navigator) {
      console.log('location get');
      setIsLocationLoaded(true);
      navigator.geolocation.getCurrentPosition(handleLocationSuccess, handleLocationError);
    } else {
      console.log('location denied');
      alert(
        'Location Service message: Geo-location based services will no longer be accessible to you. Please enable location services if you wish to use these features.'
      );
    }
  }, [isLocationLoaded]);
  const activities = useAppSelector(state => state.filters.mapResults);
  const [, forceUpdate] = useState(0);
  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);

  useEffect(() => {
    forceUpdate(n => n + 1);
  }, [activities]);

  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: apiKeys.GMAP_API_Key,
  });

  const navigate = useNavigate();
  const zoom = 12;
  const width = '100%';
  const height = '100vh';
  const center = {
    lat: parseFloat(JSON.parse(localStorage.getItem('latitude'))),
    lng: parseFloat(JSON.parse(localStorage.getItem('longitude'))),
  };
  const [selectedMarker, setSelectedMarker] = useState(null);

  const handleCloseButtonClick = () => {
    navigate('/', { state: { activeMenu: 'nearme' } });
  };

  useEffect(() => {
    if (isLoaded) {
      $('.gm-style-iw').prev('div').remove();
    }
  }, [isLoaded]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const mapContainerStyle = {
    width: width,
    height: height,
    borderRadius: '16px',
  };

  const labels = [
    {
      id: 1,
      name: 'Latest',
      color: '#F9CB00',
    },
    {
      id: 2,
      name: 'Upcoming',
      color: '#DC031E',
    },
    {
      id: 3,
      name: 'Ongoing',
      color: '#22e1d3',
    },
    {
      id: 4,
      name: 'Completed',
      color: '#787EFE',
    },
  ];

  const filteredActivities = selectedLabel
    ? activities.filter(activity => activity.timelineCategory === selectedLabel.toUpperCase())
    : activities;

  return (
    <div className="mx-4 py-2" style={{ height: '100vh', scrollbarWidth: 'none' }}>
      <div className="d-flex justify-content-start">
        <div onClick={handleCloseButtonClick}>
          <ArrowCircleLeftIcon
            sx={{
              cursor: 'pointer',
              color: '#FFCE00',
              borderRadius: '50%',
              fontSize: '25px',
            }}
          />
        </div>
      </div>
      <GoogleMap zoom={zoom} center={location} mapContainerStyle={mapContainerStyle}>
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          position="absolute"
          width={166}
          bottom={50}
          left={16}
          bgcolor="#fff"
          p={2.4}
          borderRadius={2}
        >
          {labels.map(label => (
            <Box
              key={label.id}
              display="flex"
              alignItems="center"
              gap={1.2}
              sx={{ cursor: 'pointer' }}
              onClick={() => setSelectedLabel(label.name)}
            >
              <Box bgcolor={label.color} height={16} width={16} borderRadius="1px"></Box>
              <Typography component="span" variant="body2">
                {label.name}
              </Typography>
            </Box>
          ))}
        </Box>
        <Marker
          position={{
            lat: parseFloat(localStorage.getItem('latitude')),
            lng: parseFloat(localStorage.getItem('longitude')),
          }}
        />
        {filteredActivities.map((markerData, index) => (
          <OverlayView
            key={index}
            position={{
              lat: parseFloat(markerData.latitude),
              lng: parseFloat(markerData.longitude),
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
          >
            <CustomMarkerComponent
              timelineCategory={markerData.timelineCategory}
              eventType={markerData.eventType}
              id={markerData.id}
              text={markerData.title}
            />
          </OverlayView>
        ))}
      </GoogleMap>
    </div>
  );
};

export default MapEventsPage;
