import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession, setAuthentication } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { Toaster } from 'react-hot-toast';
import { useTheme } from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';
import { getFirebaseToken, onForegroundMessage } from './firebase';
import AppRoutes from 'app/routes';
import { ToastContainer } from 'react-toastify';
import { getToken, onMessage } from 'firebase/messaging';
import { getUserPreferencesData } from './shared/reducers/user-preferences';
import { Storage } from 'react-jhipster';
import Cookies from 'js-cookie';
import { getPhoneNumber } from './shared/reducers/phone-verification';
import { HelmetProvider } from 'react-helmet-async';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');
export const App = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  // const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isDarkMode = theme.palette.mode === 'dark';
  const myCookieValue = Cookies.get('jhi-authenticationToken');
  const authenticationToken = myCookieValue;
  const isAuthenticated = !!authenticationToken;

  useEffect(() => {
    dispatch(setAuthentication());
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getSession());
      dispatch(getProfile());
      // dispatch(getUserPreferencesData());
      //  dispatch(getPhoneNumber());
    }
  }, [isAuthenticated]);

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then(firebaseToken => {
        Storage.local.set('firebaseToken', firebaseToken);
      })
      .catch(err => console.error('An error occured while retrieving firebase token. ', err));
  };

  return (
    <BrowserRouter basename={baseHref}>
      <AppRoutes />
      <Toaster
        toastOptions={{
          style: {
            background: isDarkMode ? '#2C2C38' : '#FFFFFF',
            color: isDarkMode ? '#FFFFFF' : '#000000',
          },
        }}
      />
    </BrowserRouter>
  );
};

export default App;
