import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';
import { useAppDispatch } from 'app/config/store';
import { deleteOrganization, setSelectedOrgId } from 'app/shared/reducers/settings/settings-organization';
import { fetchUserGrantedOrganizations } from 'app/shared/reducers/organization';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoaderIcon } from 'react-hot-toast';

type Props = {
  open: boolean;
  setAlertOpen: Dispatch<SetStateAction<boolean>>;
  title?: string;
  description?: string;
  agreeAction: () => void;
  okayButtonText?: string;
  closeButtonText?: string;
  paymentLoading?: boolean;
};

export default function AlertDialog({
  title,
  description,
  open,
  setAlertOpen,
  agreeAction,
  okayButtonText,
  closeButtonText,
  paymentLoading,
}: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleClose = (event: React.MouseEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ fontWeight: '400', fontSize: '1 rem', color: isDarkMode ? '#FFFFFF' : '#000' }}
          >
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingRight: '24px;' }}>
          {closeButtonText && (
            <Button
              onClick={() => setAlertOpen(false)}
              sx={{
                width: 'fit-content',
                color: 'primary.light',
                borderRadius: 1.875,
                border: '1px solid #674BFF',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                  border: '1px solid #674BFF  ',
                },
              }}
            >
              {closeButtonText}
            </Button>
          )}
          <Button
            onClick={agreeAction}
            autoFocus
            endIcon={paymentLoading ? <LoaderIcon color="primary.light" /> : null}
            disabled={paymentLoading}
            sx={{
              color: '#F6F7F8',
              textTransform: 'none',
              borderRadius: 1.875,
              // py: 0.5,
              // px: 3.25,
              border: `1px solid`,
              borderColor: 'primary.light',
              bgcolor: 'primary.light',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'primary.light',
              },
            }}
          >
            {okayButtonText ? okayButtonText : 'Proceed'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
