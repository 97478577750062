import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import { fetchProfile, fetchUnAuthorizedProfile } from 'app/shared/reducers/profile';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import toast from 'react-hot-toast';
import { Button, Card, Grid, useMediaQuery } from '@mui/material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { resetLogin, toggleModalLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import NewPrimarySearchAppBar from 'app/components/layout/header/new-primary-header';
import { Container, Typography, List, ListItem, Link } from '@mui/material';

interface Props {
  window?: () => Window;
}
const CookiePolicy = () => {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [routeBack, setRouteBack] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  const isBackHome = location.state?.isBackHome;
  const [htmlContent, setHtmlContent] = useState('');
  // const isBackHome = true;
  const fetchAboutUs = async () => {
    try {
      const response = await axios.get<any>(`api/staticContent/cookie-policy`);
      console.log('community-guidelines', response);
      setHtmlContent(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);
  useEffect(() => {
    if (isBackHome) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', e => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  // const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box>
      <NewPrimarySearchAppBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          margin: 'auto',
          overflowY: 'scroll',
          height: '100vh',
          paddingBottom: '120px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Card style={{ background: isDarkMode ? '#2c2c38' : '' }}>
          <Container maxWidth="md" sx={{ paddingY: 4 }}>
            <Typography variant="h4" gutterBottom>
              Cookie Policy
            </Typography>
            <Typography variant="body1" paragraph>
              Last updated: July 30, 2024
            </Typography>

            <Typography variant="h5" gutterBottom>
              1. Introduction
            </Typography>
            <List>
              <ListItem>
                1.1. This Cookie Policy explains how ReachAMY Inc. ("we", "us", "our") uses cookies and similar technologies to collect and
                store information when You visit our website or use our mobile application (collectively, the "Platform"). By using the
                Platform, You consent to the use of cookies in accordance with this Cookie Policy.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              2. What are Cookies?
            </Typography>
            <List>
              <ListItem>
                2.1. Cookies are small text files that are stored on Your device (computer, smartphone, tablet) when You visit a website or
                use an application. Cookies are widely used to make websites and applications work more efficiently and to provide
                information to the website or application owners.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              3. Types of Cookies We Use
            </Typography>
            <List>
              <ListItem>
                3.1. Essential Cookies These cookies are necessary for the operation of the Platform. They enable basic functions such as
                page navigation, security, and access to certain areas of the Platform. The Platform cannot function properly without these
                cookies.
              </ListItem>
              <ListItem>
                3.2. Analytics Cookies: These cookies allow us to recognize and count the number of visitors to the Platform and to see how
                visitors move around when using it. This helps us improve the way the Platform works, for example, by ensuring that users
                find what they are looking for easily.
              </ListItem>
              <ListItem>
                3.3. Functionality Cookies: These cookies are used to recognize You when You return to the Platform. This enables us to
                personalize our content for You, greet You by name, and remember Your preferences (for example, Your choice of language or
                region).
              </ListItem>
              <ListItem>
                3.4. Targeting Cookies:These cookies record Your visit to the Platform, the pages You have visited, and the links You have
                followed. We use this information to make the Platform and the advertising displayed on it more relevant to Your interests.
                We may also share this information with third parties for this purpose.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              4. How We Use Cookies
            </Typography>
            <List>
              <ListItem>4.1. We use cookies to:</ListItem>
            </List>
            <List>
              <ListItem>Operate and improve the functionality of the Platform.</ListItem>
              <ListItem>Analyze how the Platform is used and to enhance user experience.</ListItem>
              <ListItem>Remember Your preferences and personalize content for You.</ListItem>
              <ListItem>Provide personalized advertising and measure the effectiveness of advertising campaigns.</ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              5. Your Consent
            </Typography>
            <List>
              <ListItem>
                5.1. By using the Platform, You consent to the placement of cookies on Your device as described in this Cookie Policy. You
                can control and manage cookies in various ways. Please refer to the section below on "Managing Cookies" for more
                information.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              6. Managing Cookies
            </Typography>
            <List>
              <ListItem>
                6.1. Most web browsers allow You to control cookies through their settings preferences. However, if You limit the ability of
                websites to set cookies, You may worsen Your overall user experience, since it will no longer be personalized to You. It may
                also stop You from saving customized settings like login information.
              </ListItem>
            </List>
            {/* <List>
              <ListItem>
                <span>
                  6.2. You can manage Your cookie preferences directly within Your browser settings. To find out more about cookies,
                  including how to see what cookies have been set, visit &nbsp;
                  <Link
                    href="http://www.allaboutcookies.org"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }}
                  >
                    www.allaboutcookies.org
                  </Link>
                  .
                </span>
              </ListItem>
            </List> */}

            <Typography variant="h5" gutterBottom>
              7. CCPA and GDPR Compliance
            </Typography>
            <List>
              <ListItem>
                7.1. CCPA: Under the California Consumer Privacy Act (CCPA), You have the right to opt-out of the sale of Your personal
                information. We do not sell Your personal information through our use of cookies. For more information on how we handle Your
                personal information, please refer to our Privacy Policy.
              </ListItem>
            </List>
            <List>
              <ListItem>
                7.2. GDPR: Under the General Data Protection Regulation (GDPR), we will obtain Your consent before placing non-essential
                cookies on Your device. You have the right to withdraw Your consent at any time. Withdrawal of consent does not affect the
                lawfulness of processing based on consent before its withdrawal.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              8. Changes to This Cookie Policy
            </Typography>
            <List>
              <ListItem>
                8.1. We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or
                for other operational, legal, or regulatory reasons. Please revisit this Cookie Policy regularly to stay informed about our
                use of cookies and related technologies.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              9. Contact Us
            </Typography>
            <List>
              <ListItem>9.1. If You have any questions about our use of cookies or this Cookie Policy, please contact us:</ListItem>
            </List>
            <List>
              <ListItem>
                By email:{' '}
                <Link href="mailto:contact@reachamy.com" sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }}>
                  contact@reachamy.com
                </Link>
              </ListItem>
              {/* <ListItem>
                By visiting this page on our website:{' '}
                <Link
                  href="https://www.reachamy.com/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }}
                >
                  www.reachamy.com/privacy-policy
                </Link>
              </ListItem> */}
            </List>
          </Container>
        </Card>
      </Box>
    </Box>
  );
};

export default CookiePolicy;
