// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rom-timepicker {
  border-radius: 15px;
  height: 45px;
  border: 1px solid #bfbfbf;
  padding: 7.5px 14px !important;
}

.rom-timepickerdark {
  border-radius: 15px;
  background: #2c2c38;
  height: 45px;
  color: #f6f7f8;
  font-size: 14px;
  border: 1px solid #bfbfbf;
  width: -webkit-fill-available !important;
  padding: 7.5px 14px !important;
}

input.rom-timepicker {
  width: -webkit-fill-available !important;
}

.react-datepicker-wrapper {
  width: -webkit-fill-available !important;
}

.react-datepicker__time-container {
  width: 147px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #674bff;
  color: white;
  font-weight: none !important;
  font-size: 16px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  font-size: 16px !important;
  font-weight: none !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: #676bff !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/components/ui/settings/settings-body/settings-panels/settings-profile/settings-profile-preferred-time/settings-profile-prof.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,8BAAA;AACF;;AAEA;EACE,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,yBAAA;EACA,wCAAA;EACA,8BAAA;AACF;;AAEA;EACE,wCAAA;AACF;;AAGA;EACE,wCAAA;AAAF;;AAGA;EACE,YAAA;AAAF;;AAGA;EACE,yBAAA;EACA,YAAA;EACA,4BAAA;EACA,eAAA;AAAF;;AAGA;EACE,0BAAA;EACA,4BAAA;AAAF;;AAGA;EACE,oCAAA;AAAF","sourcesContent":[".rom-timepicker {\n  border-radius: 15px;\n  height: 45px;\n  border: 1px solid #bfbfbf;\n  padding: 7.5px 14px !important;\n}\n\n.rom-timepickerdark {\n  border-radius: 15px;\n  background: #2c2c38;\n  height: 45px;\n  color: #f6f7f8;\n  font-size: 14px;\n  border: 1px solid #bfbfbf;\n  width: -webkit-fill-available !important;\n  padding: 7.5px 14px !important;\n}\n\ninput.rom-timepicker {\n  width: -webkit-fill-available !important;\n\n}\n\n.react-datepicker-wrapper {\n  width: -webkit-fill-available !important;\n}\n\n.react-datepicker__time-container {\n  width: 147px;\n}\n\n.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {\n  background-color: #674bff;\n  color: white;\n  font-weight: none !important;\n  font-size: 16px;\n}\n\n.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {\n  font-size: 16px !important;\n  font-weight: none !important;\n}\n\n.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {\n  background-color: #676bff !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
