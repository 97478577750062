import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Typography,
  Grid,
  Button,
  useTheme,
  Paper,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  IconButton,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { postResourceDetails } from 'app/shared/reducers/activity-home';
import { Close as CloseIcon } from '@mui/icons-material';
import './activity-contribute.scss';

const ActivityContribute = ({ open, handleClose, handleData, onPostClick }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [isPostButtonDisabled, setIsPostButtonDisabled] = useState(false);
  const [loadResource, setLoadResource] = useState([]);
  const userDetails = useAppSelector(state => state.authentication.account);

  const handleChange = (event, index, resource) => {
    let value = event.target.value.trim();
    value = value.slice(0, 10);
    const parsedValue = value !== '' ? parseFloat(value) : null;
    const updatedLoadResource = [...loadResource];
    const indexer = updatedLoadResource?.findIndex(item => item?.id === resource?.id);

    if (indexer !== -1) {
      updatedLoadResource[indexer] = { ...updatedLoadResource[indexer], filled: parsedValue };
    } else {
      updatedLoadResource?.push({
        ...resource,
        filled: parsedValue,
      });
    }

    setLoadResource(updatedLoadResource);

    const hasNonZeroValue = updatedLoadResource?.some(contribution => contribution.filled !== 0 && contribution.filled !== null);
    setIsPostButtonDisabled(hasNonZeroValue);
  };

  const handlePostClick = () => {
    const payload = {
      activityId: handleData?.id,
      resourcesNeeded: loadResource,
    };
    const fetchPost = async () => {
      let response;
      try {
        response = await dispatch(postResourceDetails(payload));
      } catch (error) {
        console.log(error);
      }
    };
    fetchPost();

    // Clear the contribution text and close the dialog
    onPostClick();
    handleClose();
  };

  useEffect(() => {
    // Populate resources from participant list on component load
    if (handleData && handleData?.participants) {
      const data = handleData?.participants.filter(participant => participant?.id === userDetails?.id);
      let resources;
      if (data.length > 0) {
        resources = data[0]?.resources;
        setTimeout(() => {
          if (resources) {
            setLoadResource(resources);
          }
        }, 1000);
      } else {
        resources = [];
        setLoadResource([]);
      }
    }
  }, [handleData]);

  useEffect(() => {
    // setLoadResource([])
  }, [handleClose]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth={false}
      sx={{ borderRadius: '15%' }}
      PaperProps={{
        sx: {
          borderRadius: '15px',
          padding: '2%',
          overflow: 'unset',
          height: '85%',
          background: isDarkMode ? '#2C2C38' : '#FFFFFF',
        },
      }}
    >
      <DialogTitle>
        <Typography
          sx={{
            fontSize: '20px',
            fontFamily: 'Manrope',
            fontWeight: '500',
          }}
        >
          What are you bringing ?
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose} // Close the dialog when the button is clicked
          sx={{
            position: 'absolute',
            top: theme.spacing(1),
            // right: theme.spacing(1),
            right: '20px',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Please let us know what are the things you will be able to bring in for us to arrange this activity ? This will help us better
          organize and plan accordingly.
        </Typography>
        <Paper
          elevation={3}
          className="example-4"
          sx={{
            background: isDarkMode ? '#2C2C38' : '#FFFFFF',
            borderRadius: '16px',
            fontSize: '16px',
            p: 2,
            width: '100%',
            boxShadow: 'none',
            overflowY: handleData?.resources && handleData?.resources.length > 2 ? 'scroll' : 'hidden',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              width: '0',
            },
            height: '50vh',
          }}
        >
          {handleData?.resources?.length ? (
            <List>
              {handleData?.resources?.map((resource, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemText
                    style={{ width: '80%' }}
                    primary={`${index + 1}. ${resource?.resources}`}
                    secondary={
                      <Typography
                        sx={{
                          fontSize: theme.infoText.bold.fontSize,
                          fontWeight: theme.infoText.bold.fontWeight,
                          color: resource.filled >= resource.totalCount ? ' #00B158' : '#FF4B8E',
                        }}
                      >
                        {resource.filled >= resource.totalCount
                          ? `${resource.filled}/${resource.totalCount} Filled `
                          : `${resource.filled}/${resource.totalCount} Filled `}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    {/* Input text box */}
                    <TextField
                      variant="outlined"
                      placeholder="How many"
                      size="small"
                      sx={{
                        width: '100px',
                        '& input': {
                          textAlign: 'center',
                          backgroundColor: isDarkMode ? 'unset' : '',
                          fontSize: '12px',
                          borderRadius: '15px',
                        },
                      }}
                      value={loadResource?.find(item => item.id === resource.id)?.filled}
                      onChange={e => handleChange(e, index, resource)}
                      onKeyDown={e => {
                        // Allow only numbers (0-9) and backspace
                        if (!((e.key >= '0' && e.key <= '9') || e.key === 'Backspace')) {
                          e.preventDefault();
                        }
                      }}
                      inputProps={{
                        maxLength: 8,
                      }}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography
              sx={{
                color: theme.textColor.paragraph,
                fontSize: theme.infoText.regular.fontSize,
                fontWeight: theme.infoText.regular.fontWeight,
                marginLeft: '1rem',
              }}
            >
              No resources listed yet.
            </Typography>
          )}
        </Paper>
      </DialogContent>
      <Grid container justifyContent="flex-end" p={2}>
        <Button
          variant="contained"
          color="primary"
          sx={{ borderRadius: '10%', paddingLeft: '4%', paddingRight: '4%' }}
          onClick={() => handlePostClick()}
          disabled={!isPostButtonDisabled}
        >
          Submit
        </Button>
      </Grid>
    </Dialog>
  );
};

export default ActivityContribute;
