import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { fetchPetitionDetails } from 'app/shared/reducers/activity-home';
import { createPetitionSchema, editPetitionSchema } from 'app/utils/validation-schema/petition-schema';
import EditPetitionMain from './edit-petition-main-sec/edit-petition-main';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import { postingRulesType } from 'app/utils/types/activity/activity-types';
import PostSuccessModal from 'app/components/common/post-success-modal/post-success-modal';
import { fetchUserGrantedOrganizations } from 'app/shared/reducers/organization';
import { clearExistingActivities } from 'app/shared/reducers/activity-filter';

const EditPetition = () => {
  const [petition, setPetition] = useState<any>();
  const [petitionPostingRules, setPetitionPostingRules] = useState<postingRulesType[]>([]);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const petitionId = +id;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [responseUrl, setResponseUrl] = useState<string>('');

  useEffect(() => {
    const fetchActivitiesInfo = async () => {
      try {
        const response = await dispatch(fetchPetitionDetails(petitionId));
        if (response?.payload) {
          setPetition(response?.payload);
        } else {
          console.log('No petition details found.');
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchActivitiesInfo();
  }, []);

  useEffect(() => {
    const getUserGrantedOrganizations = async () => {
      try {
        await dispatch(fetchUserGrantedOrganizations());
      } catch (error) {
        console.log(error);
      }
    };

    getUserGrantedOrganizations();
  }, [dispatch]);

  useEffect(() => {
    const fetchPostingRules = async () => {
      try {
        const response = await axios.get<postingRulesType[]>('api/rules-tables');

        setPetitionPostingRules(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPostingRules();
  }, []);

  const initialValues = {
    categoryID: petition?.category?.id || '',
    title: petition?.title || '',
    details: petition?.details || '',
    postAsOrganisation: petition?.postAsOrganisation,
    urgent: petition?.urgent || false,
    targetSignature: petition?.targetSignature || '',
    multimedia: petition?.eventGallery,
    petitionTo: petition?.petitionTo,
    startDate: petition?.startDate,
    endDate: petition?.endDate,
    latitude: petition?.latitude,
    longitude: petition?.longitude,
    organizationId: Number(petition?.organizationId),
  };

  const handleEditPetitionSubmit = async petition => {
    try {
      const response = await axios.put<any>('api/petition/update/' + petitionId, petition);
      if (response.status === 201) {
        dispatch(clearExistingActivities());

        setResponseUrl(response.data.link);
        setOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: editPetitionSchema,
    onSubmit: (values, { resetForm }) => {
      handleEditPetitionSubmit(values);
    },
  });
  const handleModalClose = () => {
    setOpen(false);
    navigate('/');
  };
  return (
    <div className=" container py-2 mx-3" style={{ height: '100vh', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: 'none' }}>
      <style>
        {`
         ::-webkit-scrollbar {
        width: 0;
        background: transparent; 
      }     
      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
    `}
      </style>
      <div className="row">
        <div className="col-3">
          <ArrowCircleLeftIcon
            onClick={() => navigate('/')}
            sx={{ cursor: 'pointer', color: '#FFCE00', borderRadius: '50%', fontSize: '29px' }}
          />
        </div>
        <div className="col-9">
          <Typography variant="h4" sx={{ color: 'secondary.main' }}>
            Edit Petition
          </Typography>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row mt-5 g-3">
          <div className="col-md-3 col-sm-12 col-10 ">
            <Stack sx={{ backgroundColor: 'secondary.dark', borderRadius: '16px', p: 1 }} gap={1}>
              <Typography
                sx={{
                  color: theme.textColor.mainHeading,
                  fontSize: theme.p1.semiBold.fontSize,
                  fontWeight: theme.p1.semiBold.fontWeight,
                  px: 0.8,
                }}
              >
                Posting Rules
              </Typography>
              <Stack
                direction="column"
                gap={1}
                sx={{
                  maxWidth: { xs: '100%', lg: 265 },
                  borderRadius: '15px',
                  height: '66vh',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {petitionPostingRules?.length
                  ? petitionPostingRules?.map(item => (
                      <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                        <Stack p={0.7}>
                          <Box
                            sx={{
                              width: '9px',
                              height: '9px',
                              borderRadius: '50%',
                              backgroundColor: '#775FFF',
                            }}
                          />
                        </Stack>
                        <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0, lg: 2 } }}>
                          <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.description}</Typography>
                        </Stack>
                      </Stack>
                    ))
                  : null}
              </Stack>
            </Stack>
          </div>
          <div className="col-md-9 col-sm-12 col-10">
            <EditPetitionMain formik={formik} petition={petition} />
          </div>
        </div>
      </form>

      <PostSuccessModal
        handleModalClose={handleModalClose}
        fullScreen={fullScreen}
        open={open}
        onClose={handleModalClose}
        responseUrl={responseUrl}
        message="Your petition was edited successfully"
      />
    </div>
  );
};

export default EditPetition;
