̥import React, { useState } from 'react'
import { Dialog, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { useAppDispatch } from 'app/config/store';
import { useParams } from 'react-router-dom';
import chatEdit, { getChatEdited } from 'app/shared/reducers/chat-messages/chat-edit';
import { toast } from 'react-toastify';

const ChatEdit = ({ open, onClose, handleChat ,onSave}) => {
    const [editedChat, setEditedChat] = useState(handleChat.message);
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const handleSave = async () => {
    onClose();
      const userid2 = JSON.parse(localStorage?.getItem('MyDetailsAccount'))
      const payload = {
               userId: userid2?.id,
               messageId: handleChat?.id,
               eventId: handleChat?.eventId,
               eventType: handleChat?.eventType,
               message: editedChat ? editedChat : ''
              }
    onSave(payload);
//         const payload = {
//           messageId: handleChat?.id,
//           payload: {
//             eventId: handleChat?.eventId,
//             eventType: handleChat?.eventType,
//             message: editedChat ? editedChat : ''
//           },
//         };
//         try {
//           const response = await dispatch(getChatEdited(payload));
//           if (response?.meta?.requestStatus === 'fulfilled') {
//             toast.success(response?.payload?.message);
//             onClose();
//           }
//         } catch (error) {
//           console.error('An error occurred while fetching data:', error);
//         }
      };


    const handleCancel = () => {
        setEditedChat('');
        onClose();
    };


    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm" fullWidth
        >
            <DialogContent>
                <TextField
                    multiline
                    rows={4}
                    variant="outlined"
                    label="Edit the message"
                    fullWidth
                    defaultValue={handleChat.message}
                    onChange={(e) => setEditedChat(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Cancel
                </Button>
          <Button onClick={handleSave} color="primary" disabled={!editedChat?.trim()}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default ChatEdit
