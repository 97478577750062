import React, { useState, useEffect } from 'react';
import { Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WelcomeUser from '../welcome-user/welcome-user';
import AnimalRescueIcon from 'app/components/icons/side-bar-icons/animal-rescue-icon';
import EnvironmentIcon from 'app/components/icons/side-bar-icons/environment-icon';
import HealthIcon from 'app/components/icons/side-bar-icons/health-icon';
import EventsIcon from 'app/components/icons/side-bar-icons/events-icon';
import PetitionsIcon from 'app/components/icons/side-bar-icons/petitions-icon';
import PrivateIcon from 'app/components/icons/side-bar-icons/private-icon';
import OthersIcon from 'app/components/icons/side-bar-icons/others-icon';
import { useAppDispatch } from 'app/config/store';
import { Storage } from 'react-jhipster';
import { fetchCategories } from 'app/shared/reducers/categories';

export interface ItemData {
  id: number;
  category: string;
}

const iconMapping = {
  ANIMAL_RESCUE: <AnimalRescueIcon />,
  ENVIRONMENT: <EnvironmentIcon />,
  HEALTH: <HealthIcon />,
  EVENTS: <EventsIcon />,
  PETITIONS: <PetitionsIcon />,
  PRIVATE: <PrivateIcon />,
  Others: <OthersIcon />,
};

const PickYourInterests = () => {
  const matchesXs = useMediaQuery('(max-width:500px)');
  const [categories, setCategories] = useState<any>([]);
  const [selected, setSelected] = useState<ItemData[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleItemClick = (item: ItemData) => {
    const isSelected = selected.some(element => element.category === item.category && element.id === item.id);

    if (isSelected) {
      setSelected(prevSelected => prevSelected.filter(element => element.id !== item.id));
    } else {
      setSelected(prevSelected => [...prevSelected, item]);
    }
  };

  useEffect(() => {
    if (Storage.session.get('interests')) {
      setSelected(JSON.parse(Storage.session.get('interests')));
    }
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCategories());
        setCategories(response.payload);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const buttonStyle = {
    p: 1,
    lineHeight: '1.2',
    boxShadow: 'none',
    whiteSpace: 'wrap',
    borderRadius: '32px',
    flexDirection: matchesXs ? 'column' : 'row',
    height: matchesXs ? '95px' : '45px',
    margin: '5px',
    '&:hover': {
      color: '#E4E4E4',
      backgroundColor: '#674BFF',
      boxShadow: 'none',
    },
  };
  const renderButton = (item: ItemData, index: number) => {
    return (
      <Grid item xs={12} sm={6} md={3} container justifyContent="center" key={item.category + index} sx={{ pl: 2, pt: 2 }}>
        <Button
          variant="contained"
          sx={
            matchesXs
              ? {
                  fontSize: '12px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: selected.some(element => element.category === item.category && element.id === item.id)
                    ? 'primary.light'
                    : '#E4E4E4',
                  width: matchesXs ? '95px' : '150px',
                  ...buttonStyle,
                }
              : {
                  backgroundColor: selected.some(element => element.category === item.category && element.id === item.id)
                    ? 'primary.light'
                    : '#E4E4E4',
                  width: matchesXs ? '95px' : '180px',
                  ...buttonStyle,
                }
          }
          onClick={() => handleItemClick(item)}
          startIcon={iconMapping[item.category]}
        >
          {item.category}
        </Button>
      </Grid>
    );
  };

  return (
    <div style={{ height: '100vh', overflow: 'auto', paddingTop: '40px' }}>
      {<WelcomeUser />}

      <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
        <Typography
          textAlign={'center'}
          sx={
            matchesXs
              ? { fontSize: '20px', fontWeight: 'normal', paddingTop: 1, paddingLeft: 1 }
              : { fontSize: '24px', color: 'secondary.light', fontWeight: 600 }
          }
        >
          Pick your interests so that we can personalise ReachAMY for you
        </Typography>
        <Grid padding={2} sx={{ margin: 'auto', maxWidth: '700px' }}>
          <Grid container justifyContent="center" spacing={3}>
            {categories?.map(renderButton)}
          </Grid>
          <Grid item container alignItems="center" justifyContent={'space-between'} marginTop={5}>
            <Button
              onClick={() => {
                Storage.session.set('interests', JSON.stringify(selected));
                navigate('/availability');
              }}
              variant="outlined"
              sx={{
                textTransform: 'none',
                width: '89px',
                height: '40px',
                borderRadius: '15px',
                border: '1px solid #FFCE00',
                boxShadow: 'none',
                '&:hover': { boxShadow: 'none' },
                margin: '10px',
              }}
            >
              <Typography>Back</Typography>
            </Button>
            <Button
              onClick={() => {
                Storage.session.set('interests', JSON.stringify(selected));
                navigate('/createbio');
              }}
              variant="contained"
              sx={{
                textTransform: 'none',
                width: '89px',
                height: '40px',
                borderRadius: '15px',
                border: '1px solid #FFCE00',
                boxShadow: 'none',
                '&:hover': { backgroundColor: 'primary.main', boxShadow: 'none' },
                margin: '10px',
              }}
            >
              <Typography>Next</Typography>
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default PickYourInterests;
