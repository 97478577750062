import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Box, Fab, Modal, Stack, useTheme } from '@mui/material';
import ProfileLocationIcon from 'app/components/icons/profile/location-icon';
import WaveFormIcon from 'app/components/icons/profile/wave-form-icon';
import OrganisedIcon from 'app/components/icons/profile/organised-icon';
import ModeratedIcon from 'app/components/icons/profile/moderated-icon';
import './profile-details.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ProfileGoals from '../profile-goals/profile-goals';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { fetchCount, fetchImpactReport } from 'app/shared/reducers/profile';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Add from '@mui/icons-material/Add';
import { Download } from '@mui/icons-material';
import toast from 'react-hot-toast';
import LoaderIcon from 'app/components/common/loader-icon/loader-icon';
import { getInitials } from 'app/utils/common-utils/common-utils';
import axios from 'axios';

type ProfileDataProps = {
  profileData: any;
  id: any;
};
const ProfileDetailsSection = ({ profileData, id }: ProfileDataProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showFullText, setShowFullText] = useState(false);
  const [open, setOpen] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get('status');
  useEffect(() => {
    if (id) {
      dispatch(fetchCount({ userId: id, categoryId: null }));
    }
  }, [id]);
  const participatedCountApi = useAppSelector(state => state.profileReducer.participationCount);
  const moderatorCountApi = useAppSelector(state => state.profileReducer.moderatorCount);
  const organizedCountApi = useAppSelector(state => state.profileReducer.organizedCount);
  const profileAchievements = [
    {
      name: 'Participated',
      value: participatedCountApi ?? 0,
      icon: <WaveFormIcon sx={{ fontSize: { xs: 'larger', lg: '16px' } }} />,
    },
    {
      name: 'Organized',
      value: organizedCountApi ?? 0,
      icon: <OrganisedIcon sx={{ fontSize: { xs: 'larger', lg: '16px' } }} />,
    },
    {
      name: 'Moderated',
      value: moderatorCountApi ?? 0,
      icon: <ModeratedIcon sx={{ fontSize: { xs: 'larger', lg: '16px' } }} />,
    },
  ];

  const toggleShowText = () => {
    setShowFullText(!showFullText);
  };
  const handleClick = () => {
    if (JSON.parse(localStorage.getItem('Banned'))) {
      toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
      return;
    }
    navigate(`/create-organization`);
  };
  const stylemodal = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
  };

  const [payload, setPayload] = React.useState<any>();
  const handleGetCatalogue = async id => {
    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        setPayload(response.data);
        // if (response.data) {
        //   handlePaymentSite();
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (status === 'success') {
      downloadImpactReport();
    }
  }, [status]);

  useEffect(() => {
    if (payload?.productId) handlePaymentSite();
  }, [payload?.productId]);
  const handlePaymentSite = async () => {
    let passData = {
      eventId: 0,
      eventType: 5,
      quantity: 1,
      productId: payload?.productId,
    };
    localStorage.setItem('passEventId', JSON.stringify(passData?.eventId));
    localStorage.setItem('passEventType', JSON.stringify(passData?.eventType));
    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl;

          // Open checkout URL in current tab
        } else {
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const downloadImpactReport = async () => {
    setDownloadLoading(true);
    try {
      if (profileData?.activitiesOrganizedCount > 0 || profileData?.participationCount > 0) {
        toast.dismiss();

        const actionResult = await dispatch(fetchImpactReport(id));
        const fileUrl = actionResult.payload;

        if (fileUrl) {
          const response = await fetch(fileUrl);

          if (response.ok) {
            setDownloadLoading(false);
            const blob = await response.blob();
            const blobURL = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobURL;
            link.download = 'Impact Report.pdf';

            link.click();

            setTimeout(() => window.URL.revokeObjectURL(blobURL), 1000);
            toast.dismiss();
            toast.success('Impact report downloaded successfully!');
          } else {
            const errorMessage = await response.text();
            setDownloadLoading(false);
            toast.error(`Error downloading report: ${errorMessage}`);
          }
        } else {
          setDownloadLoading(false);
          toast.error('An error occurred while generating the report');
        }
      } else {
        toast.dismiss();
        setDownloadLoading(false);
        toast.error('Please organize or volunteer in at least one activity to generate a report');
      }
    } catch (error) {
      console.error('Error downloading impact report:', error);
      toast.error('An unexpected error occurred. Please try again later.');
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid
      container
      direction="row"
      alignItems={{ xs: 'center', lg: 'flex-start' }}
      justifyContent={'space-between'}
      sx={{ paddingTop: '15px' }}
    >
      <Grid item container alignItems={'center'} justifyContent={'center'} xs={12} lg={3}>
        <Avatar
          alt={profileData?.postedByProfileImageUrl ? '' : profileData?.fullName ? profileData.fullName[0] : null}
          src={profileData?.postedByProfileImageUrl}
          sx={{
            width: 130,
            height: 130,
            backgroundColor: profileData?.fullName ? '#674bff' : '',
            fontSize: 34,
            color: '#fff',
          }}
        >
          {profileData?.fullName
            ? getInitials(profileData?.fullName)
            : profileData?.deactivatedOrDeleted
            ? getInitials('Inactive User')
            : ''}
        </Avatar>
      </Grid>
      <Grid item container direction="column" xs={12} lg={9} spacing={2.5} className="mt-0">
        <Grid item container direction={'row'} alignItems="center" justifyContent={{ xs: 'center', lg: 'space-between' }}>
          <Grid item xs={12} lg={7}>
            <Typography
              component="h6"
              textAlign={{ xs: 'center', lg: 'left' }}
              fontSize={23}
              color={theme.profile.color}
              fontWeight={600}
              lineHeight={1.3}
              sx={{ wordBreak: ' break-word' }}
            >
              {profileData?.fullName}
            </Typography>
          </Grid>
          <Grid className="btn-mrgtop" item xs={12} lg={5}>
            {profileData?.ownProfile && (
              <>
                <Button
                  fullWidth
                  className="hide-org"
                  variant="contained"
                  sx={{
                    backgroundColor: 'primary.light',
                    color: 'common.white',
                    fontSize: '13px',
                    px: 1,
                    borderRadius: 1,
                    '&:hover': {
                      color: 'common.white',
                      backgroundColor: 'primary.light',
                    },
                  }}
                  onClick={() => handleClick()}
                  startIcon={<Add />}
                >
                  Create Organization
                </Button>
                <Button
                  disableElevation
                  disableRipple
                  size="small"
                  sx={{
                    marginTop: 0.7,
                    marginBottom: 0.7,
                    width: '100%',
                    color: 'primary.light',
                    borderRadius: 1,
                    border: '1px solid #674BFF',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                      border: '1px solid #674BFF  ',
                    },
                    '&.MuiButton-root.Mui-disabled': {
                      color: 'primary.light',
                      border: '1px solid #674BFF',
                    },
                  }}
                  startIcon={<Download />}
                  variant="outlined"
                  // onClick={() => handleGetCatalogue(5)}
                  onClick={() => downloadImpactReport()}
                  endIcon={downloadLoading ? <LoaderIcon color="primary.light" /> : null}
                  disabled={downloadLoading}
                >
                  Impact Report
                </Button>
              </>
            )}

            <Button
              className="show-mile"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: 'primary.light',
                color: 'common.white',
                fontSize: '13px',
                px: 1,
                borderRadius: 1,
                '&:hover': {
                  color: 'common.white',
                  backgroundColor: 'primary.light',
                },
              }}
              onClick={handleOpen}
            >
              Activity Milestone
            </Button>
          </Grid>
        </Grid>
        {profileData?.location && (
          <Grid item container spacing={1} alignItems={'center'} m={0}>
            <Grid item style={{ paddingLeft: '0px', paddingTop: '0' }}>
              {profileData?.location && <ProfileLocationIcon sx={{ fontSize: 18 }} />}
            </Grid>
            <Grid item>
              <Typography fontWeight={600} color={theme.profile.secondary}>
                {profileData.city}
                {profileData.city && profileData.location ? ',' : ''} {profileData.location}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item className="pt-2 bio">
          <Typography color={theme.profile.color} fontSize={14}>
            {showFullText ? (
              <>
                {profileData?.bio}
                <span style={{ cursor: 'pointer', color: '#674BFF', fontSize: 13 }} onClick={toggleShowText}>
                  ...Less
                </span>
              </>
            ) : (
              <>
                {profileData?.bio?.slice(0, 140)}
                {profileData?.bio?.length > 140 && (
                  <span style={{ cursor: 'pointer', color: '#674BFF', fontSize: 13 }} onClick={toggleShowText}>
                    ...More
                  </span>
                )}
              </>
            )}
          </Typography>
        </Grid>

        {profileData?.visibilityType !== 'Private' && !profileData?.ownProfile && (
          <Grid item container direction="row" alignItems={'center'} justifyContent={{ xs: 'center', lg: 'space-between' }}>
            {profileAchievements?.map(data => (
              <Grid item xs={4} md={6} lg={4} key={data?.name} sx={{ p: { xs: 0.1, lg: 1 } }}>
                <Stack direction="row" spacing={1}>
                  {data?.icon}
                  <Stack direction="column" spacing={0.5}>
                    <Typography className="fontsmall" color={theme.profile.color} fontSize={15}>
                      {data?.name}
                    </Typography>
                    <Typography className="fontsmall" color={theme.profile.color} fontWeight={600}>
                      {data?.value}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        )}
        {profileData?.ownProfile && (
          <Grid item container direction="row" alignItems={'center'} justifyContent={{ xs: 'center', lg: 'space-between' }}>
            {profileAchievements?.map(data => (
              <Grid item xs={4} md={6} lg={4} key={data?.name} sx={{ p: { xs: 0.1, lg: 1 } }}>
                <Stack direction="row" spacing={1}>
                  {data?.icon}
                  <Stack direction="column" spacing={0.5}>
                    <Typography className="fontsmall" color={theme.profile.color} fontSize={15}>
                      {data?.name}
                    </Typography>
                    <Typography className="fontsmall" color={theme.profile.color} fontWeight={600}>
                      {data?.value}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      {profileData?.ownProfile && (
        <Box className="fixed-button2">
          <Fab
            size="large"
            color="secondary"
            aria-label="add"
            onClick={() => handleClick()}
            sx={{
              marginTop: '6px',
              textTransform: 'capitalize',
              backgroundColor: 'primary.light',
              color: 'common.white',
              '&:hover': {
                color: 'common.white',
                backgroundColor: 'primary.light',
                textTransform: 'capitalize',
                boxShadow: 'none',
              },
            }}
          >
            <AddIcon />
          </Fab>
        </Box>
      )}

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={stylemodal}>
          <CloseOutlinedIcon onClick={handleClose} sx={{ float: 'right' }} />
          <ProfileGoals id={id} />
        </Box>
      </Modal>
    </Grid>
  );
};

export default ProfileDetailsSection;
