import { useAppSelector } from 'app/config/store';
import React, { useState, useEffect, useRef } from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enUS from 'date-fns/locale/en-US';
import { useNavigate } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CloseIcon from '@mui/icons-material/Close';
import { Card } from 'reactstrap';
import { Scheduler } from '@aldabil/react-scheduler';
import { IconButton, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import './event-calender-page.scss';
import axios from 'axios';

const EventCalenderPage = () => {
  // const activities = useAppSelector(state => state.filters.activities);
  const [activities, setActivities] = useState<any[]>([]);
  const [mappedEvents, setMappedEvents] = useState<any[]>([]);
  const activitiesBody = useAppSelector(state => state.filters.RequestBody.filter);
  const theme = useTheme();
  const navigate = useNavigate();
  const schedulerRef = useRef(null);
  const scrollToCurrentTime = () => {
    const currentCell = schedulerRef.current && schedulerRef.current.querySelector('.css-72565a');
    if (currentCell) {
      const topOffset = currentCell.getBoundingClientRect().top;
      const roundedTopOffset = Math.floor(topOffset);
      schedulerRef.current.scrollTo({ top: roundedTopOffset - 100, left: 50, behavior: 'smooth' });
    }
  };

  const fetchActivitiesData = async (id: any) => {
    try {
      // Construct the URL based on whether id is null or not
      let url = 'api/home/activities';
      if (id !== null) {
        url += `?organizationId=${id}`;
      }

      const response = await axios.get<any>(url);
      if (response?.data) {
        setActivities(response?.data);
      }
    } catch (error) {
      console.log('Error fetching activities:', error);
    }
  };
  useEffect(() => {
    const organizationId = activitiesBody?.organizationId !== null ? activitiesBody.organizationId : null;
    fetchActivitiesData(organizationId);
  }, [activitiesBody]);

  useEffect(() => {
    scrollToCurrentTime();
  }, []);
  const locales = {
    'en-US': enUS,
  };
  const isDarkMode = theme.palette.mode === 'dark';
  const handleCloseButtonClick = () => {
    navigate('/');
  };
  useEffect(() => {
    if (activities?.length > 0) {
      const newMappedEvents = activities
        // .filter(item => item.lostPet === null)
        .map(item => {
          const startDate = item?.startDate?.split('T')[0];
          const startTime = item?.activityStartTime?.split('T')[1] || '16:39:37.147+05:30';
          const endTime = item?.activityEndTime?.split('T')[1] || '16:50:37.147+05:30';

          const start = startDate + (item?.activityStartTime ? 'T' + startTime : '');
          const end = startDate + (item?.activityEndTime ? 'T' + endTime : '');

          // Check if end date is less than start date
          const endDate = end < start ? start : end;
          return {
            event_id: item?.id,
            title: item?.title,
            start: new Date(start),
            end: new Date(endDate),
            editable: false,
            deletable: false,
            type: item?.eventType,
            color: theme.palette.mode === 'dark' ? '#FFCE00' : '#674BFF',
          };
        })
        .filter(item => item.type === 'Activity');

      setMappedEvents(newMappedEvents);
    }
  }, [activities?.length > 0]);

  const handleEventClick = eventClickInfo => {
    const eventId = eventClickInfo?.event_id;
    const typeID = eventClickInfo?.type;
    if (eventId && typeID) {
      if (typeID === 'Activity') {
        navigate(`/activity/${eventId}`);
      } else {
        navigate(`/petition/${eventId}`);
      }
    }
  };
  const customTheme = {
    header: { backgroundColor: '#fff', textColor: '#212121', borderBottom: '1px solid #ccc' },
    weekHeader: { backgroundColor: '#fff', textColor: '#212121', borderColor: '#ccc', fontWeight: 'bold' },
    day: {
      backgroundColor: '#fff',
      textColor: '#212121',
      borderColor: '#ccc',
      textTransform: 'uppercase',
      fontSize: '0.8rem',
      fontWeight: 'bold',
    },
    month: {
      backgroundColor: '#fff',
      textColor: '#FF0000',
      borderColor: '#ccc',
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontWeight: 'bold',
    },
    week: {
      backgroundColor: '#fff',
      textColor: '#FF0000',
      borderColor: '#ccc',
      textTransform: 'uppercase',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  };
  const todayDate = new Date();
  return (
    <div className="mx-4 py-2" style={{ height: '100vh', scrollbarWidth: 'none' }}>
      <div className="d-flex mb-2 ">
        <div onClick={handleCloseButtonClick}>
          <ArrowCircleLeftIcon sx={{ cursor: 'pointer', color: '#FFCE00', borderRadius: '50%', fontSize: '25px' }} />
        </div>
      </div>
      <style>
        {`.css-1iwzvzx-MuiButtonBase-root-MuiButton-root {
          color: ${isDarkMode ? '#FFCE00 !important' : '#674BFF !important'};
        }
        `}
        {`.css-hsfylk-MuiTypography-root {
           color: ${isDarkMode ? '#FFCE00 !important' : '#674BFF !important'};
          }`}

        {`
          p.MuiTypography-root.MuiTypography-body1.MuiTypography-noWrap.css-1csjcbn-MuiTypography-root {
    color: #fff !important;
}
          `}
        {`  .css-1iwzvzx-MuiButtonBase-root-MuiButton-root[aria-label="Today"] {
    display: none !important;
  }`}
        {`  .css-f2k5sd-MuiButtonBase-root-MuiButton-root[aria-label="Today"] {
    display: none !important;
  }`}
        {`.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.css-1bhkrkz-MuiAvatar-root {
                      background: unset !important   }`}

        {`.MuiAvatar-root.MuiAvatar-circular.MuiAvatar-colorDefault.css-fq8rg2-MuiAvatar-root {
                        background: unset !important    }`}

        {`h6.MuiTypography-root.MuiTypography-subtitle2.MuiTypography-noWrap.css-nq60gd-MuiTypography-root {
       color: ${isDarkMode ? '#000 !important' : '#FFFF !important'};
}`}
      </style>
      <div
        className="example"
        style={{
          width: '100%',
          height: '92vh',
          overflowY: 'scroll',
        }}
        ref={schedulerRef}
      >
        <Scheduler
          key={mappedEvents.map(event => event.event_id).join('_')}
          events={mappedEvents}
          onEventClick={handleEventClick}
          // day={null}
          week={{
            weekDays: [0, 1, 2, 3, 4, 5, 6],
            weekStartOn: 1,
            startHour: 0,
            endHour: 24,
            step: 30,
            navigation: true,
            disableGoToDay: false,
          }}
          day={{
            startHour: 0,
            endHour: 24,
            step: 15,

            navigation: true,
          }}
          editable={false}
          view="month"
        />
      </div>
    </div>
  );
};
export default EventCalenderPage;
