import React, { CSSProperties, useEffect } from 'react';

import { Button, Grid, InputAdornment, TextField, Typography, Stack, useTheme, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AccountCircle } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { handlePasswordResetInit, reset } from 'app/modules/account/password-reset/password-reset.reducer';

import { Alert } from 'reactstrap';
import { forgotPasswordSchema } from 'app/utils/validation-schema/forgot-password-schema';
import LoaderIcon from 'app/components/common/loader-icon/loader-icon';
import toast from 'react-hot-toast';
import './forgot-password.scss';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { width } from '@fortawesome/free-solid-svg-icons/faCogs';
interface ForgotPasswordPageProps {
  currentComponent?: any;
  setCurrentComponent?: any;
  handleAuthModalClose?: any;
  enteredMail?: any;
}
const ForgotPasswordPage = ({ currentComponent, setCurrentComponent, handleAuthModalClose, enteredMail }: ForgotPasswordPageProps) => {
  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      dispatch(reset());
    },
    []
  );
  const navigate = useNavigate();
  const theme = useTheme();

  const initialValues = {
    email: enteredMail || '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: values => {
      dispatch(handlePasswordResetInit(values.email));
    },
    validationSchema: forgotPasswordSchema,
  });
  const successMessage = useAppSelector(state => state.passwordReset.successMessage);
  const errorMessage = useAppSelector(state => state.passwordReset.errorMessage);
  const errorObj = useAppSelector(state => state.passwordReset.error);
  const loading = useAppSelector(state => state.passwordReset.loading);

  useEffect(() => {
    if (successMessage) {
      handleAuthModalClose();
      toast.success(successMessage?.data, { id: 'reset-success' });
    }
  }, [successMessage]);

  const loginContainer: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  };
  const heading = {
    color: theme.textColor.heading,
    fontSize: '15px',
    marginBottom: '20px',
    marginTop: '20px',
  };
  const handleGoBack = () => {
    setCurrentComponent('login');
  };
  return (
    <Grid
      className="forgetpassword-mrg"
      container
      spacing={2}
      width={'100%'}
      padding={'10px'}
      direction={'column'}
      margin={'0'}
      justifyContent={'center'}
      alignItems={'center'}
      // minHeight={'300px'}
      // minWidth={'300px'}
      // maxWidth={'420px'}
      sx={{ margin: 'auto', width: '42vw', height: '70%' }}
    >
      <Grid item xs={12} md={7} style={loginContainer} padding={'0 !important'}>
        <Grid item xs={12} md={12}>
          <div onClick={() => navigate('/')}>
            <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" style={{ height: '58px' }} />
          </div>
        </Grid>

        <form onSubmit={formik.handleSubmit}>
          <Stack alignItems={'center'} gap={1}>
            <Typography variant="h5" gutterBottom mb={0} mt={2}>
              Forgot your Password?
            </Typography>
            {successMessage !== null ? (
              <Alert color="success" data-cy="registration succes messsage">
                <strong>{successMessage?.data}</strong>
              </Alert>
            ) : null}
            {errorMessage?.length ? (
              <Alert color="danger" data-cy="registration error messsage">
                <p className="text-wrap">{errorMessage}</p>
              </Alert>
            ) : null}
            <h2 style={{ fontSize: '11px', textAlign: 'center' }}>
              No worries! We will help you reset it.
              <br />
              Enter your registered email id and we will send you a reset password link.
            </h2>
            <TextField
              name="email"
              size="small"
              id="email"
              placeholder="Email"
              InputProps={{
                style: { backgroundColor: theme.palette.secondary.dark },
                endAdornment: (
                  <InputAdornment position="end">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              style={{ marginBottom: '10px', width: '90%' }}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email ? String(formik.errors.email) : ''}
            />
            <Button
              type="submit"
              variant="contained"
              style={{
                marginBottom: '10px',
                width: theme.createActivity.width,
                height: theme.createActivity.height,
                backgroundColor: theme.palette.primary.light,
                borderRadius: theme.createActivity.borderRadius,
                color: theme.palette.secondary.dark,
                borderColor: theme.palette.primary.main,
              }}
              endIcon={loading ? <LoaderIcon /> : null}
            >
              <span>
                <Typography style={{ textTransform: 'capitalize' }}>Submit</Typography>
              </span>
            </Button>
          </Stack>
          <Grid item xs={12} md={12} pt={0} className="pt-0 text-center">
            <span style={{ fontSize: '11px', textAlign: 'center', width: '70%', fontWeight: '600' }}>Back to </span>
            <Link
              onClick={() => handleGoBack()}
              sx={{ fontSize: '11px', color: theme.palette.primary.light, textAlign: 'center', width: '70%', fontWeight: '600' }}
            >
              Log in
            </Link>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordPage;
