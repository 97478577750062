import { Box, Grid, Rating, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled, useTheme } from '@mui/material/styles';

function ActivityDetailsReviewSection({ activityResponse }) {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down(740));
  const AswesomeBar = styled(LinearProgress)(({ color }) => ({
    height: 9,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#EFF0F0',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: '#4EA952',
    },
  }));

  const GoodBar = styled(LinearProgress)(({ color }) => ({
    height: 9,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#EFF0F0',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: '#A3D52D',
    },
  }));

  const MehBar = styled(LinearProgress)(({ color }) => ({
    height: 9,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#EFF0F0',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: '#F7E63C',
    },
  }));

  const BadRatingBar = styled(LinearProgress)(({ color }) => ({
    height: 9,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#EFF0F0',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: '#F7A627',
    },
  }));

  const TerribleRatingBar = styled(LinearProgress)(({ color }) => ({
    height: 9,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#EFF0F0',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: ' #FF0000',
    },
  }));

  return (
    <div>
      {activityResponse && (
        <Grid container padding={'16px'} direction={'column'} gap={2}>
          {activityResponse?.hasPermission && (
            <>
              <Grid item container justifyContent={'center'} alignItems={'center'} direction={'column'} gap={2}>
                <Typography sx={{ fontWeight: '600', fontSize: '13px/21px', color: '#9FA1A4' }}>REVIEWS</Typography>
                {activityResponse?.starRating && (
                  <Typography fontWeight={'600'} fontSize={'31px/50px'}>
                    {parseFloat(activityResponse?.starRating)}
                  </Typography>
                )}
                <Rating
                  name="half-rating-read"
                  defaultValue={activityResponse?.starRating}
                  precision={0.1}
                  readOnly
                  sx={{ color: theme.palette.primary.light, fontSize: '2rem' }}
                />
                <Typography>Based on {activityResponse?.ratedParticipantsCount} review(s)</Typography>
              </Grid>
              <Grid container gap={2} direction={'column'} paddingLeft={isSmScreen ? 0 : 8} paddingRight={isSmScreen ? 0 : 8}>
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} gap={1}>
                  <Typography sx={{ flex: '0 0 25%' }}>Satisfaction</Typography>
                  <Box className="ms-3" sx={{ flex: '1 1 75%' }}>
                    <AswesomeBar variant="determinate" value={activityResponse?.satisfaction} sx={{ width: '100%' }} />
                  </Box>{' '}
                </Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} gap={1}>
                  <Typography sx={{ flex: '0 0 25%' }}>Planning</Typography>
                  <Box className="ms-3" sx={{ flex: '1 1 75%' }}>
                    <GoodBar variant="determinate" value={activityResponse?.planning} sx={{ width: '100%' }} />
                  </Box>{' '}
                </Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} gap={1}>
                  <Typography sx={{ flex: '0 0 25%' }}>Communication</Typography>
                  <Box sx={{ flex: '1 1 75%' }}>
                    <MehBar variant="determinate" value={activityResponse?.communication} sx={{ width: '100%' }} />
                  </Box>{' '}
                </Grid>
                {/* <Grid sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} gap={1}>
                  <Typography sx={{ flex: '0 0 25%' }}>Bad</Typography>
                  <Box sx={{ flex: '1 1 75%' }}>
                    <BadRatingBar variant="determinate" value={activityResponse?.bad} sx={{ width: '100%' }} />
                  </Box>{' '}
                </Grid> */}
                {/* <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} gap={1}>
                  <Typography sx={{ flex: '0 0 25%' }}>Terrible</Typography>
                  <Box sx={{ flex: '1 1 75%' }}>
                    <TerribleRatingBar variant="determinate" value={activityResponse?.terrible} sx={{ width: '100%' }} />
                  </Box>
                </Grid> */}
              </Grid>
            </>
          )}

          {activityResponse?.completedEventGallery && activityResponse?.completedEventGallery?.length !== 0 && (
            <Grid item container justifyContent={'center'} alignItems={'center'}>
              <Typography sx={{ fontWeight: '600', fontSize: '13px/21px', color: '#9FA1A4' }}>Photos and videos</Typography>
            </Grid>
          )}
          <Grid container spacing={2} paddingTop={2} paddingBottom={2} alignItems={'center'}>
            {activityResponse?.completedEventGallery?.map((image, index) => (
              <Grid item container xs={12} sm={6} md={4} lg={3} key={index} alignItems={'center'} justifyContent={'center'}>
                <a href={image.link} target="_blank" rel="noopener noreferrer">
                  <img
                    src={image.type === 'videos' ? image?.thumbnail : image?.link}
                    alt={`Image ${index + 1}`}
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '15px' }}
                  />
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default ActivityDetailsReviewSection;
