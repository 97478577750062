import React from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import CreateActivityInstructions from './create-activity-instructions/create-activity-instructions';
import CreateActivityResources from './create-activity-resources/create-activity-resources';
import './create-activity-instruction-resources.scss';
import { useAppSelector } from 'app/config/store';
import LoaderIcon from 'app/components/common/loader-icon/loader-icon';

const CreateActivityInstructionResources = ({ isError, formik, isButtonDisabled }: any) => {
  const isLoading = useAppSelector(state => state.homeActivity.loading);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center " style={{ rowGap: '20px' }}>
      <div className="col-12  mt-sm-2 mt-2 mt-md-0">
        <CreateActivityInstructions formik={formik} />
      </div>
      {formik.values.subCategoryID !== 72 && (
        <div className="col-12">
          <CreateActivityResources formik={formik} />
        </div>
      )}
      <div className=" fixed-button1 text-center">
        {formik.values.categoryID !== 19 && formik.values.subCategoryID !== 72 && formik.values.activityFrequencyId === 1 && (
          <div>
            <FormControl component="fieldset">
              <FormControlLabel
                sx={{ color: 'secondary.main', fontSize: '14px' }}
                value="end"
                control={
                  <Checkbox
                    name="urgent"
                    checked={formik.values.urgent === true}
                    onChange={event => {
                      formik.setFieldValue('urgent', event.target.checked);
                    }}
                    disableRipple
                    sx={{
                      color: 'transparent',
                      '&.Mui-checked': { color: 'primary.light' },
                      '& .MuiSvgIcon-root': { fontSize: 23, borderRadius: '25%', border: '1px solid #BFBFBF', padding: '3px' },
                    }}
                  />
                }
                label={<Typography sx={{ color: 'secondary.main', fontSize: '12px' }}>Mark as urgent need</Typography>} // Set font size here
                labelPlacement="end"
              />
            </FormControl>
          </div>
        )}
        <div>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: !formik.dirty || !formik.isValid ? 'secondary.contrastText' : 'primary.main',
              textTransform: 'none',
              borderRadius: '15px',
              width: '200px',
              color: !formik.dirty || !formik.isValid ? 'secondary.dark' : 'common.black',
              height: '40px',
              '&:hover': {
                backgroundColor: !formik.dirty || !formik.isValid ? 'secondary.contrastText' : 'primary.main',
                color: !formik.dirty || !formik.isValid ? 'secondary.dark' : 'common.black',
              },
            }}
            endIcon={isLoading && formik.isValid ? <LoaderIcon /> : null}
            disabled={formik.isSubmitting || isButtonDisabled || isError}
          >
            Post now
          </Button>
        </div>
      </div>
    </div>
  );
};
export default CreateActivityInstructionResources;
