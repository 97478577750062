import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import EntitiesRoutes from 'app/entities/routes';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import LoginPage from './components/ui/auth/login';
import WelcomeScreen from './components/ui/auth/welcome-screen/welcome-screen';
import RegistrationPage from './components/ui/auth/registration/registration-page';
import PickYourInterests from './components/ui/auth/pick-your-interests/pick-your-interests';
import MainHome from './components/ui/home/main-home';
import EventsNearYou from './components/ui/auth/events-near-you/events-near-you';
import ChooseAvailability from './components/ui/auth/choose-availability/choose-availability';
import SetBio from './components/ui/auth/set-bio/set-bio';
import ResetPasswordPage from './components/ui/auth/reset-password/reset-password';
import ForgotPasswordPage from './components/ui/auth/forgot-password/forgot-password';
import VerifyOtpPage from './components/ui/auth/verify-otp/verify-otp';
import CreateActivity from './components/ui/create-activity/create-activity';
import BlogListView from './components/ui/blog-list/blog-list-view';
import BlogSingle from './components/ui/blog-single/blog-single';
import Blog from './components/ui/blog/blog';
import EditActivity from './components/ui/create-activity/edit-activity';
import CreatePetition from './components/ui/create-petition/create-petition';
import { useTheme } from '@mui/material/styles';
import ActivitySection from './components/ui/activity-details-section/activity-section/activity-section';
import { colorSchemes } from './utils/data/constants/constants';
import PetitionDetail from './components/ui/petition/petition-detail';
import EditPetition from './components/ui/edit-petition/edit-petition';
import TestPage from './components/ui/test/test';
import ChatCard from './components/common/cards/chat-card/chat-card';
import Profile from './components/ui/profile/profile';
import MapEventsPage from './components/layout/map-events-page/map-events-page';
import CreateOrganization from './components/ui/profile/create-organization/create-organization';
import EventCalenderPage from './components/layout/event-calender-page/event-calender-page';
import Cookies from 'js-cookie';
import Settings from './components/ui/settings/settings';
import Organization from './components/ui/organaization/organization';
import Login from './components/ui/auth/login';
import ProfileLockedPage from './shared/error/profile-locked-page';
import DashboardHome from './components/ui/dashboard/home/dashboard-home';
import DashboardCategory from './components/ui/dashboard/category/dashboard-category';
import MyActivityDashboard from './components/ui/dashboard/my-activity/my-activity-dashboard';
import RecurringSection from './components/ui/activity-recurring-section/recurring-section';
import AdminRegister from './components/ui/auth/registration/admin-register';
import Gallery from './components/ui/auth/registration/gallery';
import usePageTracking from './utils/hooks/usePageTracking';
import AboutUs from './components/ui/about-us/about-us';
import CommunityGuidelines from './components/ui/community-guidelines/community-guidelines';
import CookiePolicy from './components/ui/cookie-policy/cookie-policy';
import LegelNotices from './components/ui/legel-notices/legel-notices';
import PrivacyPolicy from './components/ui/privacy-policy/privacy-policy';
import TermsAndCondition from './components/ui/terms-and-condition/terms-and-condition';
import Faq from './components/ui/faq/faq';
import HowItWorks from './components/ui/how-it-works/how-it-works';
import MemberAgreements from './components/ui/member-agreement/member-agreement';

const loading = <div>loading ...</div>;

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => loading,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const AppRoutes = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const myCookieValue = Cookies.get('jhi-authenticationToken');
  const isUserPreferenceDone = sessionStorage.getItem('userPreferencesCompleted');

  const authenticationToken = myCookieValue;
  const [phoneVerified, setPhoneDetails] = useState(true);
  const [enteredMail, setEnteredMail] = useState(null);

  console.log(isUserPreferenceDone, 'isUserPreferenceDone');

  useEffect(() => {
    const checkString = localStorage.getItem('getPhone');
    if (checkString) {
      const check = JSON.parse(checkString);
      setPhoneDetails(check?.verified);
      console.log('checking verified', check?.verified, check);
    }
  }, [localStorage.getItem('getPhone')]);

  usePageTracking();

  return (
    <div
      className="view-routes"
      style={{
        minHeight: '100vh',
        background: isDarkMode ? '#1F1F26' : colorSchemes.appGradientColor,
      }}
    >
      <ErrorBoundaryRoutes>
        <Route index element={<MainHome />} />
        <Route element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="community-guidelines" element={<CommunityGuidelines />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
        <Route path="legal-notices" element={<LegelNotices />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-and-condition" element={<TermsAndCondition />} />
        <Route path="faq" element={<Faq />} />
        <Route path="how-it-works" element={<HowItWorks />} />
        <Route path="member-agreement" element={<MemberAgreements />} />
        {!authenticationToken && <Route path="login" element={<Login setEnteredMail={setEnteredMail} />} />}
        {/* <Route path="admin" element={<AdminLayout />} /> */}
        <Route path="logout" element={<Logout />} />
        <Route path="logins" element={<LoginPage />} />
        <Route path="blog-list" element={<BlogListView />} />
        <Route path="blog-single/:id" element={<BlogSingle />} />
        <Route path="welcome" element={<WelcomeScreen />} />
        <Route path="events" element={<EventsNearYou />} />
        <Route path="availability" element={<ChooseAvailability />} />
        <Route path="interests" element={<PickYourInterests />} />
        <Route path="createbio" element={<SetBio />} />
        <Route path="signup" element={<RegistrationPage />} />
        <Route path="invite" element={<AdminRegister />} />
        <Route path="gallery" element={<Gallery />} />

        <Route path="resetpassword" element={<ResetPasswordPage />} />
        <Route path="forgotpassword" element={<ForgotPasswordPage />} />
        <Route path="verifyotp" element={<VerifyOtpPage />} />
        <Route path="create-a-blog" element={<Blog />} />
        <Route path="test" element={<TestPage />} />
        <Route path="lock" element={<ProfileLockedPage />} />
        <Route path="account">
          <Route
            path="*"
            element={
              <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]}>
                <Account />
              </PrivateRoute>
            }
          />
          <Route path="register" element={<Register />} />
          <Route path="activate" element={<Activate />} />
          <Route path="reset">
            <Route path="request" element={<PasswordResetInit />} />
            <Route path="finish" element={<PasswordResetFinish />} />
          </Route>
        </Route>

        {(phoneVerified || JSON.parse(localStorage.getItem('getPhone'))?.verified) && (
          <>
            {!JSON.parse(localStorage.getItem('activityPostLimitReached')) && (
              <>
                <Route path="create-activity" element={<CreateActivity />} />
                <Route path="create-activity/:category" element={<CreateActivity />} />
              </>
            )}
            {!JSON.parse(localStorage.getItem('petitionPostLimitReached')) && <Route path="create-petition" element={<CreatePetition />} />}
          </>
        )}

        <Route path="chat/list" element={<ChatCard />} />
        <Route path="chat/main" element={<ChatCard />} />
        <Route path="chat" element={<ChatCard />} />
        <Route path="chat/info" element={<ChatCard />} />
        <Route path="edit-activity/:id" element={<EditActivity />} />
        <Route path="activity/:id" element={<ActivitySection />} />
        <Route path="recurringactivity/:id" element={<RecurringSection />} />
        <Route path="petition/:id" element={<PetitionDetail />} />
        <Route path="edit-petition/:id" element={<EditPetition />} />
        <Route path="profile" element={<Profile />} />
        <Route path="profile/:id" element={<Profile />} />
        <Route path="calender" element={<EventCalenderPage />} />
        <Route path="map" element={<MapEventsPage />} />
        <Route path="create-organization" element={<CreateOrganization />} />
        <Route path="organization/:id" element={<Organization />} />
        <Route path="organizations/:id" element={<Organization />} />
        <Route path="settings" element={<Settings />} />
        <Route path="stats" element={<DashboardHome />} />
        <Route path="stats/:id" element={<DashboardCategory />} />
        <Route
          path="my-activty/dashboard"
          element={
            <PrivateRoute>
              <MyActivityDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
