import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  useTheme,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchPendingInvitations, inviteMembers } from 'app/shared/reducers/settings/settings-organization';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useSearchParams } from 'react-router-dom';
import { settingOrgPermssionProps } from './settings-org-rightcard';
import { AddCircleOutline } from '@mui/icons-material';

const SettingsOrgInvite = ({ hasPermission }: settingOrgPermssionProps) => {
  const [email, setEmail] = useState('');
  const [emails, setEmails] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [mailValid, setMailValid] = useState(false);
  const [emailError, setEmailError] = useState('');
  const userState = useAppSelector(state => state.authentication.account);
  const isOwnMail = (userState?.email || userState?.login) === email;
  const [isMember, setIsMember] = useState(false);

  const dispatch = useAppDispatch();
  const { members, selectedOrgId } = useAppSelector(state => state.settingsOrganization);

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  useEffect(() => {
    if (members.length > 0) {
      const member = Boolean(members.find((member: any) => email === member.email));
      setIsMember(member);
    }
  }, [members, email]);

  const handleInputChange = event => {
    const newEmail = event.target.value;
    const trimmedEmail = newEmail.trim(); // Trim leading and trailing spaces
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedEmail);
    setMailValid(isValidEmail);
    setEmail(trimmedEmail); // Save trimmed email to state
    setIsButtonDisabled(false);
    setEmailError('');
  };

  const removeEmail = emailToRemove => {
    setEmails(emails.filter(email => email !== emailToRemove));

    if (email === emailToRemove) {
      setEmail('');
    }
  };

  const handleAddIconClick = () => {
    if (email) {
      if (isMember) {
        setEmailError('User already a member');
      } else {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

        if (isValidEmail) {
          if (isOwnMail) {
            setEmailError('Self-Invitations not Allowed. Please use a different email address.');
          } else if (!emails.includes(email)) {
            setEmails([...emails, email]);
            setEmail('');
          } else {
            setEmailError('Email already added.');
          }
        } else {
          setEmailError('Please enter a valid email address.');
        }
      }
    }
  };

  const handleInviteClick = () => {
    const isValidEmail = email ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) : true;

    if (email || (!email && emails.length > 0))
      if (isMember) {
        setEmailError('User is already a member');
      } else {
        if (isValidEmail) {
          if (isOwnMail) {
            setEmailError('This is your own email address.');
          } else {
            dispatch(inviteMembers({ organizationId: selectedOrgId, emailAddresses: emails.length === 0 ? [email] : emails }))
              .then(() => {
                dispatch(fetchPendingInvitations({ orgId: selectedOrgId }));
                setEmail('');
                setEmails([]);
                setIsButtonDisabled(true);
              })
              .catch(error => {
                toast.error('Failed to send invitations');
              });
          }
        } else {
          setEmailError('Please enter a valid email address.');
        }
      }
  };

  return (
    selectedOrgId &&
    hasPermission && (
      <Grid container spacing={2} pt={1}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" px={1} sx={{ color: theme.palette.mode === 'dark' ? '#ffff' : '#2a2a2a' }}>
            Invite
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} className=" p-0 mx-2">
          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              sx={{
                height: '40px',
                fontSize: "0.75rem"
              }}
              onChange={handleInputChange}
              value={email}
              onKeyDown={event => {
                if (event.key === 'Enter' && email) {
                  handleAddIconClick();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="add" onClick={handleAddIconClick}>
                    <AddCircleOutline sx={{ color: 'primary.main' }} />
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
            />

            {emailError && (
              <FormHelperText error id="email-error-helper-text">
                {emailError}
              </FormHelperText>
            )}

            <Grid container spacing={0.5} sx={{ marginTop: '2px' }}>
              {emails.map((email, index) => (
                <Grid item key={index}>
                  <Chip key={index} label={email} onDelete={() => removeEmail(email)} />
                </Grid>
              ))}
            </Grid>
            <Grid
              container
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 1,
              }}
            >
              <FormHelperText id="outlined-weight-helper-text">Invite your friends through email.</FormHelperText>
              <Button
                onClick={handleInviteClick}
                disabled={!mailValid && isMember}
                variant="text"
                disableRipple
                sx={{
                  gap: 1,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: '#F6F7F8',
                    textTransform: 'none',
                    borderRadius: 1.875,
                    py: 0.5,
                    px: 3.25,
                    border: `1px solid`,
                    borderColor: 'primary.light',
                    bgcolor: 'primary.light',
                    fontSize: theme.infoText.regular.fontSize,
                    fontWeight: theme.infoText.regular.fontWeight,
                    fontStyle: theme.infoText.regular.fontStyle,
                  }}
                >
                  Invite
                </Typography>
              </Button>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    )
  );
};

export default SettingsOrgInvite;
