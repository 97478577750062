import React, { useEffect, useState } from 'react';
import '../dashboard.scss';
import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchOrganization, fetchUnauthorizedOrganization } from 'app/shared/reducers/organization';
import Cookies from 'js-cookie';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import BarGraph from './bar-graph';
import HomeSection from './home-section';
import { fetchAchievedAndTargetGoalsByCategories, fetchActivityCount } from 'app/shared/reducers/dashboard/dashboard';
import { fetchActivityDetails, fetchActivityDetailsUnauthorized } from 'app/shared/reducers/activity-home';
import { convertDateToDesiredFormat, truncateString } from 'app/utils/common-utils/common-utils';
import { fetchAllCategoriesSubcategories, fetchAllCategoriesSubcategoriesUnauthorized } from 'app/shared/reducers/categories-subcategories';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';

const drawerWidth = 315;
const drawerWidthSm = 248;

interface Props {
  window?: () => Window;
}

const DashboardHome = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [catNames, setCatNames] = useState([]);
  const [sortedAchievedTargetGoals, setSortedAchievedTargetGoals] = useState([]);

  const myCookieValue = Cookies.get('jhi-authenticationToken');
  const authenticationToken = myCookieValue;
  const isAuthenticated = !!authenticationToken;
  const activityCount = useAppSelector(state => state.dashboardReducer.data.activityCount.successResponse);
  const achievedTargetGoals = useAppSelector(state => state.dashboardReducer.data.achievedTargetGoals.successResponse);
  const isLoading = useAppSelector(state => state.dashboardReducer.data.achievedTargetGoals.loading);

  const popularActivty = useAppSelector(state => state.homeActivity.activityDetails);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const container = window !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (achievedTargetGoals?.length > 0 && catNames?.length > 0) {
      const customSortFunction = (a, b) => {
        const indexA = catNames.indexOf(a.categoryName);
        const indexB = catNames.indexOf(b.categoryName);
        return indexA - indexB;
      };

      const sortedArray = achievedTargetGoals.slice().sort(customSortFunction);

      setSortedAchievedTargetGoals(sortedArray);
    }
  }, [catNames, achievedTargetGoals]);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        if (id)
          if (isAuthenticated) {
            await dispatch(fetchOrganization(id));
          } else {
            await dispatch(fetchUnauthorizedOrganization(id));
          }
      } catch (error) {
        console.log(error);
      }
    };

    fetchOrganizationData();
  }, [id, isAuthenticated]);

  useEffect(() => {
    dispatch(fetchActivityCount());
    dispatch(fetchAchievedAndTargetGoalsByCategories());

    const fetchCategoryItem = async () => {
      let response;
      try {
        if (isAuthenticated) {
          response = await dispatch(fetchAllCategoriesSubcategories());
        } else {
          response = await dispatch(fetchAllCategoriesSubcategoriesUnauthorized());
        }
        if (response.meta.requestStatus === 'fulfilled') {
          const categoryData = response.payload;
          const catNames = categoryData.map((obj: any) => obj.category);
          setCatNames(catNames);
        } else {
          console.log('Error fetching category items');
        }
      } catch (error) {
        console.error('Error fetching category items', error);
      }
    };

    fetchCategoryItem();
  }, []);

  useEffect(() => {
    if (activityCount?.popularActivityId)
      if (isAuthenticated) {
        dispatch(fetchActivityDetails(activityCount?.popularActivityId));
      } else {
        dispatch(fetchActivityDetailsUnauthorized(activityCount?.popularActivityId));
      }
  }, [activityCount, isAuthenticated]);

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };
  const handleAccordionClick = (category: any, currentState: any) => {
    setCategory(category);
  };

  const handleButtonClicked = () => {
    const newToggleValue = !toggleValue;
    if (toggleMapValue) {
      setToggleMapValue(false);
    }
    setToggleValue(newToggleValue);
  };

  const handleButtonMapClicked = () => {
    const newToggleMapValue = !toggleMapValue;
    if (toggleValue) {
      setToggleValue(false);
    }
    setToggleMapValue(newToggleMapValue);
  };
  const handleButtonFeatureClicked = () => {};
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const drawer = (
    <Box
      sx={{
        border: 'none',
        maxHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',

        background:
          theme.palette.mode === 'dark'
            ? '#1F1F26'
            : 'transparent linear-gradient(182deg, #F7F5EF 0%, #F2F2FA 49%, #f1f1f8 100%) 0% 0% no-repeat padding-box;',
        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',
        minHeight: '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      {/* <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Box style={{ paddingLeft: '0px', paddingRight: '50px', cursor: 'pointer' }} onClick={() => navigate('/')}>
          <img src="content/images/logos/reachamy-logo.png" alt="reach amy logo" style={{ height: '54px' }} />
        </Box>
      </DrawerHeader> */}
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Link to={'/'} style={{ paddingLeft: '0px', paddingRight: '50px' }}>
          <AmyIcon sx={{ width: '159px', height: '100px' }} />
        </Link>
      </DrawerHeader>

      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={handleSubCategoryClick}
        onhandleAccordionClick={handleAccordionClick}
        onButtonClicked={handleButtonClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onMapClicked={handleButtonMapClicked}
        onFeatureClicked={handleButtonFeatureClicked}
      />
      <Divider />
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        background: isDarkMode
          ? '#1F1F26'
          : ' transparent linear-gradient(172deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat padding-box',
        border: 'none',
        p: 0,
        backdropFilter: 'blur(10px)',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      <CssBaseline />

      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
          background: isDarkMode ? '#1F1F26' : '#f6f4f1',
        }}
      >
        <Toolbar
          className="amy-top-nav"
          sx={{
            backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
            border: 'none',
            bakground: isDarkMode ? '#1F1F26' : '',
            backdropFilter: 'blur(10px)',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, top: '10px' }}
          >
            <MenuIcon />
          </IconButton>
          <PrimaryHeader searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth },
          flexShrink: { sm: 0 },
          minHeight: '100vh',
          border: 'none',
          background: isDarkMode ? '#1F1F26' : 'transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSm },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth } },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        className="inside-bg"
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '0px',
          background: isDarkMode ? '#1F1F26' : ' #f6f4f1',
          border: 'none',

          overflowY: 'scroll',
        }}
      >
        <Toolbar />
        <div className="container-fluid p-0" style={{ background: isDarkMode ? '#1F1F26' : '' }}>
          <Grid container spacing={2}>
            <Grid display="flex" item xs={12} md={8}>
              <Box sx={{ backgroundColor: isDarkMode ? '#2c2c38' : '#fff', padding: 2.6, flex: 1, borderRadius: 1 }}>
                <BarGraph activityCountData={activityCount} />
              </Box>
            </Grid>
            <Grid display="flex" item xs={12} md={4}>
              <Box display="flex" flex="1" py={3} px={3} bgcolor={isDarkMode ? '#2c2c38' : '#fff'} sx={{ borderRadius: 1 }}>
                {activityCount?.popularActivityId ? (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.7 }}>
                    <Typography component="h5" sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                      Most Popular Activity
                    </Typography>
                    <Box display="flex" height="106px" overflow="hidden" sx={{ width: '53%', borderRadius: 1 }}>
                      <img
                        className="img"
                        src={
                          popularActivty?.eventGallery?.length > 0 &&
                          (popularActivty?.eventGallery[0].type === 'images'
                            ? popularActivty?.eventGallery[0].link
                            : popularActivty?.eventGallery[0].thumbnail)
                        }
                        alt="Activty image"
                      />
                    </Box>
                    <Box flex="1" display="flex" flexDirection="column" justifyContent="space-between">
                      <div>
                        <Box mb={1}>
                          <Typography color={isDarkMode ? '#FFCE00' : '#674bff'} variant="body2">
                            {popularActivty?.postedBy}
                          </Typography>
                          <Typography color={isDarkMode ? '#FFCE00' : '#674bff'} variant="body2">
                            {convertDateToDesiredFormat(popularActivty?.createdAt)}
                          </Typography>
                        </Box>
                        <CardContent sx={{ padding: 0 }}>
                          <Typography mb="5px" fontWeight="bold" fontSize="0.92rem">
                            {popularActivty?.title}
                          </Typography>
                          {popularActivty && <Typography variant="body2">{truncateString(popularActivty?.caption, 185)}</Typography>}
                        </CardContent>
                      </div>

                      <Link to={`/activity/${activityCount?.popularActivityId}`}>
                        <Button
                          variant="text"
                          sx={{ color: isDarkMode ? '#FFCE00' : '#674bff', display: 'flex', justifyContent: 'flex-start', padding: 0 }}
                        >
                          View More..
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.7 }}>
                    <Typography component="h5" sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
                      Most Popular Activity
                    </Typography>
                    <Typography py={1} variant="body2">
                      No popular activity found
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid py={3.3}>
            {!isLoading &&
              sortedAchievedTargetGoals?.length > 0 &&
              sortedAchievedTargetGoals.map((item, index) => (
                <HomeSection dateStarts={activityCount?.startDate} dateEnds={activityCount?.endDate} key={index} item={item} />
              ))}
          </Grid>
        </div>
      </Box>
      {/* End main body */}
    </Box>
  );
};
export default DashboardHome;
