import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThemeProvider } from '@mui/material/styles';
import getStore, { useAppSelector, useAppDispatch } from 'app/config/store';
import setupAxiosInterceptors from 'app/config/axios-interceptor';
import { clearAuthentication } from 'app/shared/reducers/authentication';
import ErrorBoundary from 'app/shared/error/error-boundary';
import AppComponent from 'app/app';
import { loadIcons } from 'app/config/icon-loader';
import theme from './styles/theme/theme';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import darkTheme from './styles/theme/dark-theme';
import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { apiKeys } from './utils/data/constants/api-keys';
import { getReportingReasons } from './shared/reducers/activity/activity-report';
import axios from 'axios';
import ReactGA from 'react-ga4';
import initializeGA from './ga';

const AppWrapper = () => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(state => state.darkMode);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const appTheme = mode?.darkMode ? darkTheme : theme;
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getReportingReasons());
    }
  }, [isAuthenticated, dispatch]);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AppComponent />
        </LocalizationProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

const store = getStore();
const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

loadIcons();
initializeGA();

const rootEl = document.getElementById('root');

const RootComponent = () => {
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    const fetchGoogleClientId = async () => {
      try {
        const response = await axios.get('api/account/google-cred');
        if (response.status === 200) {
          let clientId = response.headers['client-id'];
          setClientId(clientId);
          Cookies.set('googleClientId', clientId, { expires: 7 });
        }
      } catch (error) {
        console.log('Error fetching Google Client ID:', error);
      }
    };

    fetchGoogleClientId();
  }, []);

  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId={clientId ? clientId : apiKeys.GOOGLE_CLIENT_ID}>
        <AppWrapper />
      </GoogleOAuthProvider>
    </Provider>
  );
};

const rootElement = document.getElementById('root');
createRoot(rootElement).render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
);
