import React, { useEffect } from 'react';

import { Dialog, DialogActions, Button, Typography, TextField, Box, MenuItem, FormControl, Select, useTheme } from '@mui/material';
import { reportCommentById } from 'app/shared/reducers/blogs';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';

const ReportCommentDialog = ({ open, onClose, onReport, comment }) => {
  const [reportReason, setReportReason] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState('');
  const reportingReasons = useAppSelector(state => state.reportActivityReducer.reportingReasons);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const dispatch = useAppDispatch();
  const { id } = useParams();
  const match = id.match(/(\d+)(?!.*\d)/);
  const blogId = match ? Number(match[0]) : null;
  console.log('Extracted ID:', blogId);

  const handleReport = async () => {
    const params = {
      commentId: comment?.id,
      blogId: blogId,
      reportCategory: null,
      reportReason: reportReason ? reportReason : '',
    };

    try {
      const postResponse = await dispatch(reportCommentById(params));
      handleOKClick(postResponse.payload);

      // Call onClose() only if the API call is successful
      setReportReason('');
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setReportReason('');
    onClose();
  };

  const handleOKClick = payload => {
    onClose(payload);
  };
  useEffect(() => {
    if (setSelectedOption?.length) {
      setReportReason(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    return () => {
      setSelectedOption('');
    };
  }, []);
  const showTextField = selectedOption === 'Something Else';

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '15px',
          padding: '0.5%',
        },
      }}
    >
      <div style={{ padding: '16px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            paddingTop: '1%',
            paddingRight: '2%',
            paddingBottom: '2%',
          }}
        >
          <Close onClick={handleCancel} />
        </Box>
        <Typography variant="h6" gutterBottom>
          Report Comment
        </Typography>
        <Typography variant="body1">Please provide a reason for reporting this comment.</Typography>
        <FormControl fullWidth variant="outlined" sx={{ marginTop: '16px' }}>
          <Select
            value={selectedOption}
            onChange={e => {
              setSelectedOption(e.target.value);
            }}
            size="small"
            displayEmpty
            MenuProps={{
              PaperProps: {
                style: {
                  overflowY: 'scroll',
                },
              },
              MenuListProps: {
                style: {
                  maxHeight: '200px',
                },
              },
            }}
          >
            <MenuItem value="">Select a reason</MenuItem>
            {reportingReasons?.map((data: any) => (
              <MenuItem value={data.label} key={data.id}>
                <Typography sx={{ fontSize: '16px' }}>{data.label}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showTextField && (
          <TextField
            label="Details (Optional)"
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            // value={reportReason}
            onChange={e => setReportReason(e.target.value)}
            sx={{ marginTop: 2 }}
          />
        )}
      </div>
      <DialogActions>
        <Button onClick={handleCancel} sx={{ color: isDarkMode ? '#FFCE00' : '#674bff' }}>
          Cancel
        </Button>
        <Button disabled={!selectedOption} onClick={handleReport} sx={{ color: isDarkMode ? '#FFCE00' : '#674bff' }}>
          Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportCommentDialog;
