import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { Grid, Stack, Tooltip, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchMileStones } from 'app/shared/reducers/profile-section/milestones';
import Card from '@mui/material/Card';
import './profile-goals.scss';
import { formatDateToNormal } from 'app/utils/common-utils/common-utils';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { Check } from '@mui/icons-material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
// import AdvocacyIcon from './trophy-icons';
import TrophyIcons from './trophy-icons';
import TrophyIconsblue from './trophy-icon-blue';
import { fetchOrgMileStones } from 'app/shared/reducers/organization';

interface PofileGoalsProps {
  id: string;
  type?: string;
}
export const ProfileGoals = ({ id, type }: PofileGoalsProps) => {
  const [mileStoneData, setMileStoneData] = React.useState(null);
  const profileMileStoneData = useAppSelector(state => state.mileStones.mileStones);
  const orgMileStoneData = useAppSelector(state => state.organizationReducer.orgMileStones);

  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (type === 'organization') {
      setMileStoneData(orgMileStoneData);
    } else {
      setMileStoneData(profileMileStoneData);
    }
  }, [profileMileStoneData, orgMileStoneData]);

  React.useEffect(() => {
    if (id) {
      if (type === 'organization') {
        dispatch(fetchOrgMileStones(id));
      } else {
        dispatch(fetchMileStones(id));
      }
    }
  }, [id]);

  React.useEffect(() => {
    if (mileStoneData?.count >= 0) {
      if (mileStoneData?.count === 0) {
        setActiveStep(1);
      } else {
        setActiveStep(mileStoneData?.count + 1);
      }
    }
  }, [mileStoneData?.count]);

  const theme = useTheme();

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        color: theme.palette.primary.light,
        height: '40px',
        border: '1px solid #674BFF',
        width: 1,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        color: theme.palette.primary.light,
        height: '40px',
        border: '1px solid #674BFF',
        width: 1,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      color: theme.palette.primary.light,
      height: '40px',
      border: '1px solid #674BFF',
      width: 1,
    },
  }));
  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
    display: 'flex',
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#fff',
      borderRadius: '50%',
    }),
    '& .QontoStepIcon-completedIcon': {
      width: '34px',
      height: '34px',
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
      zIndex: 1,
      padding: '0 5px',
      borderRadius: '50%',
      fontSize: 115,
    },
    '& .QontoStepIcon-circle': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '34px',
      height: '34px',
      borderRadius: '50%',
      border: '1px solid #674BFF',
      fontSize: '1.2rem',
    },
  }));

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {activeStep === mileStoneData?.milestones.length ? (
          <EmojiEventsIcon />
        ) : completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  return (
    <Card
      className="example2"
      sx={{
        backgroundColor: 'secondary.dark',
        borderRadius: '6px',
        height: '80vh',
        overflowY: 'scroll',
        boxShadow: 'none',
        px: 1.2,
      }}
    >
      <Stack alignItems="center" justifyContent="center" sx={{ paddingTop: '20px', pb: 1 }}>
        {/* <img style={{ height: '6em', width: 'auto' }} src="content/images/ribbon.png" alt="milestone ribbon" /> */}
        <TrophyIconsblue sx={{ height: 'auto', width: ' 70px' }} />
      </Stack>
      {mileStoneData?.milestones?.length ? (
        <Stack
          direction={'column'}
          gap={0.5}
          sx={{
            pb: 1,
          }}
        >
          <Typography sx={{ color: theme.profile.color, fontSize: '20px', textAlign: 'left', fontWeight: 600 }}>Activity</Typography>
          <Typography sx={{ color: '#B4B4B4', fontSize: '14px', textAlign: 'left' }}>completed tasks</Typography>
          <Box
            sx={{
              maxHeight: '50vh',
              overflowY: 'auto',
              overflowX: 'hidden',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Stepper activeStep={activeStep} orientation="vertical" connector={<ColorlibConnector sx={{ ml: '15px' }} />}>
              {mileStoneData?.milestones.map(step => (
                <Step key={step?.milestones}>
                  <StepLabel sx={{ py: 0 }} StepIconComponent={QontoStepIcon}>
                    <Grid container direction="row" alignItems={'center'} justifyContent={'space-between'}>
                      <Tooltip title={step?.description ?? null}>
                        <Typography
                          // data-toggle="tooltip"
                          data-placement="center"
                          fontSize={'14px'}
                          // title={step?.description ?? null}
                          color={theme.profile.color}
                          sx={{ cursor: 'default' }}
                        >
                          {step?.milestones}
                        </Typography>
                      </Tooltip>
                      <Box sx={{ color: 'primary.light', fontSize: '12px' }}>
                        {step?.completedDate && formatDateToNormal(step?.completedDate).replace(/\//g, '-')}
                      </Box>
                    </Grid>
                    <hr className="hr-margin" />
                  </StepLabel>
                </Step>
              ))}
              {mileStoneData?.milestones.length > 0 && (
                <Step>
                  <StepLabel sx={{ py: 0 }} StepIconComponent={QontoStepIcon}>
                    {/* Render your icon for the last step */}
                    <Grid container direction="row" alignItems={'center'} justifyContent={'space-between'}>
                      {mileStoneData?.milestones.length === activeStep ? (
                        <>
                          <EmojiEventsIcon sx={{ fontSize: 40, color: 'primary.main' }} />
                          {/* <Typography fontSize="14px" color={theme.profile.color}>
                            Last Step
                          </Typography> */}
                        </>
                      ) : (
                        <div></div>
                      )}
                    </Grid>
                  </StepLabel>
                </Step>
              )}
            </Stepper>
          </Box>
        </Stack>
      ) : (
        <Typography sx={{ color: theme.profile.color, fontSize: '20px', textAlign: 'center', fontWeight: 600 }}>
          No Milestones Achieved
        </Typography>
      )}
    </Card>
  );
};
export default ProfileGoals;
