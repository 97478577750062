import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import WelcomeUser from '../welcome-user/welcome-user';

import { Button, Grid, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Storage } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUserPreferencesData, sendUserPreferencesData } from 'app/shared/reducers/user-preferences';

const SetBio = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<string>('');
  const [userPreferences, setUserPreferences] = useState<any>({});
  const matchesXs = useMediaQuery('(max-width:500px)');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const breakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const darkModeEnabled = useAppSelector(state => state.darkMode).darkMode;
  const sendDataSuccess = useAppSelector(state => state.userPreferencesSettings.sendSuccess);

  useEffect(() => {
    if (Storage.session.get('bio')) {
      setData(Storage.session.get('bio'));
    }
    if (Storage.session.get('weekDays')) {
      const weekDaysData = JSON.parse(Storage.session.get('weekDays'));
      const weekDays = weekDaysData.map(day => day.value);
      setUserPreferences(prevState => ({ ...prevState, weekDays }));
    }
    if (Storage.session.get('location')) {
      const locationMasterDTO = Storage.session.get('location');
      setUserPreferences(prevState => ({ ...prevState, locationMasterDTO }));
    }
    if (Storage.session.get('preferredStartTime')) {
      const preferredStartTime = Storage.session.get('preferredStartTime');
      setUserPreferences(prevState => ({ ...prevState, preferredStartTime }));
    }
    if (Storage.session.get('preferredEndTime')) {
      const preferredEndTime = Storage.session.get('preferredEndTime');
      setUserPreferences(prevState => ({ ...prevState, preferredEndTime }));
    }
    if (Storage.session.get('interests')) {
      const interestsData = JSON.parse(Storage.session.get('interests'));
      const interests = interestsData.map(interest => interest.id);
      setUserPreferences(prevState => ({ ...prevState, interests }));
    }
    if (Storage.session.get('Maxdistance')) {
      const distanceLimit = JSON.parse(Storage.session.get('Maxdistance'));
      setUserPreferences(prevState => ({ ...prevState, distanceLimit }));
    }
    if (Storage.session.get('profilePic')) {
      const profilePic = Storage.session.get('profilePic');
      setUserPreferences(prevState => ({ ...prevState, profilePic: profilePic.id }));
    }
    setUserPreferences(prevState => ({ ...prevState, darkModeEnabled }));
  }, []);

  useEffect(() => {
    if (sendDataSuccess) {
      Storage.session.remove('preferredStartTime');
      Storage.session.remove('weekDays');
      Storage.session.remove('location');
      Storage.session.remove('preferredEndTime');
      Storage.session.remove('interests');
      Storage.session.remove('distance');
      Storage.session.remove('bio');
      Storage.session.remove('ActiveField');
      Storage.session.remove('defaultDistance');
      Storage.session.remove('Maxdistance');
      Storage.session.remove('id');
      Storage.session.remove('profilePic');
      dispatch(getUserPreferencesData());
      navigate('/');
    }
  }, [sendDataSuccess, navigate]);

  const handleUserPreferencesSubmit = () => {
    setUserPreferences(prevState => ({ ...prevState, description: data }));
    dispatch(sendUserPreferencesData(userPreferences));
  };
  return (
    <Grid container direction="column" minHeight="100vh" py={5} sx={{ padding: matchesXs ? '5%' : '' }}>
      <Grid item>
        <WelcomeUser />
      </Grid>
      <Grid item container direction="column" sx={{ alignItems: 'center' }} justifyContent={'center'} gap={2}>
        <Grid item>
          <Typography variant="h4" sx={{ color: 'secondary.light', fontSize: matchesXs ? '20px' : '24px' }}>
            Let people know you better
          </Typography>
        </Grid>
        <Grid item>
          <Stack direction={'column'} alignItems={'center'}>
            <Grid item>
              <TextField
                name="bio"
                id="bio"
                defaultValue={data}
                rows={4}
                placeholder="Enter your short bio"
                multiline
                sx={{ width: breakpoint ? '335px' : '600px' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setData(e.target.value);
                  setUserPreferences(prevState => ({ ...prevState, description: e.target.value }));
                }}
              />
            </Grid>
            <Grid item container alignItems="center" justifyContent={'space-between'} my={3}>
              <Button
                onClick={() => {
                  Storage.session.set('bio', data);
                  navigate('/interests');
                }}
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  width: '89px',
                  height: '40px',
                  borderRadius: '15px',
                  border: '1px solid #FFCE00',
                  boxShadow: 'none',
                  '&:hover': { boxShadow: 'none' },
                }}
              >
                <Typography>Back</Typography>
              </Button>
              <Button
                onClick={handleUserPreferencesSubmit}
                variant="contained"
                sx={{
                  textTransform: 'none',
                  width: '89px',
                  height: '40px',
                  borderRadius: '15px',
                  border: '1px solid #FFCE00',
                  boxShadow: 'none',
                  '&:hover': { backgroundColor: 'primary.main', boxShadow: 'none' },
                }}
              >
                <Typography>Finish</Typography>
              </Button>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SetBio;
