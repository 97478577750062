import React, { useEffect } from 'react';
import { Dialog, DialogActions, Button, Typography, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import toast from 'react-hot-toast';
import { addDays } from 'date-fns';
import { useState } from 'react';
import { enUS } from 'date-fns/locale';
import Calendar from 'react-calendar';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
const RecurringRangeModal = ({ open, onClose, handleData }) => {
  const [filterId, setFilterId] = useState<number>(0);
  const handleCancel = () => {
    onClose(filterId);
  };
  const filteredData = handleData?.filter(item => !item.parent);
  const startDateValues = filteredData?.map(item => new Date(item.startDate));
  const activeDates = startDateValues?.map(dateString => new Date(dateString));
  const isDateActive = date => {
    return activeDates.some(
      activeDate =>
        date.getFullYear() === activeDate.getFullYear() &&
        date.getMonth() === activeDate.getMonth() &&
        date.getDate() === activeDate.getDate()
    );
  };
  const handleDateClick = date => {
    const inputDate = new Date(date);

    // Extract year, month, and day
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = String(inputDate.getDate()).padStart(2, '0');

    // Form the desired output string
    const outputDateString = `${year}-${month}-${day}`;
    const data = handleData.filter(item => !item.parent);
    const filteredData = data.filter(item => {
      // Convert startDate in data to the same format for accurate comparison
      const startDateFormatted = new Date(item.startDate).toISOString();

      // Compare the dates
      return outputDateString === startDateFormatted?.split('T')[0];
    });
    console.log('Filtered data for selected date:', filteredData);
    setFilterId(filteredData[0]?.id);
  };
  useEffect(() => {
    if (filterId != 0) {
      handleCancel();
    }
  }, [filterId]);

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="xs">
      <style>
        {`.activeDate{
        
        color: #674BFF  !important;
       
      }`}

        {/* {`.activeDateParticipant {
            background-color:#D7FFEB !important;
        color: #56C02B !important;
        border-radius:48% !important;
        
        }`} */}

        {`.activeDateParticipant abbr {
     
        color:#56C02B   !important;
       
}`}

        {`.react-calendar__tile--now {
          background-color:#FFCE00 !important;
        }`}

        {`.react-calendar__tile{
          // width: 48px !important;
          // height:48px !important;
          // padding: 0;
           border-radius: 12px 12px 12px 12px;
        }`}
        {`.react-calendar {
         width: 428px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2.125em;
    padding: 10px;
        }`}
      </style>

      <Calendar
        tileClassName={({ date, view }) => {
          const isActive = isDateActive(date);
          const hasParticipant = filteredData.some(item => {
            console.log('Date:', date);
            console.log('View:', view);
            const itemDate = new Date(item.startDate);
            return (
              itemDate.getFullYear() === date.getFullYear() &&
              itemDate.getMonth() === date.getMonth() &&
              itemDate.getDate() === date.getDate() &&
              item.participant
            );
          });
          console.log('part', hasParticipant);
          if (view === 'month' && isActive) {
            return hasParticipant ? 'activeDateParticipant' : 'activeDate';
          }
          return undefined;
        }}
        onClickDay={handleDateClick}
      />
    </Dialog>
  );
};

export default RecurringRangeModal;
