import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SettingsPanel from './settings-panels/settings-panels';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const SettingsBody = () => {
  const navigate = useNavigate();
  const handleCloseButtonClick = () => {
    navigate(-1);
  };
  return (
    <Box>
      <div className="d-flex mb-2 ">
        <div onClick={handleCloseButtonClick}>
          <ArrowCircleLeftIcon sx={{ cursor: 'pointer', color: '#FFCE00', borderRadius: '50%', fontSize: '25px' }} />
        </div>
      </div>
      <Box>
        <Typography variant="h6">Settings</Typography>
      </Box>
      <Box>
        <SettingsPanel />
      </Box>
    </Box>
  );
};

export default SettingsBody;
