import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { serializeAxiosError } from '../reducer.utils';
import toast from 'react-hot-toast';

export interface OrganizationSettingsState {
  fetchLoading: boolean;
  fetchError: boolean;
  fetchSuccess: boolean;
  pendingInvitations: Array<any> | null;
  inviteLoading: boolean;
  inviteError: boolean;
  inviteSuccess: boolean;
  removeInviteePendingLoading: boolean;
  removeInviteePendingError: boolean;
  removeUserAcceptedLoading: boolean;
  removeUserAcceptedError: boolean;
  membersLoading: boolean;
  membersError: boolean;
  members: Array<any> | null;
  selectedOrgId: number | null;
  updateOrganizationLoading: boolean;
  updateOrganizationError: boolean;
  updateOrganizationSuccess: boolean;
  updateUserRoleLoading: boolean;
  updateUserRoleError: boolean;
  deleteOrganizationError: boolean;
  deleteOrganizationLoading: boolean;
  deleteOrganizationSuccess: boolean;
}

interface RemovePendingInviteeParams {
  orgId: number;
  email: string;
}

interface RemoveAcceptedUserParams {
  orgId: number;
  userId: number;
}

interface OrgIdParam {
  orgId: number;
}

interface InviteMembersParams {
  organizationId: number;
  emailAddresses: string[];
}

const initialState: OrganizationSettingsState = {
  fetchLoading: false,
  fetchError: null,
  fetchSuccess: null,
  pendingInvitations: [],
  inviteLoading: false,
  inviteError: null,
  inviteSuccess: null,
  removeInviteePendingLoading: false,
  removeInviteePendingError: null,
  removeUserAcceptedLoading: false,
  removeUserAcceptedError: null,
  membersLoading: false,
  membersError: null,
  members: [],
  selectedOrgId: null,
  updateOrganizationLoading: false,
  updateOrganizationError: null,
  updateOrganizationSuccess: false,
  updateUserRoleLoading: false,
  updateUserRoleError: null,
  deleteOrganizationError: null,
  deleteOrganizationLoading: false,
  deleteOrganizationSuccess: null,
};

export const fetchPendingInvitations = createAsyncThunk(
  'organization/pendingInvitations',
  async ({ orgId }: OrgIdParam) => axios.get<any>(`/api/organization-invitees/${orgId}/pending`),
  {
    serializeError: serializeAxiosError,
  }
);

export const inviteMembers = createAsyncThunk(
  'organization/inviteMembers',
  async ({ organizationId, emailAddresses }: InviteMembersParams) =>
    axios.post('/api/organization/invite-members', {
      organizationId,
      emailAddresses,
    }),
  {
    serializeError: serializeAxiosError,
  }
);

export const removePendingInvitee = createAsyncThunk(
  'organization/removePendingInvitee',
  async ({ orgId, email }: RemovePendingInviteeParams) => axios.delete(`/api/organization/${orgId}/remove-pending-invitee/${email}`),
  {
    serializeError: serializeAxiosError,
  }
);

export const removeAcceptedUser = createAsyncThunk(
  'organization/removeAcceptedUser',
  async ({ orgId, userId }: RemoveAcceptedUserParams) => axios.delete(`/api/organization/${orgId}/remove-accepted-user/${userId}`),
  {
    serializeError: serializeAxiosError,
  }
);

export const fetchOrganizationMembers = createAsyncThunk(
  'organization/fetchOrganizationMembers',
  async ({ orgId }: OrgIdParam) => axios.get<any>(`/api/organization/${orgId}/accepted-users`),
  {
    serializeError: serializeAxiosError,
  }
);

export const updateOrganization = createAsyncThunk(
  'organization/updateOrganization',
  async ({ orgId, updatedData }: { orgId: number; updatedData: any }, thunkAPI) => {
    try {
      const response = await axios.put(`/api/organizations/${orgId}`, updatedData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || 'Failed to update organization: ');
    }
  }
);

export const updateUserRole = createAsyncThunk(
  'organization/updateUserRole',
  async ({ organizationId, userId, memberRole }: { organizationId: number; userId: number; memberRole: string }, thunkAPI) => {
    try {
      const response = await axios.put(`/api/profile/settings/organization/assign-role/${organizationId}`, {
        organizationId,
        userId,
        memberRole,
      });
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data.title);
      return thunkAPI.rejectWithValue(error.response?.data?.title || 'Failed to update user role: ');
    }
  }
);

export const deleteOrganization = createAsyncThunk(
  'organization/deleteOrganization',
  async ({ orgId }: OrgIdParam) => {
    await axios.delete(`/api/organizations/${orgId}`);
  },
  {
    serializeError: serializeAxiosError,
  }
);

const settingsOrganization = createSlice({
  name: 'pendingInvitations',
  initialState,
  reducers: {
    setSelectedOrgId: (state, action: { payload: number }) => {
      state.selectedOrgId = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPendingInvitations.pending, state => {
        state.fetchLoading = true;
        state.fetchSuccess = null;
        state.fetchError = null;
      })
      .addCase(fetchPendingInvitations.fulfilled, (state, action) => {
        state.fetchLoading = false;
        state.fetchSuccess = true;
        state.fetchError = null;
        state.pendingInvitations = action.payload.data;
      })
      .addCase(fetchPendingInvitations.rejected, (state, action) => {
        state.fetchLoading = false;
        state.fetchSuccess = false;
        state.fetchError = true;
        state.pendingInvitations = [];
        toast.error('Failed to fetch pending invitations');
      })
      .addCase(inviteMembers.pending, state => {
        state.inviteLoading = true;
        state.inviteSuccess = null;
        state.inviteError = null;
      })
      .addCase(inviteMembers.fulfilled, (state, action) => {
        state.inviteLoading = false;
        state.inviteSuccess = true;
        state.inviteError = null;
        toast.success('Invitations sent successfully');
      })
      .addCase(inviteMembers.rejected, (state, action) => {
        state.inviteLoading = false;
        state.inviteSuccess = false;
        state.inviteError = true;
        toast.error('Failed to send invitations');
      })
      .addCase(removePendingInvitee.pending, state => {
        state.removeInviteePendingLoading = true;
        state.removeInviteePendingError = null;
      })
      .addCase(removePendingInvitee.fulfilled, (state, action) => {
        state.removeInviteePendingLoading = false;
        state.removeInviteePendingError = null;

        state.pendingInvitations = state.pendingInvitations.filter(invitee => invitee.email !== action.meta.arg.email);
        toast.success('Pending invitee removed successfully');
      })
      .addCase(removePendingInvitee.rejected, (state, action) => {
        state.removeInviteePendingLoading = false;
        state.removeInviteePendingError = true;
        toast.error('Failed to remove pending invitee');
      })

      .addCase(removeAcceptedUser.pending, state => {
        state.removeUserAcceptedLoading = true;
        state.removeUserAcceptedError = null;
      })
      .addCase(removeAcceptedUser.fulfilled, (state, action) => {
        state.removeUserAcceptedLoading = false;
        state.removeUserAcceptedError = null;

        state.members = state.members.filter(member => member.id !== action.meta.arg.userId);
        toast.success('User removed successfully');
      })
      .addCase(removeAcceptedUser.rejected, (state, action) => {
        state.removeUserAcceptedLoading = false;
        state.removeUserAcceptedError = true;
        toast.error('Failed to remove the user');
      })

      .addCase(fetchOrganizationMembers.pending, state => {
        state.membersLoading = true;
        state.membersError = null;
        state.members = [];
      })
      .addCase(fetchOrganizationMembers.fulfilled, (state, action) => {
        state.membersLoading = false;
        state.membersError = null;
        state.members = action.payload.data;
      })
      .addCase(fetchOrganizationMembers.rejected, (state, action) => {
        state.membersLoading = false;
        state.membersError = true;
      })
      .addCase(updateOrganization.pending, state => {
        state.updateOrganizationLoading = true;
        state.updateOrganizationError = null;
      })
      .addCase(updateOrganization.fulfilled, (state, action) => {
        state.updateOrganizationLoading = false;
        state.updateOrganizationError = null;
        state.updateOrganizationSuccess = true;
        toast.success('Organization updated successfully!');
      })
      .addCase(updateOrganization.rejected, (state, action) => {
        state.updateOrganizationLoading = false;
        state.updateOrganizationError = true;
        state.updateOrganizationSuccess = false;
        toast.error('Failed to update organization');
      })
      .addCase(updateUserRole.pending, state => {
        state.updateUserRoleLoading = true;
        state.updateUserRoleError = null;
      })
      .addCase(updateUserRole.fulfilled, (state, action) => {
        state.updateUserRoleLoading = false;
        state.updateUserRoleError = null;

        toast.success('User role successfully changed!');
        const updatedMemberIndex = state.members.findIndex(member => member.id === action.meta.arg.userId);
        if (updatedMemberIndex !== -1) {
          state.members[updatedMemberIndex].role = action.meta.arg.memberRole;
        }
      })
      .addCase(updateUserRole.rejected, (state, action) => {
        state.updateUserRoleLoading = false;
        state.updateUserRoleError = true;
      })
      .addCase(deleteOrganization.pending, state => {
        state.deleteOrganizationLoading = true;
        state.deleteOrganizationError = null;
      })
      .addCase(deleteOrganization.fulfilled, state => {
        state.deleteOrganizationLoading = false;
        state.deleteOrganizationSuccess = true;
        state.deleteOrganizationError = null;

        toast.success('Organization removed successfully!');
      })
      .addCase(deleteOrganization.rejected, (state, action) => {
        state.deleteOrganizationLoading = false;
        state.deleteOrganizationSuccess = false;
        state.deleteOrganizationError = true;

        toast.error(action?.error?.message || 'Failed to delete organization');
      });
  },
});

export const setSelectedOrgId = (selectedOrgId: number) => ({
  type: 'pendingInvitations/setSelectedOrgId',
  payload: selectedOrgId,
});

export default settingsOrganization.reducer;
