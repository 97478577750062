import React, { MouseEvent, useEffect, useState } from 'react';

import { useNavigate, Link } from 'react-router-dom';

import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CssBaseline,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
  ListItemIcon,
  styled,
  Stack,
  ListItemText,
  Popover,
  ListItem,
} from '@mui/material';

import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { fetchBlogs, fetchBlogsByCategory, fetchBlogsByCategoryUnauthorized, fetchBlogsUnauthorized } from 'app/shared/reducers/blogs';
import MenuIcon from '@mui/icons-material/Menu';
import HTMLReactParser from 'html-react-parser';
import { convertDate, encodedTitle } from 'app/utils/common-utils/common-utils';
import { darkModeToggle, setTheme } from 'app/shared/reducers/dark-mode';
import LightModeIcon from '@mui/icons-material/LightMode';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import DarkModeToggleIcon from '../../icons/header-icons/dark-mode-toggle-icon';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SquareChatIcon from 'app/components/icons/header-icons/square-chat-icon';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Logout } from '@mui/icons-material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import './blog-list-view.scss';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import { Storage } from 'react-jhipster';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import { toggleModalLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import FeatureDialog from 'app/components/common/alert-dialog/feature-dialog';
import { clearSearhedActivities } from 'app/shared/reducers/activity-filter';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import BlogTableDeleteDialog from 'app/entities/blog-table/blog-table-delete-dialog';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const BlogListView = () => {
  const [isSearchBarVisible, setIsSearchBarVisible] = React.useState(true);
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const searchResults = useAppSelector(state => state.filters.searchResults);
  const errorResults = useAppSelector(state => state.filters.error);
  const searchLoading = useAppSelector(state => state.filters.loading);
  const searchKeyword = useAppSelector(state => state.filters.searchKeyWord);

  const openLightbox = image => {
    setSelectedImage(image);
    setLightBoxOpen(true);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSearchBarVisible(window.innerWidth >= 900);
    };

    // Check the window width and set the initial visibility on component mount
    window.addEventListener('resize', handleResize);
    handleResize();

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const loadingContainerStyle = {
    height: '100vh',

    display: 'flex',

    justifyContent: 'center',

    alignItems: 'center',
  };

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const [blogData, setBlogData] = useState<any>([]);

  const [loading, setLoading] = useState(true);

  const [error, setError] = useState(false);

  const [toggleMapValue, setToggleMapValue] = useState(false);

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  const isDarkMode = theme.palette.mode === 'dark';

  const navigate = useNavigate();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallerSearch = useMediaQuery(theme.breakpoints.down(599));

  const handleImageError = event => {
    event.target.src = 'content/images/no-img.jpg';
  };

  const fetchData = async () => {
    let response;

    try {
      setLoading(true);

      if (isAuthenticated) {
        response = await dispatch(fetchBlogs());
      } else {
        response = await dispatch(fetchBlogsUnauthorized());
      }

      setBlogData(response.payload);

      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);

      setError(true);
    }
  };

  useEffect(() => {
    fetchData();
    dispatch(clearSearhedActivities());

    return () => {
      dispatch(clearSearhedActivities());
    };
  }, []);

  const [category, setCategory] = useState(null);

  const [subCategory, setSubCategory] = useState(null);

  const onhandleAccordionClick = async (categoryId: number, currentState: any) => {
    setCategory(categoryId);

    let response;

    if (!currentState) {
      try {
        setLoading(true);

        if (isAuthenticated) {
          response = await dispatch(fetchBlogsByCategory({ categoryId, subCategoryId: null }));
        } else {
          response = await dispatch(fetchBlogsByCategoryUnauthorized({ categoryId, subCategoryId: null }));
        }

        setBlogData(response.payload);

        setLoading(false);
      } catch (error) {
        console.log(error);

        setLoading(false);

        setError(true);

        setBlogData([]);
      }
    } else {
      fetchData();
    }
  };

  const onSubCategoryClick = async (categoryId: number, subCategoryId: number) => {
    setCategory(categoryId);

    setSubCategory(subCategoryId);

    let response;

    try {
      setLoading(true);

      if (isAuthenticated) {
        response = await dispatch(fetchBlogsByCategory({ categoryId, subCategoryId }));
      } else {
        response = await dispatch(fetchBlogsByCategoryUnauthorized({ categoryId, subCategoryId }));
      }

      setBlogData(response.payload);

      setLoading(false);
    } catch (error) {
      console.log(error);

      setLoading(false);

      setError(true);

      setBlogData([]);
    }
  };

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawerWidth = 315;

  const reduxDarkModeValue = useAppSelector(state => state.darkMode.darkMode);

  const [currentComponent, setCurrentComponent] = React.useState(' ');

  const [authModalOpen, setAuthModalOpen] = React.useState(false);

  const toggleThemeHandler = () => {
    dispatch(darkModeToggle(!reduxDarkModeValue));
    Storage.local.set('dark-mode', !reduxDarkModeValue);

    if (isAuthenticated) {
      dispatch(setTheme({ darkMode: !reduxDarkModeValue }));
    }
  };

  const handleChatIconClick = () => {
    if (isAuthenticated) {
      navigate('/');
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };

  const handleDashboardIconClick = () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    } else {
      navigate('/');
    }
  };

  const handleNotificationIconClick = () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    } else {
      navigate('/');
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);

    navigate('/logout');
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);

    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAuthModalOpen = () => {
    setAuthModalOpen(true);
    dispatch(toggleModalLogin());
  };

  //close authentication modal dialog

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  const handleLogout = () => {
    setCurrentComponent('logout');

    handleAuthModalOpen();

    handleMenuClose();
  };

  const handleLogin = () => {
    setCurrentComponent('login');

    handleAuthModalOpen();

    handleMenuClose();
  };
  const handleButtonFeatureClicked = () => {};
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const menuId = 'primary-search-menu';

  const drawerWidthSm = 248;
  const handleButtonClicked = () => {};
  const handleButtonMapClicked = () => {};
  const renderMenu = (
    <Menu
      sx={{ top: '37px' }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',

        horizontal: 'right',
      }}
      PaperProps={{
        elevation: 0,

        sx: {
          overflow: 'visible',

          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',

          mt: 1.5,

          '& .MuiAvatar-root': {
            width: 32,

            height: 32,

            ml: -0.5,

            mr: 1,
          },

          '&:before': {
            content: '""',

            display: 'block',

            position: 'absolute',

            top: 0,

            right: 14,

            width: 10,

            height: 10,

            bgcolor: 'background.paper',

            transform: 'translateY(-50%) rotate(45deg)',

            zIndex: 0,
          },
        },
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',

        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {isAuthenticated ? (
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      ) : (
        <MenuItem onClick={handleLogin}>
          <ListItemIcon>
            <LoginIcon />
          </ListItemIcon>
          Login
        </MenuItem>
      )}
    </Menu>
  );

  const mobileMenuId = 'menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',

        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',

        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton onClick={toggleThemeHandler} size="large" aria-label="dark-mode-toggler" color="inherit">
          <Badge>{theme.palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeToggleIcon />}</Badge>
        </IconButton>
      </MenuItem>

      <MenuItem>
        <IconButton size="large" aria-label="chat" color="inherit">
          <Badge>{theme.palette.mode === 'dark' ? <QuestionAnswerRoundedIcon /> : <SquareChatIcon />}</Badge>
        </IconButton>
      </MenuItem>

      {/* <MenuItem>

        <IconButton size="large" aria-label="notifiacations" color="inherit">

          <Badge>{theme.palette.mode === 'dark' ? <NotificationsRoundedIcon /> : <NotificationIcon />}</Badge>

        </IconButton>

      </MenuItem> */}

      {isAuthenticated ? (
        <MenuItem onClick={handleLogout}>
          <IconButton size="large" color="inherit">
            <LogoutIcon />
          </IconButton>
        </MenuItem>
      ) : (
        <MenuItem onClick={handleLogin}>
          <IconButton size="large" color="inherit">
            <LoginIcon />
          </IconButton>
        </MenuItem>
      )}
    </Menu>
  );

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',

    alignItems: 'center',

    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,

    justifyContent: 'flex-end',
  }));

  const drawer = (
    <Box
      sx={{
        border: 'none',

        maxHeight: '100vh',

        overflowY: 'scroll',

        overflowX: 'hidden',

        background:
          theme.palette.mode === 'dark'
            ? '#1F1F26'
            : 'transparent linear-gradient(182deg, #F7F5EF 0%, #F2F2FA 49%, #f1f1f8 100%) 0% 0% no-repeat padding-box;',

        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },

        backdropFilter: 'blur(10px)',

        minHeight: '100%',

        '&::-webkit-scrollbar': {
          display: 'none',
        },

        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },

        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Link to={'/'} style={{ paddingLeft: '0px', paddingRight: '50px' }}>
          <AmyIcon sx={{ width: '159px', height: '100px' }} />
        </Link>
      </DrawerHeader>

      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={onSubCategoryClick}
        onhandleAccordionClick={onhandleAccordionClick}
        onButtonClicked={handleButtonClicked}
        onMapClicked={handleButtonMapClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onFeatureClicked={handleButtonFeatureClicked}
      />
    </Box>
  );

  useEffect(() => {
    const filteredSearchResults = searchResults?.filter(blog => blog.eventType === 'Blog');

    if (filteredSearchResults.length === 0) {
      // Remove item from localStorage
      localStorage.removeItem('searchKeyword');
    }
  }, [searchResults]);

  const [confirmFeature, setConfirmFeature] = React.useState<boolean>(false);
  const [payload, setPayload] = React.useState<any>();
  const [passId, setPassId] = React.useState<any>();

  const handleFeatureClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    setPassId(data?.id);
    handleGetCatalogue(4);
  };

  const [paymentLoading, setPaymentLoading] = useState(false);
  const handlePayment = async () => {
    setPaymentLoading(true);

    let passData = {
      eventId: passId,
      eventType: 4,
      quantity: 1,
      productId: payload?.productId,
    };

    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        setPaymentLoading(false);
        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl; // Open checkout URL in current tab
        } else {
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      setPaymentLoading(false);
      console.log(error);
    }
  };

  const handleGetCatalogue = async id => {
    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        console.log('check res', response);
        setPayload(response.data);
        setConfirmFeature(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [anchorElMock, setAnchorElMock] = useState<HTMLElement | null>(null);
  const [selectedBlogId, setSelectedBlogId] = useState<number>(null);

  const openModal = Boolean(anchorElMock);
  const menuItemId = open ? 'simple-popover' : undefined;
  const handleClickModal = (event: MouseEvent<HTMLElement>, blogId) => {
    setSelectedBlogId(blogId);
    setAnchorElMock(event.currentTarget);
  };

  const handleCloseModal = () => {
    setAnchorElMock(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',

        overflow: 'hidden',
      }}
    >
      <CssBaseline />

      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthSm}px)`, md: `calc(100% - ${drawerWidthSm}px)`, lg: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidthSm}px`, md: `${drawerWidthSm}px`, lg: `${drawerWidth}px` },
          background: isDarkMode ? '#1F1F26' : 'none',
        }}
      >
        <Toolbar
          className="amy-top-nav"
          sx={{
            backgroundColor: ' transparent linear-gradient(118deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat',
            border: 'none',
            backdropFilter: 'blur(10px)',
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, top: '10px' }}
          >
            <MenuIcon />
          </IconButton>
          <PrimaryHeader />
        </Toolbar>
      </AppBar>

      {renderMobileMenu}

      {renderMenu}

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },

            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidthSm },
          }}
        >
          {drawer}
        </Drawer>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },

            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { sm: drawerWidthSm, md: drawerWidthSm, lg: drawerWidth } },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,

          p: 3,
          width: '0px',

          // width: {
          //   sm: `calc(100% - ${drawerWidthSm} px)`,
          //   md: `calc(100% - ${drawerWidthSm} px)`,
          //   lg: `calc(100% - ${drawerWidth} px)`,
          // },
          background: isDarkMode
            ? '#1F1F26'
            : ' transparent linear-gradient(172deg, #F7F5EF 0%, #F2F2FA 49%, #EFEFF5 100%) 0% 0% no-repeat padding-box;',

          border: 'none',
        }}
      >
        <Toolbar />

        <div className="col-sm-12 col-md-10 col-lg-8 col-scroll ">
          <div
            className="col-12"
            style={{ display: 'flex', flexDirection: window.innerWidth < 768 ? 'column' : 'row', justifyContent: 'space-between' }}
          >
            <div>
              <Typography variant="h6" noWrap component="div" gutterBottom>
                Read what's happening
              </Typography>
            </div>

            {/* <div>
              {isAuthenticated && (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '15px',
                    width: { xs: '50%', sm: '100%', md: '100%', lg: '100%' },
                    paddingLeft: '10px',
                    paddingRight: '10px',
                  }}
                  onClick={() => navigate('/create-a-blog')}
                >
                  Create a blog
                </Button>
              )}
            </div> */}
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12" style={{ height: '77vh', overflow: 'hidden scroll' }}>
            <style>
              {`

         ::-webkit-scrollbar {

        width: 0;

        background: transparent;

      }    

      ::-webkit-scrollbar-thumb {

        background: transparent;

      }    `}
            </style>

            {loading && !searchResults?.length && !searchKeyword ? (
              <Grid item xs={12}>
                <div style={loadingContainerStyle}>Loading...</div>
              </Grid>
            ) : error && !searchResults?.length && !searchKeyword ? (
              <Grid item xs={12}>
                <div style={loadingContainerStyle}>Error occurred while fetching data.</div>
              </Grid>
            ) : searchResults?.length ? (
              <>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={1} alignItems={{ xs: 'center' }}>
                  <Typography color="secondary.light">{`${searchResults?.length} Search results found for  `}</Typography>
                  <Typography color={'secondary.main'} fontWeight={600}>
                    "{`${searchKeyword}`}"
                  </Typography>
                </Stack>

                {searchResults?.map((blog: any) => (
                  <div className="mb-3 mt-3" style={{ display: 'flex', flexDirection: 'column' }} key={blog.id}>
                    <Card
                      sx={{
                        width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },

                        borderRadius: '15px',
                        cursor: 'pointer',
                      }}
                    >
                      <Link to={`/blog-single/${encodedTitle(blog?.blogTitle)}-${blog.id}`} className="text-decoration-none">
                        <div style={{ position: 'relative' }}>
                          {/* <CardMedia
                            component="img"
                            alt="Blog Image"
                            height="300"
                            image={blog?.blogImage || 'content/images/no-img.jpg'}
                            style={{ objectFit: 'contain' }}
                            onError={handleImageError}
                          /> */}

                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              position: 'absolute',

                              bottom: 10,

                              left: 10,

                              background: '#F6F7F8',

                              color: '#900095',

                              textTransform: 'capitalize',

                              borderRadius: '16px',
                            }}
                          >
                            {blog?.blogType?.category}
                          </Button>
                        </div>

                        <CardContent component="div" sx={{ backgroundColor: 'secondary.dark' }}>
                          <Typography gutterBottom variant="h5" component="div" sx={{ color: theme.palette.secondary.main }}>
                            {encodedTitle(blog?.blogTitle)}
                          </Typography>

                          <Typography
                            variant="body2"
                            color="text.secondary"
                            component="div"
                            sx={{
                              color: theme.palette.secondary.light,

                              display: '-webkit-box',

                              WebkitBoxOrient: 'vertical',

                              overflow: 'hidden',

                              textOverflow: 'ellipsis',

                              maxHeight: '6em',

                              lineClamp: 3,

                              WebkitLineClamp: 3,
                            }}
                          >
                            {blog?.postDescription ? (
                              blog.postDescription.length > 220 ? (
                                <>{HTMLReactParser(blog.postDescription)}</>
                              ) : (
                                HTMLReactParser(blog.postDescription)
                              )
                            ) : null}
                          </Typography>
                        </CardContent>

                        <CardActions sx={{ justifyContent: 'space-between', backgroundColor: 'secondary.dark' }}>
                          <Button variant="text" sx={{ color: theme.palette.secondary.light, textTransform: 'capitalize' }}>
                            {convertDate(blog?.postedOn)}
                          </Button>

                          <Button variant="text" sx={{ color: theme.palette.secondary.light, textTransform: 'capitalize' }}>
                            by {blog?.authorName}
                          </Button>
                        </CardActions>
                      </Link>
                    </Card>
                  </div>
                ))}
              </>
            ) : Array.isArray(blogData) && blogData?.length > 0 && !searchResults?.length && !searchKeyword ? (
              blogData?.map((blog: any) => (
                <div className="mb-3 mt-3" style={{ display: 'flex', flexDirection: 'column' }} key={blog.id}>
                  <Card
                    sx={{
                      width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' },

                      borderRadius: '15px',
                      cursor: 'pointer',
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <CardMedia
                        component="img"
                        alt="Blog Image"
                        height="300"
                        style={{ objectPosition: '1px 1%' }}
                        image={blog?.blogImage || 'content/images/no-img.jpg'}
                        onError={handleImageError}
                        onClick={() => openLightbox(blog?.blogImage || 'content/images/no-img.jpg')}
                      />

                      {blog?.featured && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            background: '#674BFF',
                            color: '#FFFF',
                            padding: '5px 10px',
                            borderRadius: '0 0 15px 0',
                            zIndex: '999',
                            fontSize: '12px',
                          }}
                        >
                          <span style={{ fontWeight: 'bold' }}>Featured</span>
                        </div>
                      )}
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          position: 'absolute',

                          bottom: 10,

                          left: 10,

                          background: '#F6F7F8',

                          color: '#900095',

                          textTransform: 'capitalize',

                          borderRadius: '16px',
                        }}
                        onClick={() => {
                          navigate(`/blog-single/${encodedTitle(blog?.blogTitle)}-${blog.id}`);
                        }}
                      >
                        {blog?.blogType?.category}
                      </Button>
                    </div>

                    <CardContent component="div" sx={{ backgroundColor: 'secondary.dark' }}>
                      <div className="d-flex justify-content-between">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          sx={{ color: theme.palette.secondary.main }}
                          onClick={() => {
                            navigate(`/blog-single/${encodedTitle(blog?.blogTitle)}-${blog.id}`);
                          }}
                        >
                          {blog?.blogTitle}
                        </Typography>

                        <span onClick={event => handleClickModal(event, blog.id)}>
                          <MoreHorizOutlinedIcon
                            aria-describedby={'menuItemId'}
                            sx={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }}
                          />
                        </span>
                      </div>

                      <Typography
                        variant="body2"
                        color="text.secondary"
                        component="div"
                        sx={{
                          color: theme.palette.secondary.light,

                          display: '-webkit-box',

                          WebkitBoxOrient: 'vertical',

                          overflow: 'hidden',

                          textOverflow: 'ellipsis',

                          maxHeight: '6em',

                          lineClamp: 3,

                          WebkitLineClamp: 3,
                        }}
                        onClick={() => {
                          navigate(`/blog-single/${encodedTitle(blog?.blogTitle)}-${blog.id}`);
                        }}
                      >
                        {blog?.postDescription ? (
                          blog.postDescription.length > 220 ? (
                            <>{HTMLReactParser(blog.postDescription)}</>
                          ) : (
                            HTMLReactParser(blog.postDescription)
                          )
                        ) : null}
                      </Typography>
                    </CardContent>

                    <CardActions sx={{ justifyContent: 'space-between', backgroundColor: 'secondary.dark' }}>
                      <div>
                        <Button variant="text" sx={{ color: theme.palette.secondary.light, textTransform: 'capitalize' }}>
                          {convertDate(blog?.postedOn)}
                        </Button>
                        {isAuthenticated && selectedBlogId === blog?.id && (
                          <>
                            {/* <button
                              style={{
                                padding: '3px 12px',
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                backgroundColor: '#674bff',
                                color: 'white',
                                fontSize: '12px',
                                borderRadius: '15px',
                              }}
                              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                handleFeatureClick(event, blog);
                              }}
                            >
                              Feature now
                            </button> */}
                            <Popover
                              id={menuItemId}
                              open={openModal && selectedBlogId === blog?.id}
                              anchorEl={anchorElMock}
                              onClose={handleCloseModal}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                            >
                              <ListItem
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  textAlign: 'left',
                                  alignItems: 'inherit',
                                  padding: '8px 16px',
                                }}
                              >
                                <button
                                  style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    handleFeatureClick(event, blog);
                                  }}
                                >
                                  <ListItemText className="text-start">
                                    <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                                      Feature blog
                                    </Typography>
                                  </ListItemText>
                                </button>
                              </ListItem>
                            </Popover>
                          </>
                        )}
                      </div>

                      <Button variant="text" sx={{ color: theme.palette.secondary.light, textTransform: 'capitalize' }}>
                        by {blog?.authorName}
                      </Button>
                    </CardActions>
                  </Card>
                </div>
              ))
            ) : (
              <Grid item xs={12} style={loadingContainerStyle}>
                <Box display="flex" flexDirection="column" alignItems="center" gap="13px">
                  <Box
                    component="img"
                    sx={{
                      height: 233,
                      width: 350,
                      maxHeight: { xs: 233, md: 167 },
                      maxWidth: { xs: 350, md: 160 },
                    }}
                    alt="Image"
                    src="content/images/empty.svg"
                  />
                  <span>No blog data available.</span>
                </Box>
              </Grid>
            )}
          </div>
        </div>
      </Box>

      <Lightbox carousel={{ finite: true }} open={lightBoxOpen} close={() => setLightBoxOpen(false)} slides={[{ src: selectedImage }]} />

      <AuthorisationModal
        onClose={handleAuthModalClose}
        authModalOpen={authModalOpen}
        fullScreen={fullScreen}
        handleAuthModalClose={handleAuthModalClose}
        currentComponent={currentComponent}
        setCurrentComponent={setCurrentComponent}
      />

      <FeatureDialog
        paymentLoading={paymentLoading}
        agreeAction={handlePayment}
        values={payload}
        open={confirmFeature}
        setFeatureOpen={setConfirmFeature}
      />
    </Box>
  );
};

export default BlogListView;
