import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';
import { useAppDispatch } from 'app/config/store';
import { deleteOrganization, setSelectedOrgId } from 'app/shared/reducers/settings/settings-organization';
import { fetchUserGrantedOrganizations } from 'app/shared/reducers/organization';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoaderIcon } from 'react-hot-toast';

type Props = {
  open: boolean;
  setFeatureOpen: Dispatch<SetStateAction<boolean>>;
  values?: any;
  agreeAction: () => void;
  paymentLoading?: boolean;
};

export default function FeatureDialog({ values, open, setFeatureOpen, agreeAction, paymentLoading }: Props) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setFeatureOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent sx={{ fontWeight: '400', fontSize: '1 rem', color: isDarkMode ? '#FFFFFF' : '#000' }}>
          {
            // Use a ternary operator or a function to return the correct message
            values?.productName === 'Featured Petition'
              ? 'Featuring Petition is a paid service. Do you wish to continue?'
              : values?.productName === 'Featured Activity'
              ? 'Featuring Activity is a paid service. Do you wish to continue?'
              : values?.productName === 'Featured Blog'
              ? 'Featuring Blog is a paid service. Do you wish to continue?'
              : 'This service is paid. Do you wish to continue?'
          }
          {/* <DialogContentText id="alert-dialog-description">{values}</DialogContentText> */}
        </DialogContent>
        <DialogActions sx={{ paddingRight: '24px;' }}>
          <Button
            onClick={() => setFeatureOpen(false)}
            sx={{
              width: 'fit-content',
              color: 'primary.light',
              borderRadius: 1.875,
              border: '1px solid #674BFF',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent',
                border: '1px solid #674BFF  ',
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={agreeAction}
            autoFocus
            endIcon={paymentLoading ? <LoaderIcon color="primary.light" /> : null}
            disabled={paymentLoading}
            sx={{
              color: '#F6F7F8',
              textTransform: 'none',
              borderRadius: 1.875,
              // py: 0.5,
              // px: 3.25,
              border: `1px solid`,
              borderColor: 'primary.light',
              bgcolor: 'primary.light',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'primary.light',
              },
              '& .MuiButton-endIcon': {
                marginRight: '4px',
              },
            }}
          >
            Proceed ${values?.totalPrice}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
