import React from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import AuthenticationModal from 'app/components/ui/auth/authentication-modal';
import { useAppSelector } from 'app/config/store';

interface AuthenticationModalProps {
  fullScreen: boolean;
  onClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  authModalOpen: boolean;
  currentComponent: string;
  setCurrentComponent: React.Dispatch<React.SetStateAction<string>>;
  handleAuthModalClose;
  handleSearchClear?: () => void;
}
const AuthorisationModal = ({
  fullScreen,
  authModalOpen,
  setCurrentComponent,
  handleAuthModalClose,
  currentComponent,
  handleSearchClear,
}: AuthenticationModalProps) => {
  const theme = useTheme();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  return (
    <Dialog fullScreen={fullScreen} open={authModalOpen} onClose={handleAuthModalClose} sx={{ padding: '3%' }}>
      <style>
        {`
          .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-przs3r-MuiPaper-root-MuiDialog-paper{
            border-radius:15px}`}
      </style>
      <Stack sx={{ backgroundColor: theme.palette.secondary.dark, overflow: 'hidden' }} height={'100%'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            paddingTop: '1%',
            paddingRight: '2%',
            paddingBottom: '2%',
          }}
        >
          {!isAuthenticated && <Close onClick={handleAuthModalClose} />}
        </Box>
        <AuthenticationModal
          setCurrentComponent={setCurrentComponent}
          currentComponent={currentComponent}
          handleAuthModalClose={handleAuthModalClose}
          handleSearchClear={handleSearchClear}
        />
      </Stack>
    </Dialog>
  );
};

export default AuthorisationModal;
